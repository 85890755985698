<template>
  <div class="my-4 mx-3">
    <!-- Begin Page Content -->
    <div class="" v-if="permissions.includes('notifications.read')">
      <div
        class="d-flex flex-md-row flex-column justify-content-between align-items-md-center pb-3 mt-3 gap-md-0 gap-3"
      >
        <div>
          <h3 class="text-dark">Notifications</h3>
          <ol class="breadcrumb m-0">
            <li class="breadcrumb-item">
              <router-link :to="{ name: 'DashboardContent' }" tag="a">
                Dashboard
              </router-link>
            </li>
            <li class="breadcrumb-item active">Notifications</li>
          </ol>
        </div>
        <button
          class="btn btn-primary btn-sm ms-md-auto me-md-0 me-auto"
          @click="notificationModalHandler('add')"
          v-if="permissions.includes('notifications.write')"
        >
          Add notification
        </button>
      </div>
      <div class="card shadow-sm border-0 mb-4">
        <div class="card-body">
          <div class="p-2" v-if="permissions.includes('notifications.read')">
            <vue-good-table
              styleClass="vgt-table no-border"
              :totalRows="totalRecords"
              :pagination-options="{
                enabled: false,
                perPageDropdown: perpage_list,
              }"
              :rows="rows"
              :columns="columns"
            >
              <template slot="table-row" scope="props">
                <template v-if="props.column.label == 'Action'">
                  <template>
                    <button
                      class="btn-trash m-2"
                      v-if="permissions.includes('notifications.write')"
                    >
                      <i
                        @click="delete_notification(props.row.id)"
                        class="fa fa-trash fs-5"
                      ></i>
                    </button>
                  </template>
                </template>
              </template>
              <template slot="pagination-bottom" slot-scope="props">
                <CustomPagination
                  class="p-2"
                  :pageTabs="4"
                  :total="props.total"
                  :pageChanged="props.pageChanged"
                  :perPageChanged="props.perPageChanged"
                  :serverParams="serverParams"
                ></CustomPagination>
              </template>
            </vue-good-table>
          </div>
        </div>
        <div
          class="modal fade"
          id="notificationModal"
          aria-hidden="true"
          tabindex="-1"
        >
          <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content">
              <div class="modal-header">
                <h3
                  class="modal-title text-dark font-weight-bold pl-3"
                  id="taskModalLongTitle"
                  style="font-size: 26px"
                  v-text="modal_title"
                ></h3>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body" style="overflow-y: auto">
                <add-notification
                  @close-modal="notificationModalHandler('close')"
                ></add-notification>
                <!-- <add-quotes @close-modal="quoteModalHandler('close')"></add-quotes> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <UnauthorizedPage></UnauthorizedPage>
    </div>
  </div>
  <!-- End of Main Content -->
</template>
<script>
import user from "@/ajax/user";
import { mapState, mapActions } from "vuex";
import Vue from "vue";
import axios from "axios";
import UnauthorizedPage from "@/views/Layouts/UnauthorizedPage.vue";
import myMixin from "@/mixins/Permission";
import AddNotification from "./AddNotification.vue";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import CustomPagination from "../../components/CustomPagination.vue";

Vue.use(VueSweetalert2);

// import VueAxios from 'vue-axios';
// Vue.use(VueAxios,axios)

export default {
  name: "Notification",
  mixins: [myMixin],
  components: { UnauthorizedPage, AddNotification, CustomPagination },
  data: function () {
    return {
      siteLogReq: {
        site_name: "notifications",
      },
      permissions: window.localStorage.getItem("permissions"),
      currentPage: "",
      isLoading: false,
      columns: [
        {
          label: "Notification",
          field: "text",
          sortable: false,
          thClass: "custom-th",
          tdClass: "custom-td",
        },
        {
          label: "Action",
          field: "action",
          sortable: false,
          thClass: "disable-sorting custom-th",
          tdClass: "custom-td text-nowrap",
        },
      ],
      rows: [],
      totalRecords: 0,
      serverParams: {
        columnFilters: {},
        sort: [
          {
            field: "",
            type: "asc",
          },
        ],
        page: 1,
        perpage: 100,
      },
      perpage_list: [100, 200, 300, 400, 500, "All"],
      modal_title: null,
    };
  },
  mounted() {
    this.setCurrentPage("Notifications");
    if (this.permissions.includes("notifications.read")) {
      this.getUserPermissions();
      this.getAllNotification();
    }
  },
  created() {
    user.createSiteUsage(this.siteLogReq);
  },
  methods: {
    ...mapActions("userStore", ["setCurrentUser", "logoutUser"]),
    ...mapActions("common", ["setCurrentPage"]),

    hideModalEventHandler() {
      this.modal_title = null;
      this.client_id = null;
      $("#notificationModal").modal("hide");
    },
    notificationModalHandler(action, id) {
      if (action == "add") {
        this.modal_title = "Add Notification";
        $("#notificationModal").modal("show");
      } else if (action == "update") {
        this.modal_title = "Update Client";
        this.client_id = id;
        $("#notificationModal").modal("show");
      } else {
        $("#notificationModal").modal("hide");
        this.getAllNotification();
      }
    },
    getAllNotification() {
      var fields = {
        page: this.serverParams.page,
        perpage: this.serverParams.perpage,
      };
      user.getAllNotification(fields).then((result) => {
        this.rows = [];
        this.reqObj = result.data.data;
        this.rows = result.data.data.data;
        this.totalRecords = result.data.data.total;
      });
    },
    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      this.getAllNotification();
      // this.displayAllMilestonePages()
    },
    onPerPageChange(params) {
      this.updateParams({ perpage: params.currentPerPage });
      // this.displayAllMilestonePages()
      this.serverParams.page = 1;
      this.getAllNotification();
    },

    delete_notification(id) {
      this.$swal({
        title: "Delete",
        text: "Are you sure you want to delete?",
        type: "warning",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#32a852",
        cancelButtonColor: "#f00",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      }).then((result) => {
        if (result.value) {
          this.$swal(
            "Deleted",
            "You successfully delete this Notification",
            "success"
          );
          user.delete_notification(id).then((result) => {
            this.getAllNotification();
          });
        }
      });
    },
  },
  computed: {
    ...mapState("userStore", ["currentUser"]),
  },
};
</script>

<style scoped>
.startbuttons {
  padding: 5px 30px 5px 30px;
  margin: 10px;
  font-size: 20px;
}
.disableButton {
  opacity: 0.5;
  pointer-events: none;
}
</style>
