<template>
  <!-- Begin Page Content -->
  <div class="mt-4 mx-3 border-0">
    <div
      v-if="
        permissions.includes('clients.read') ||
        permissions.includes('sub_clients.read')
      "
    >
      <div class="d-flex align-items-center justify-content-between pb-3">
        <div>
          <h3 class="">{{ currentPage }}</h3>
          <!-- style="--bs-breadcrumb-divider: '>'" -->
          <ol class="breadcrumb m-0 align-items-top">
            <li class="breadcrumb-item active">
              <!-- <router-link
            :to="{ name: 'Clients' }"
            :tag="currentPage == 'Clients' ? 'span' : 'a'"
          >
            Clients
          </router-link> -->
              <span
                @click="pageChanger('Clients')"
                :class="
                  currentPage == 'Clients' ? '' : 'text-primary cursor-pointer'
                "
              >
                Clients
              </span>
            </li>
            <li class="breadcrumb-item">
              <!-- <router-link
            :to="{ name: 'SubClients' }"
            :tag="currentPage == 'SubClients' ? 'span' : 'a'"
          >
            SubClients
          </router-link> -->
              <span
                @click="pageChanger('SubClients')"
                :class="
                  currentPage == 'SubClients'
                    ? ''
                    : 'text-primary cursor-pointer'
                "
              >
                SubClients
              </span>
            </li>
          </ol>
        </div>
        <div>
          <button
            class="btn btn-primary btn-sm"
            v-if="
              permissions.includes('clients.write') &&
              permissions.includes('clients.add') &&
              currentPage == 'Clients'
            "
            @click="clientModalHandler('add')"
          >
            Add Client
          </button>
          <button
            class="btn btn-primary btn-sm"
            v-if="
              permissions.includes('sub_clients.write') &&
              permissions.includes('sub_clients.add') &&
              currentPage == 'SubClients'
            "
            @click="subclientModalHandler('add')"
          >
            Add SubClient
          </button>
        </div>
      </div>
      <!-- <ul class="nav border-bottom text-center">
        <li
          class="nav-item p-0 me-2"
          @click="pageChanger('Clients')"
          :style="ClientStyles"
        >
          <a
            class="nav-link px-0 py-2 fs-6"
            style="color: inherit; cursor: pointer"
            >Clients</a
          >
        </li>

        <li
          class="nav-item p-0"
          style="cursor: pointer"
          @click="pageChanger('SubClients')"
          :style="SubClientStyles"
        >
          <a class="nav-link px-0 py-2 fs-6" style="color: inherit"
            >SubClients</a
          >
        </li>
      </ul> -->
      <div v-if="currentPage === 'Clients'">
        <redmine-filter
          page="Clients"
          ref="redmineFilter"
          @redmine-filter-data="redmineFilterHandler"
          @reset-server-params="onResetServerParams"
          :serverParams="serverParams"
          key="Clients"
        >
        </redmine-filter>
      </div>
      <div v-if="currentPage === 'SubClients'">
        <redmine-filter
          page="SubClients"
          ref="redmineFilter"
          @redmine-filter-data="redmineFilterHandlerSubClient"
          @reset-server-params="onResetServerParams"
          :serverParams="serverParams"
          key="SubClients"
        ></redmine-filter>
      </div>
      <transition name="slide-fade" mode="out-in">
        <div :key="currentPage">
          <div v-if="currentPage === 'Clients'">
            <!-- <div class="d-flex justify-content-end"> -->
            <!-- <button
            class="btn btn-primary btn-sm order-2 order-md-0"
            v-if="
              permissions.includes('clients.write') &&
              permissions.includes('clients.add')
            "
            @click="clientModalHandler('add')"
          >
            Add Client
          </button> -->
            <!-- </div> -->
            <div class="card my-4 border-0 shadow-sm">
              <div class="card-body">
                <!-- <div v-if="permissions.includes('clients.read')" class="p-2"> -->
                <div v-if="permissions.includes('clients.read')" class="p-2">
                  <div class="table-responsive">
                    <vue-good-table
                      styleClass="vgt-table no-border"
                      mode="remote"
                      @on-page-change="onPageChange"
                      @on-sort-change="onSortChange"
                      @on-column-filter="onColumnFilter"
                      @on-per-page-change="onPerPageChange"
                      :totalRows="FilteredRecords"
                      :isLoading.sync="isLoading"
                      :pagination-options="{ enabled: true }"
                      :rows="rows"
                      :columns="columns"
                    >
                      <template slot="table-row" scope="props">
                        <template v-if="props.column.label == 'Action'">
                          <template
                            v-if="permissions.includes('clients.write')"
                          >
                            <div
                              class="d-flex justify-content-center align-items-center gap-3 p-2"
                            >
                              <button class="btn-view">
                                <i
                                  @click="AssignUser(props.row.id)"
                                  class="opt-users fs-5"
                                  data-toggle="modal"
                                  data-target="#assign-client-users"
                                ></i>
                              </button>
                              <button
                                class="btn-pencil"
                                @click="
                                  clientModalHandler('update', props.row.id)
                                "
                              >
                                <i class="fa fa-pencil fs-5"></i>
                              </button>
                              <button
                                class="btn-trash"
                                v-if="permissions.includes('clients.delete')"
                                @click="deleteClient(props.row.id)"
                              >
                                <i class="fa fa-trash fs-5"></i>
                              </button>
                            </div>
                          </template>
                          <!-- <span class="bg-light"><br><small style="font-size:10px">{{CreatedAndUpdatedAt(props.row.created_at,props.row.updated_at)}}</small></span> -->
                        </template>
                      </template>
                      <template slot="pagination-bottom" slot-scope="props">
                        <CustomPagination
                          class="p-2"
                          :pageTabs="4"
                          :total="props.total"
                          :pageChanged="props.pageChanged"
                          :perPageChanged="props.perPageChanged"
                          :serverParams="serverParams"
                        ></CustomPagination>
                      </template>
                    </vue-good-table>
                  </div>
                </div>
              </div>
            </div>
            <!-- model for assign users to client : END -->
          </div>

          <!-- Begin subclient Page Content -->
          <div v-if="currentPage === 'SubClients'">
            <!-- <div class="d-flex justify-content-end"> -->
            <!-- <button
            class="btn btn-primary btn-sm order-md-0 order-2"
            v-if="
              permissions.includes('sub_clients.write') &&
              permissions.includes('sub_clients.add')
            "
            @click="subclientModalHandler('add')"
          >
            Add Sub Client
          </button> -->
            <!-- </div> -->
            <div class="card my-4 border-0 shadow-sm">
              <div class="card-body">
                <div
                  v-if="permissions.includes('sub_clients.read')"
                  class="p-2"
                >
                  <div class="table-responsive">
                    <vue-good-table
                      styleClass="vgt-table no-border"
                      mode="remote"
                      @on-page-change="onPageChange"
                      @on-sort-change="onSortChange"
                      @on-column-filter="onColumnFilter"
                      @on-per-page-change="onPerPageChange"
                      :totalRows="FilteredRecords"
                      :isLoading.sync="isLoading"
                      :pagination-options="{
                        enabled: true,
                        perPageDropdown: perpage_list,
                      }"
                      :rows="rows"
                      :columns="SubClientcolumns"
                    >
                      <template slot="table-row" scope="props">
                        <template v-if="props.column.label == 'Action'">
                          <div
                            v-if="permissions.includes('sub_clients.write')"
                            class="d-flex justify-content-center align-items-center gap-3 p-2"
                          >
                            <button
                              v-if="permissions.includes('sub_clients.write')"
                              class="btn-pencil"
                              @click="
                                subclientModalHandler('update', props.row.id)
                              "
                            >
                              <i class="fa fa-pencil fs-5"></i>
                            </button>
                            <button
                              v-if="permissions.includes('sub_clients.delete')"
                              class="btn-trash"
                              @click="deleteSubClient(props.row.id)"
                            >
                              <i class="fa fa-trash fs-5"></i>
                            </button>
                          </div>
                        </template>
                      </template>
                      <template slot="pagination-bottom" slot-scope="props">
                        <CustomPagination
                          class="p-2"
                          :pageTabs="4"
                          :total="props.total"
                          :pageChanged="props.pageChanged"
                          :perPageChanged="props.perPageChanged"
                          :serverParams="serverParams"
                        ></CustomPagination>
                      </template>
                    </vue-good-table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
      <div
        class="modal fade"
        id="SubClientModal"
        role="dialog"
        aria-hidden="true"
        tabindex="-1"
      >
        <div
          class="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <div class="modal-content">
            <div class="modal-header">
              <h3
                class="text-dark font-weight-bold m-0"
                v-text="modal_title"
              ></h3>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body" style="overflow-y: auto">
              <AddSubClient
                @close-modal="subclientModalHandler('close')"
                v-if="modal_title == 'Update SubClient'"
                :subclient_id="subclient_id"
              ></AddSubClient>
              <AddSubClient
                @close-modal="subclientModalHandler('close')"
                v-if="modal_title == 'Add SubClient'"
              >
              </AddSubClient>
            </div>
          </div>
        </div>
      </div>
      <!-- Client add/update modal -->
      <div class="modal fade" id="clientModal" aria-hidden="true" tabindex="-1">
        <div class="modal-dialog modal-dialog-centered modal-lg">
          <div class="modal-content">
            <div class="modal-header">
              <h3
                class="modal-title text-dark font-weight-bold pl-3"
                id="taskModalLongTitle"
                style="font-size: 26px"
                v-text="modal_title"
              ></h3>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body" style="overflow-y: auto">
              <add-client
                @close-modal="clientModalHandler('close')"
                v-if="modal_title == 'Update Client'"
                :client_id="client_id"
              ></add-client>
              <add-client
                @close-modal="clientModalHandler('close')"
                v-if="modal_title == 'Add Client'"
              ></add-client>
            </div>
          </div>
        </div>
      </div>
      <!-- model for assign users to client : START -->
      <div
        class="modal fade"
        id="assign-client-users"
        tabindex="-1"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered modal-lg">
          <div class="modal-content">
            <div class="modal-header">
              <h3 class="text-dark m-0" id="exampleModalLabel">
                Assign Users To Client
              </h3>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <div class="row g-0">
                <div class="col-12 w-100">
                  <div class="">
                    <multiselect
                      class="project_multiselect"
                      v-model="assign.users"
                      :hide-selected="true"
                      :options="users"
                      :multiple="true"
                      :custom-label="custom_label"
                      placeholder="Select Multiple User"
                      label="first_name"
                      track-by="id"
                      group-label="label"
                      group-values="user_data"
                      :group-select="true"
                      @select="multiEvent($event, 'add')"
                      @remove="multiEvent($event, 'remove')"
                    >
                      <template
                        slot="selection"
                        slot-scope="{ values, search, isOpen }"
                      ></template>
                    </multiselect>
                  </div>
                </div>
              </div>
              <div
                class="row bg-light my-2 mx-1 text-center shadow-sm p-2"
                v-if="assign.users.length > 0"
              >
                <div class="col-3">
                  <b>Username</b>
                </div>
                <div class="col-6">
                  <b>Alias</b>
                </div>
                <div class="col-3">
                  <b>Order</b>
                </div>
              </div>
              <div class="row">
                <draggable
                  :list="selected_users"
                  @change="onDrag($event)"
                  group="list"
                  v-if="selected_users.length"
                >
                  <div
                    class="row mx-1 my-2"
                    v-for="(user, index) in selected_users"
                    :key="index"
                  >
                    <div class="col-3 mt-2">
                      <p class="">
                        <b>{{ index + 1 }} . </b> {{ custom_label(user) }}
                      </p>
                    </div>
                    <div class="col-6 pl-4 pr-4">
                      <input
                        type="text"
                        v-model="user.alias"
                        class="form-control"
                      />
                    </div>
                    <div class="col-3">
                      <input
                        type="number"
                        :value="orderMaker(user, index)"
                        @input="orderMaker(user, Number($event.target.value))"
                        ref="order"
                        class="form-control"
                      />
                    </div>
                  </div>
                </draggable>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-primary"
                @click="AssignUsersToClient()"
              >
                Save
              </button>
              <button
                type="button"
                class="btn btn-outline-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-else-if="
        !permissions.includes('clients.read') ||
        !permissions.includes('sub_clients.read')
      "
    >
      <UnauthorizedPage></UnauthorizedPage>
    </div>
  </div>
</template>
<script>
import user from "@/ajax/user";
import AddClient from "./AddClient.vue";
import { mapState, mapActions } from "vuex";
import Vue from "vue";
import axios from "axios";
import myMixin from "@/mixins/Permission";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import AddSubClient from "./AddSubClient.vue";
import redmineFilter from "../../components/redmine_filter.vue";
import UnauthorizedPage from "@/views/Layouts/UnauthorizedPage.vue";
import CustomPagination from "../../components/CustomPagination.vue";
import draggable from "vuedraggable";

Vue.use(VueSweetalert2);

export default {
  name: "Clients",
  mixins: [myMixin],
  components: {
    UnauthorizedPage,
    draggable,
    AddClient,
    AddSubClient,
    CustomPagination,
    redmineFilter,
  },
  data: function () {
    return {
      currentPage: "Clients",
      isLoading: false,
      siteLogReq: {
        site_name: "clients",
      },
      columns: [
        {
          label: "Name",
          field: "name",
          sortable: true,
          thClass: "custom-th",
          tdClass: "custom-td",
          filterable: true,
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Client name", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.onColumnFilter, //custom filter function that
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Manager",
          field: "first_name",
          type: "value",
          sortable: true,
          thClass: "custom-th",
          tdClass: "custom-td",
          filterable: true,
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Manager name", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.onColumnFilter, //custom filter function that
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Slug",
          field: "slug",
          sortable: true,
          thClass: "custom-th",
          tdClass: "custom-td",
          filterable: true,
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "slug", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.onColumnFilter, //custom filter function that
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Action",
          field: "action",
          sortable: false,
          thClass: "disable-sorting custom-th",
          tdClass: "custom-td text-nowrap",
        },
      ],
      SubClientcolumns: [
        {
          label: "Client",
          field: "name",
          sortable: true,
          thClass: "custom-th",
          tdClass: "custom-td",
          filterable: true,
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Enter Client name", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.onColumnFilter, //custom filter function that
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Name",
          field: "subclientname",
          sortable: true,
          thClass: "custom-th",
          tdClass: "custom-td",
          filterable: true,
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Sub Client Name", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.onColumnFilter, //custom filter function that
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Contact Person",
          field: "contactPerson",
          sortable: true,
          thClass: "custom-th",
          tdClass: "custom-td",
          filterable: true,
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Contact Person", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.onColumnFilter, //custom filter function that
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Contact Email",
          field: "contactEmail",
          sortable: true,
          thClass: "custom-th",
          tdClass: "custom-td",
          filterable: false,
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Contact Email", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.onColumnFilter, //custom filter function that
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: " Manager",
          field: "first_name",
          type: "value",
          sortable: true,
          thClass: "custom-th",
          tdClass: "custom-td",
          filterable: true,
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Manager name", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.onColumnFilter, //custom filter function that
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Action",
          field: "action",
          sortable: false,
          thClass: "disable-sorting custom-th",
          tdClass: "custom-td text-nowrap",
        },
      ],
      rows: [],
      totalRecords: 0,
      FilteredRecords: 0,
      serverParams: {
        columnFilters: {},
        sort: [
          {
            field: "",
            type: "asc",
          },
        ],
        page: 1,
        perpage: 100,
      },
      perpage_list: [100, 200, 300, 400, 500, "All"],
      permissions: window.localStorage.getItem("permissions"),
      // for assign users
      assign: {
        users: [],
        project_id: 0,
      },
      users: [],
      UserState: "",
      checkval: [],
      selected_users: [],
      modal_title: null,
      client_id: null,
      subclient_id: null,
    };
  },
  mounted() {
    this.setCurrentPage("Clients");
    // this.getAllClient();
    $("#clientModal").on("hidden.bs.modal", this.hideModalEventHandler);
    $("#SubClientModal").on(
      "hidden.bs.modal",
      this.hideModalEventHandlerSubCilent
    );
    this.getUserPermissions();
  },
  created() {
    user.createSiteUsage(this.siteLogReq);
  },
  methods: {
    ...mapActions("userStore", ["setCurrentUser", "logoutUser"]),
    ...mapActions("common", ["setCurrentPage"]),

    hideModalEventHandler() {
      this.modal_title = null;
      this.client_id = null;
      $("#clientModal").modal("hide");
    },
    hideModalEventHandlerSubCilent() {
      this.modal_title = null;
      this.subclient_id = null;
      $("#SubClientModal").modal("hide");
    },
    pageChanger(page_name) {
      if (this.currentPage !== page_name) {
        this.currentPage = page_name;
        this.rows = [];
      }
    },
    redmineFilterHandler() {
      this.search_client();
    },
    redmineFilterHandlerSubClient() {
      this.search_subclient();
    },

    onResetServerParams(serverParams) {
      this.serverParams = Object.assign({}, serverParams);
      // console.log("-----------",this.serverParams)
      this.$nextTick(() => {
        this.$refs.redmineFilter.applyFilter();
      });
    },

    // clients
    search_client() {
      // this.isLoading = true;
      var fields = {
        filters: this.serverParams.filters,
        messageData: this.message,
        sort: this.serverParams.sort,
        page: this.serverParams.page,
        perpage: this.serverParams.perpage,
      };

      user.search_client(fields).then((result) => {
        // console.log("API Response:", result.data.data);
        this.rows = result.data.data.data;
        this.FilteredRecords = result.data.data.total;
      });
    },
    deleteClient(id) {
      this.$swal({
        title: "Delete",
        text: "Are you sure you want to delete?",
        type: "warning",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#32a852",
        cancelButtonColor: "#f00",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      }).then((result) => {
        if (result.value) {
          user.deleteClient(id).then((result) => {
            if (result.data.code == 424) {
              this.$swal(result.data.data, result.data.message, "warning");
            }
            if (result.data.code == 200) {
              this.$swal(result.data.data, result.data.message, "success");
            }
            this.search_client();
          });
        }
      });
    },
    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      this.$nextTick(() => this.$refs.redmineFilter.applyFilter(true));
    },

    onPerPageChange(params) {
      this.updateParams({ perpage: params.currentPerPage });
      this.serverParams.page = 1;
      this.$nextTick(() => this.$refs.redmineFilter.applyFilter(true));
    },

    onSortChange(params) {
      this.updateParams({
        sort: [
          {
            type: params[0].type,
            field: params[0].field,
          },
        ],
      });
      this.$nextTick(() => this.$refs.redmineFilter.applyFilter(true));
    },

    onColumnFilter(params) {
      //remove empty fields
      var newObj = { columnFilters: {} };
      Object.keys(params.columnFilters).forEach(function (key) {
        if (params.columnFilters[key] !== "")
          newObj.columnFilters[key] = params.columnFilters[key];
      });

      this.updateParams(newObj);
      this.serverParams.page = 1;
      this.search_client();
    },
    clientModalHandler(action, id) {
      if (action == "add") {
        this.modal_title = "Add Client";
        $("#clientModal").modal("show");
      } else if (action == "update") {
        this.modal_title = "Update Client";
        this.client_id = id;
        $("#clientModal").modal("show");
      } else {
        $("#clientModal").modal("hide");
        this.search_client();
      }
    },
    orderMaker(user, order) {
      user["alias"] = user["alias"];
      user["order"] = order + 1;
      return order + 1;
    },
    // getAllClient() {
    //   user.getAllClient().then((result) => {
    //     this.rows = [];
    //     this.rows = result.data.data.data;
    //     this.totalRecords = result.data.data.total;
    //   });
    // },
    multiEvent(event, action) {
      if (action == "add") {
        let obj = Object.assign({}, event);
        obj.alias = obj.first_name;
        this.selected_users.push(obj);
      } else {
        this.selected_users = this.selected_users.filter(
          (item) => item.id != event.id
        );
      }
    },
    onDrag(event) {
      this.assign.users.forEach((item) => {
        if (item.id == event.moved.element.id) {
          // console.log(item.order,event.element);
          item.order = event.moved.newIndex + 1;
        }
      });
    },
    custom_label(name) {
      // console.log(name.first_name,name.middle_name,name.last_name);
      if (name.last_name != null && name.middle_name != null) {
        return `${name.first_name} ${name.middle_name} ${name.last_name}`;
      } else if (name.last_name == null) {
        return `${name.first_name} ${name.middle_name}`;
      } else if (name.middle_name == null) {
        return `${name.first_name} ${name.last_name}`;
      }
    },
    GetAllValidUsersList() {
      user.GetAllValidUsersList().then((result) => {
        this.users = [{ label: "Select all", user_data: result.data.data }];
        // console.log(this.users);
      });
    },
    getClientUnAssignUsers(id) {
      user.getClientUnAssignUsers(id).then((result) => {
        this.assign.users = result.data.data;
        this.checkval = this.assign.users;
        this.selected_users = [...this.assign.users];
      });
    },

    AssignUser(id) {
      $("#assign-client-users").modal("show");
      // this.$root.$emit('bv::show::modal', 'assign-client-users')
      this.assign.client_id = id;
      this.assign.users = [];
      this.GetAllValidUsersList();
      this.getClientUnAssignUsers(id);
    },

    AssignUsersToClient() {
      var dataObj = {};
      dataObj.users = this.selected_users.map((item) => {
        return { id: item.id, alias: item.alias, order: item.order };
      });
      dataObj.client_id = this.assign.client_id;
      user.SetAssignedUsersToClient(dataObj).then((result) => {
        if (result.data.code == 200) {
          $("#assign-client-users").modal("hide");
          this.$swal(
            "Success",
            "User assigned successfully to client...",
            "success"
          );
        } else if (result.data.code == 401) {
          this.$swal("Error", "User Aliased Already exist...", "danger");
        }
      });
    },

    // sub clients
    search_subclient() {
      // this.isLoading = true;
      var fields = {
        filters: this.serverParams.filters,
        messageData: this.message,
        sort: this.serverParams.sort,
        page: this.serverParams.page,
        perpage: this.serverParams.perpage,
      };
      user.search_subclient(fields).then((result) => {
        this.reqObj = result.data.data.data;
        this.rows = result.data.data.data;
        this.FilteredRecords = result.data.data.total;
      });
    },

    deleteSubClient(id) {
      this.$swal({
        title: "Delete",
        text: "Are you sure you want to delete?",
        type: "warning",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#32a852",
        cancelButtonColor: "#f00",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      }).then((result) => {
        if (result.value) {
          // this.$swal('Deleted', 'You successfully delete this client', 'success')
          user.deleteSubClient(id).then((result) => {
            if (result.data.code == 424) {
              this.$swal(result.data.data, result.data.message, "warning");
            }
            if (result.data.code == 200) {
              this.$swal(result.data.data, result.data.message, "success");
            }
            this.search_subclient();
          });
        }
      });
    },

    subclientModalHandler(action, id) {
      if (action == "add") {
        this.modal_title = "Add SubClient";
        $("#SubClientModal").modal("show");
      } else if (action == "update") {
        this.modal_title = "Update SubClient";
        this.subclient_id = id;
        $("#SubClientModal").modal("show");
      } else {
        $("#SubClientModal").modal("hide");
        this.search_subclient();
      }
    },
    getAllSubClient() {
      user.getAllSubClient(this.reqObj).then((result) => {
        this.rows = [];
        this.rows = result.data.data.data;
        this.totalRecords = result.data.data.total;
      });
    },
  },
  computed: {
    ...mapState("userStore", ["currentUser"]),
    ClientStyles() {
      if (this.currentPage == "Clients") {
        return { color: "#583D72", "border-bottom": "3px solid #583D72" };
      } else {
        return { color: "#79869F", "border-bottom": "" };
      }
    },
    SubClientStyles() {
      if (this.currentPage == "SubClients") {
        return { color: "#583D72", "border-bottom": "3px solid #583D72" };
      } else {
        return { color: "#79869F", "border-bottom": "" };
      }
    },
  },
};
</script>
<style>
.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 0.4s ease;
}
.slide-fade-enter,
.slide-fade-leave-to {
  opacity: 0;
  transform: translateY(10px);
}
</style>
