var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mx-3 my-4"},[(_vm.permissions.includes('snapshot.report'))?_c('div',[_c('div',{staticClass:"mb-3"},[_c('div',[_c('h3',{staticClass:"text-dark"},[_vm._v("Snapshot")]),_c('ol',{staticClass:"breadcrumb m-0"},[_c('li',{staticClass:"breadcrumb-item"},[_c('router-link',{attrs:{"to":{ name: 'DashboardContent' },"tag":"a"}},[_vm._v(" Dashboard ")])],1),_c('li',{staticClass:"breadcrumb-item active"},[_vm._v("Snapshot")])])])]),_c('redmine-filter',{ref:"redmineFilter",attrs:{"page":"snapshot_report","serverParams":_vm.serverParams},on:{"redmine-filter-data":_vm.redmineFilterHandler,"reset-server-params":_vm.onResetServerParams}}),_c('div',{staticClass:"card shadow-sm border-0 my-4"},[_c('div',{staticClass:"card-body"},[(Array.isArray(_vm.rows) && _vm.rows.length == 0)?_c('div',{key:"spinner",staticClass:"spinner-border text-primary my-5 mx-auto d-block",staticStyle:{"width":"4.5rem","height":"4.5rem"}}):(_vm.rows == null)?_c('div',{key:"nodata",staticClass:"text-center p-5"},[_c('h3',[_vm._v("No data available")])]):_c('div',{key:"table"},[_c('h5',{staticClass:"text-primary text-md-start text-center mb-2 m-0",domProps:{"textContent":_vm._s(`Total: ${_vm.rows.length}`)}}),_c('div',{staticClass:"overflow-auto"},[_c('vue-good-table',{attrs:{"rows":_vm.rows,"columns":_vm.column,"max-height":"800px","styleClass":"vgt-table striped no-border","sort-options":{
                enabled: true,
                initialSortBy: { field: 'username', type: 'asc' },
              }},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(
                    props.column.label == 'Leaves Applied' && _vm.rows.length > 0
                  )?_c('div',[_c('popper',{attrs:{"disabled":_vm.rows[props.row.originalIndex].applied_leaves == null,"append-to-body":true,"trigger":_vm.popperOptions.trigger,"options":_vm.popperOptions.options}},[_c('div',{staticClass:"popper p-2 text-start"},_vm._l((_vm.rows[props.row.originalIndex]
                          .applied_leaves),function(item){return _c('small',{staticClass:"d-block",domProps:{"textContent":_vm._s(item)}})}),0),_c('div',{staticClass:"d-inline-block",attrs:{"slot":"reference"},slot:"reference"},[_vm._v(" Total : "+_vm._s(props.row.total_leaves)+" ")])])],1):(
                    props.column.label == 'Leaves Approved' && _vm.rows.length > 0
                  )?_c('div',[_c('popper',{attrs:{"disabled":_vm.rows[props.row.originalIndex].approved_leaves == null,"trigger":_vm.popperOptions.trigger,"options":_vm.popperOptions.options}},[_c('div',{staticClass:"popper p-2 text-start"},_vm._l((_vm.rows[props.row.originalIndex]
                          .approved_leaves),function(item){return _c('small',{staticClass:"d-block",domProps:{"textContent":_vm._s(item)}})}),0),_c('div',{staticClass:"d-inline-block",attrs:{"slot":"reference"},slot:"reference"},[_vm._v(" "+_vm._s(props.row.total_approved_leaves)+" ")])])],1):(
                    props.column.label == 'Discrepancy' && _vm.rows.length > 0
                  )?_c('div',[_c('popper',{attrs:{"disabled":_vm.rows[props.row.originalIndex].discrepancy_dates == null,"clas":"border","trigger":_vm.popperOptions.trigger,"options":_vm.popperOptions.options}},[_c('div',{staticClass:"popper p-2"},[_c('div',{staticClass:"text-start",staticStyle:{"font-size":"12px"}},[_c('span',[_vm._v("Total : "+_vm._s(_vm.rows[props.row.originalIndex] .total_discrepancy_hours))])]),_vm._l((_vm.rows[props.row.originalIndex]
                          .discrepancy_dates),function(item){return _c('small',{staticClass:"d-block cursor-pointer link link-primary link-underline link-underline-opactity-0 link-underline-opacity-100-hover text-start",on:{"click":function($event){return _vm.redirectToReport(
                            _vm.rows[props.row.originalIndex].id,
                            item.date
                          )}}},[_vm._v(" "+_vm._s(item.date)+" ("+_vm._s(item.hours)+") ")])})],2),_c('div',{staticClass:"d-inline-block cursor-pointer",attrs:{"slot":"reference"},slot:"reference"},[_vm._v(" "+_vm._s(props.row.total_discrepancy_count)+" ")])])],1):_vm._e()]}}],null,false,4294845885)})],1)])])])],1):_c('div',[_c('UnauthorizedPage')],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }