<template>
  <div class="my-4 mx-3">
    <div v-if="currentUser.user_role == 1">
      <div
        class="d-flex flex-md-row flex-column justify-content-between align-items-md-center pb-3 mt-3 gap-md-0 gap-3"
      >
        <div>
          <h3 class="text-dark">Asset Category</h3>
          <ol class="breadcrumb m-0">
            <li class="breadcrumb-item">
              <router-link :to="{ name: 'DashboardContent' }" tag="a">
                Dashboard
              </router-link>
            </li>
            <li class="breadcrumb-item active">Asset's Category</li>
          </ol>
        </div>
        <router-link
          tag="button"
          :to="{ name: 'AddAssetsCategory' }"
          class="btn btn-primary ms-md-auto me-md-0 me-auto btn-sm"
        >
          Add Category
        </router-link>
      </div>
      <redmineFilter
        ref="redmineFilter"
        page="categories"
        @redmine-filter-data="redmineFilterHandler"
        @reset-server-params="onResetServerParams"
        :serverParams="serverParams"
      ></redmineFilter>
      <div class="card shadow-sm border-0 my-4">
        <div class="card-body">
          <div class="d-flex justify-content-end pb-1"></div>
          <div class="p-2">
            <vue-good-table
              styleClass="vgt-table no-border"
              mode="remote"
              @on-page-change="onPageChange"
              @on-sort-change="onSortChange"
              @on-column-filter="onColumnFilter"
              @on-per-page-change="onPerPageChange"
              :totalRows="total_records"
              :isLoading.sync="isLoading"
              :rows="category_rows"
              :columns="category_columns"
              :pagination-options="{
                enabled: true,
                perPageDropdown: perpage_list,
              }"
            >
              <template
                slot="column-filter"
                slot-scope="{ column, updateFilters }"
              >
                <span v-if="column.field == 'total_assets'">
                  <input
                    type="number"
                    min="0"
                    class="form-control rounded-1"
                    style="height: 25.47px; font-size: 14px"
                    placeholder="No of assets"
                    @input="(e) => updateFilters(column, e.target.value)"
                  />
                </span>
                <span v-else-if="column.field == 'total_fields'">
                  <input
                    type="number"
                    min="0"
                    class="form-control rounded-1"
                    style="height: 25.47px; font-size: 14px"
                    placeholder="No of fields"
                    @input="(e) => updateFilters(column, e.target.value)"
                  />
                </span>
              </template>
              <template slot="table-row" slot-scope="props">
                <template v-if="props.column.field == 'action'">
                  <div
                    class="d-flex justify-content-center align-items-center gap-3 p-2"
                  >
                    <router-link
                      tag="button"
                      :to="'/update-assets-category/' + props.row.id"
                      class="btn-pencil"
                    >
                      <i class="fa fa-pencil fs-5"></i>
                    </router-link>
                    <button
                      class="btn-trash"
                      @click="deleteAssetCategory(props.row.id)"
                    >
                      <i class="fa fa-trash fs-5"></i>
                    </button>
                  </div>
                </template>
              </template>
              <template slot="pagination-bottom" slot-scope="props">
                <CustomPagination
                  class="p-2"
                  :pageTabs="4"
                  :serverParams="serverParams"
                  :total="props.total"
                  :pageChanged="props.pageChanged"
                  :perPageChanged="props.perPageChanged"
                ></CustomPagination>
              </template>
            </vue-good-table>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <h2 class="text-muted">Unauthorized access</h2>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import user from "@/ajax/user";
import Multiselect from "vue-multiselect";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import VueGoodTable from "vue-good-table";
import { mapState, mapActions } from "vuex";
import CustomPagination from "../../components/CustomPagination.vue";
import myMixin from "@/mixins/Permission";
import redmineFilter from "../../components/redmine_filter.vue";

Vue.use(VueGoodTable);
// import { Swal } from "sweetalert2/dist/sweetalert2";
export default {
  computed: {
    ...mapState("userStore", ["currentUser"]),
  },
  mixins: [myMixin],
  components: {
    Multiselect,
    flatPickr,
    CustomPagination,
    redmineFilter,
  },
  data() {
    return {
      siteLogReq: {
        site_name: "assets_categories",
      },
      category_rows: [],
      category_columns: [
        {
          label: "Category name",
          field: "name",
          filterable: true,
          thClass: "custom-th",
          tdClass: "custom-td",
          filterOptions: {
            enabled: false,
            trigger: "enter",
          },
        },
        {
          label: "Category Prefix",
          field: "prefix",
          filterable: true,
          thClass: "custom-th",
          tdClass: "custom-td",
          filterOptions: {
            enabled: false,
            trigger: "enter",
          },
        },
        // {
        //   label: "Category Fields",
        //   field: "total_fields",
        //   filterable: true,
        //   thClass: "custom-th",
        //   tdClass: "custom-td",
        //   filterOptions: {
        //     enabled: true,
        //     trigger: "enter",
        //   },
        // },
        {
          label: "No. of Assets",
          field: "total_assets",
          filterable: true,
          thClass: "custom-th",
          tdClass: "custom-td",
          filterOptions: {
            enabled: false,
            trigger: "enter",
          },
        },
        {
          label: "Action",
          field: "action",
          thClass: "custom-th",
          tdClass: "custom-td text-nowrap",
          sortable: false,
        },
      ],
      serverParams: {
        filters: {},
        sort: [
          {
            field: "",
            type: "asc",
          },
        ],
        page: 1,
        perpage: 100,
      },
      isLoading: false,
      total_records: 0,
      perpage_list: [100, 200, 300, 400, 500],
    };
  },
  mounted() {
    this.setCurrentPage("Assets");
    this.getUserPermissions();
    // this.getAssetCategory();
  },
  created() {
    user.createSiteUsage(this.siteLogReq);
  },
  methods: {
    ...mapActions("common", ["setCurrentPage"]),
    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      this.getAssetCategory();
    },

    onPerPageChange(params) {
      this.updateParams({ perpage: params.currentPerPage });
      this.serverParams.page = 1;
      this.getAssetCategory();
    },

    onSortChange(params) {
      this.updateParams({
        sort: [
          {
            type: params[0].type,
            field: params[0].field,
          },
        ],
      });
      this.getAssetCategory();
    },

    onColumnFilter(params) {
      //remove empty fields
      // var newObj = { filters: {} };
      // Object.keys(params.filters).forEach(function (key) {
      //   if (params.filters[key] !== "")
      //     newObj.filters[key] = params.filters[key];
      // });
      // this.updateParams(newObj);
      // this.serverParams.page = 1;
      // this.getAssetCategory();
    },
    redmineFilterHandler() {
      this.getAssetCategory();
    },
    onResetServerParams(serverParams) {
      this.serverParams = Object.assign({}, serverParams);
      this.$nextTick(() => {
        this.$refs.redmineFilter.applyFilter();
      });
    },
    getAssetCategory() {
      //console.log("Get assets category called ---------- ", this.serverParams);
      var fields = {
        filters: this.serverParams.filters,
        messageData: this.message,
        sort: this.serverParams.sort,
        page: this.serverParams.page,
        perpage: this.serverParams.perpage,
      };
      user.getAssetCategory(fields).then((res) => {
        this.category_rows = [];
        this.category_rows = res.data.data.data;
        this.total_records = res.data.data.total;
      });
    },
    deleteAssetCategory(id) {
      Swal.fire({
        title: "Do you want to delete category ?",
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        icon: "warning",
      }).then((res) => {
        if (res.isConfirmed) {
          user
            .deleteAssetCategory(id)
            .then((res) => {
              Swal.fire({
                title: res.data.message,
                icon: "success",
              });
              this.collpased = !this.collapsed;
              this.getAssetCategory();
            })
            .catch((err) => {
              Swal.fire({
                title: err.response.data.detail,
                icon: "error",
              });
            });
        }
      });
    },
  },
};
</script>

<style scoped>
.title {
  color: black;
}
.modal-dialog {
  max-width: 60rem;
}
.category-field-input {
  width: 100%;
  padding-bottom: 8px;
  border: 0px;
  outline: 0px;
  border-bottom: 2px solid lightgray;
  color: gray;
}
.category-field-input:focus {
  border-color: skyblue;
}
</style>
