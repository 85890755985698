<template>
  <!-- Begin Page Content -->
  <div class="my-4 mx-3">
    <h4 class="text-dark m-0">Goals</h4>
    <ul class="nav border-bottom text-center">
      <li
        class="nav-item p-0 me-2"
        :style="yourStyle"
        @click="pageChanger('yr_goals')"
      >
        <a
          class="nav-link px-0 py-2 fs-6"
          style="color: inherit; cursor: pointer"
          >Your</a
        >
      </li>

      <li
        class="nav-item p-0"
        style="cursor: pointer"
        :style="teamStyle"
        @click="pageChanger('tm_goals')"
      >
        <a class="nav-link px-0 py-2 fs-6" style="color: inherit">Team</a>
      </li>
      <li class="ms-auto" v-if="this.currentPage == 'yr_goals'">
        <button
          class="btn btn-primary btn-sm"
          @click="goalModalHandler('add', undefined, 'your')"
        >
          Add Goal
        </button>
      </li>
    </ul>
    <div v-if="this.currentPage == 'yr_goals'">
      <redmineFilter
        ref="redmineFilter"
        page="yr_goals"
        @redmine-filter-data="redmineFilterHandler"
        @reset-server-params="onResetServerParams"
        :serverParams="serverParams"
        key="yr_goals"
      ></redmineFilter>
    </div>
    <div v-if="this.currentPage == 'tm_goals'">
      <redmineFilter
        ref="redmineFilter"
        page="tm_goals"
        @redmine-filter-data="redmineFilterHandlerTeamGoals"
        @reset-server-params="onResetServerParams"
        :serverParams="serverParams"
        key="tm_goals"
      ></redmineFilter>
    </div>
    <div
      class="card border-0 shadow-sm my-4"
      v-if="permissions.includes('goals.read')"
    >
      <div class="card-body">
        <transition name="slide-fade" mode="out-in">
          <div class="p-2" v-if="this.currentPage == 'yr_goals'" key="yr_goal">
            <div
              class="d-flex justify-content-end align-items-center px-2 pb-3"
            >
              <!-- <router-link
          v-if="permissions.includes('goals.add')"
          class="btn btn-primary"
          :to="'/add-goal'"
          v-bind:class="[
            currentPage == 'AddGoal' ? 'active' : '',
            'nav-item',
          ]"
        >
          Add Goal
        </router-link> -->
            </div>
            <div class="table-responsive">
              <vue-good-table
                styleClass="vgt-table no-border"
                mode="remote"
                @on-page-change="onPageChangeYourGoals"
                @on-sort-change="onSortChangeYourGoals"
                @on-column-filter="onColumnFilterYourGoals"
                @on-per-page-change="onPerPageChangeYourGoals"
                :totalRows="Filtered_Records_Your_Goals"
                :isLoading.sync="isLoadingYourGoals"
                :pagination-options="{
                  enabled: true,
                  perpageDropdown: perpage_list,
                }"
                :rows="your_goals_list_row"
                :columns="your_goals_list_column"
              >
                <template slot="table-row" slot-scope="props">
                  <span
                    class="table-icon d-inline-block"
                    v-if="props.column.field == 'action'"
                  >
                    <button
                      class="btn-pencil"
                      @click="goalModalHandler('update', props.row.id, 'your')"
                      v-if="
                        props.row.created_by == currentUser.id &&
                        permissions.includes('goals.update')
                      "
                    >
                      <i class="fa fa-pencil fs-5"></i>
                    </button>
                  </span>
                  <span v-else-if="props.column.field == 'assigned_by'">
                    <p v-if="props.row.created_by == props.row.user_id">Self</p>
                    <p v-else>{{ ConcateFirstNameAndLastName(props.row) }}</p>
                  </span>
                  <span v-else-if="props.column.field == 'description'">
                    <read-more
                      more-str="read more"
                      :text="props.row.description"
                      less-str="read less"
                      :max-chars="100"
                    ></read-more>
                  </span>
                  <span v-else-if="props.column.field == 'progress'">
                    <div class="">
                      <span class="fw-bold d-block"
                        >{{
                          ((props.row.progress / 100) * 100).toFixed(0)
                        }}%</span
                      >
                      <small
                        style="cursor: pointer"
                        class="text-primary"
                        @click="UpdateProgress(props.row)"
                        >update</small
                      >
                    </div>
                  </span>
                  <span v-else>
                    {{ props.formattedRow[props.column.field] }}
                  </span>
                </template>
                <template
                  slot="column-filter"
                  slot-scope="{ column, updateFilters }"
                >
                  <span v-if="column.field == 'start_date'">
                    <datepicker
                      :clear-button="true"
                      :clear-button-icon="'fa fa-times'"
                      :format="formateDate"
                      placeholder="yyyy-mm-dd"
                      @input="
                        (e) =>
                          updateFilters(
                            column,
                            e
                              ? e.toISOString().substring(0, 10)
                              : updateFilters(column, '')
                          )
                      "
                    />
                  </span>
                  <span v-else-if="column.field == 'end_date'">
                    <datepicker
                      :clear-button="true"
                      :clear-button-icon="'fa fa-times'"
                      :format="formateDate"
                      placeholder="yyyy-mm-dd"
                      @input="
                        (e) =>
                          updateFilters(
                            column,
                            e
                              ? e.toISOString().substring(0, 10)
                              : updateFilters(column, '')
                          )
                      "
                    />
                  </span>
                  <span v-else-if="column.field == 'progress'">
                    <input
                      type="number"
                      min="0"
                      class="form-control rounded-1"
                      style="height: 25.47px; font-size: 14px"
                      placeholder="progress"
                      @input="(e) => updateFilters(column, e.target.value)"
                    />
                  </span>
                </template>
                <template slot="pagination-bottom" slot-scope="props">
                  <CustomPagination
                    class="p-2"
                    :pageTabs="4"
                    :total="props.total"
                    :pageChanged="props.pageChanged"
                    :perPageChanged="props.perPageChanged"
                    :serverParams="serverParams"
                  ></CustomPagination>
                </template>
              </vue-good-table>
            </div>
          </div>

          <div class="p-2" v-if="this.currentPage == 'tm_goals'" key="tm_goal">
            <div class="table-responsive">
              <vue-good-table
                styleClass="vgt-table no-border"
                mode="remote"
                @on-page-change="onPageChangeTeamMatesGoals"
                @on-sort-change="onSortChangeTeamMatesGoals"
                @on-column-filter="onColumnFilterTeamMatesGoals"
                @on-per-page-change="onPerPageChangeTeamMatesGoals"
                :totalRows="Filtered_Records_Team_mates_Goals"
                :isLoading.sync="isLoadingTeamMatesGoals"
                :pagination-options="{
                  enabled: true,
                  perpageDropdown: perpage_list,
                }"
                :rows="team_mates_goals_list_row"
                :columns="team_mates_goals_list_column"
              >
                <template slot="table-row" slot-scope="props">
                  <span class="p-2" v-if="props.column.field == 'action'">
                    <button
                      class="btn-pencil"
                      v-if="
                        props.row.created_by == currentUser.id &&
                        permissions.includes('goals.update')
                      "
                      @click="goalModalHandler('update', props.row.id, 'team')"
                    >
                      <i class="fa fa-pencil fs-5"></i>
                    </button>
                    <!-- <div class="" v-else></div> -->
                  </span>
                  <span v-else-if="props.column.field == 'assigned_by'">
                    <p>{{ ConcateAssignedBy(props.row) }}</p>
                  </span>
                  <span v-else-if="props.column.field == 'username'">
                    <p>{{ ConcateFirstNameAndLastName(props.row) }}</p>
                  </span>
                  <span v-if="props.column.field == 'description'">
                    <read-more
                      more-str="read more"
                      :text="props.row.description"
                      less-str="read less"
                      :max-chars="100"
                    ></read-more>
                  </span>
                  <span v-else-if="props.column.field == 'progress'">
                    {{ ((props.row.progress / 100) * 100).toFixed(0) }}%
                  </span>
                  <span v-else>
                    {{ props.formattedRow[props.column.field] }}
                  </span>
                </template>
                <template
                  slot="column-filter"
                  slot-scope="{ column, updateFilters }"
                >
                  <span v-if="column.field == 'start_date'">
                    <datepicker
                      :clear-button="true"
                      :clear-button-icon="'fa fa-times'"
                      :format="formateDate"
                      placeholder="yyyy-mm-dd"
                      @input="
                        (e) =>
                          updateFilters(
                            column,
                            e
                              ? e.toISOString().substring(0, 10)
                              : updateFilters(column, '')
                          )
                      "
                    />
                  </span>
                  <span v-else-if="column.field == 'end_date'">
                    <datepicker
                      :clear-button="true"
                      :clear-button-icon="'fa fa-times'"
                      :format="formateDate"
                      placeholder="yyyy-mm-dd"
                      @input="
                        (e) =>
                          updateFilters(
                            column,
                            e
                              ? e.toISOString().substring(0, 10)
                              : updateFilters(column, '')
                          )
                      "
                    />
                  </span>
                  <span v-else-if="column.field == 'progress'">
                    <input
                      type="number"
                      min="0"
                      class="form-control rounded-1"
                      style="height: 25.47px; font-size: 14px"
                      placeholder="progress"
                      @input="(e) => updateFilters(column, e.target.value)"
                    />
                  </span>
                </template>
                <template slot="pagination-bottom" slot-scope="props">
                  <CustomPagination
                    class="p-2"
                    :pageTabs="4"
                    :total="props.total"
                    :pageChanged="props.pageChanged"
                    :perPageChanged="props.perPageChanged"
                    :serverParams="serverParams"
                  ></CustomPagination>
                </template>
              </vue-good-table>
            </div>
          </div>
        </transition>
      </div>
    </div>
    <div v-else>
      <UnauthorizedPage></UnauthorizedPage>
    </div>
    <!-- Client add/update modal -->
    <div
      class="modal fade"
      id="goalModal"
      role="dialog"
      aria-hidden="true"
      tabindex="-1"
    >
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h3
              class="m-0 text-dark"
              id="taskModalLongTitle"
              v-text="modal_title"
            ></h3>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <add-goals
              v-if="modal_title == 'Add Goal'"
              @close-modal="goalModalHandler('close')"
            ></add-goals>
            <add-goals
              v-if="modal_title == 'Update Goal'"
              @close-modal="goalModalHandler('close')"
              :goal_id="goal_id"
            ></add-goals>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- End of Main Content -->
</template>
<script>
import user from "@/ajax/user";
import { mapState, mapActions } from "vuex";
import Vue from "vue";
import moment from "moment";
moment.locale("en");
import myMixin from "@/mixins/Permission";
import Datepicker from "vuejs-datepicker";
import axios from "axios";
import UnauthorizedPage from "@/views/Layouts/UnauthorizedPage.vue";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import ReadMore from "vue-read-more";
import AddGoals from "./AddGoals.vue";
import CustomPagination from "../../components/CustomPagination.vue";
import redmineFilter from "../../components/redmine_filter.vue";

Vue.use(ReadMore);
Vue.use(VueSweetalert2);

// import VueAxios from 'vue-axios';
// Vue.use(VueAxios,axios)

export default {
  name: "Goals",
  mixins: [myMixin],

  components: {
    Datepicker,
    UnauthorizedPage,
    AddGoals,
    CustomPagination,
    redmineFilter,
  },
  data: function () {
    return {
      siteLogReq: {
        site_name: "goals",
      },
      permissions: window.localStorage.getItem("permissions"),
      currentPage: "yr_goals",
      // your goals table
      serverParams: {
        filters: {},
        sort: [
          {
            field: "",
            type: "asc",
          },
        ],
        page: 1,
        perpage: 100,
        setcurrentPage: 1,
      },
      total_records_of_your_goals: 0,
      Filtered_Records_Your_Goals: 0,
      isLoadingYourGoals: false,
      your_goals_list_row: [],

      your_goals_list_column: [
        {
          label: "Assigned By",
          field: "assigned_by",
          sortable: true,
          thClass: "custom-th",
          tdClass: "custom-td",
          filterable: true,
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Username", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.onColumnFilterTeamMatesGoals, //custom filter function that
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Title",
          field: "title",
          sortable: true,
          thClass: "custom-th",
          tdClass: "custom-td",
          filterable: true,
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Title", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.onColumnFilterYourGoals, //custom filter function that
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Description",
          field: "description",
          sortable: true,
          thClass: "custom-th",
          tdClass: "custom-td",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Description", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.onColumnFilterYourGoals, //custom filter function that
          },
        },
        {
          label: "Start Date",
          field: "start_date",
          sortable: true,
          thClass: "custom-th",
          tdClass: "custom-td",
          filterable: true,
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Start Date", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.onColumnFilterYourGoals, //custom filter function that
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "End Date",
          field: "end_date",
          sortable: true,
          thClass: "custom-th",
          tdClass: "custom-td",
          filterable: true,
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "End Date", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.onColumnFilterYourGoals, //custom filter function that
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Progress",
          field: "progress",
          sortable: true,
          thClass: "custom-th",
          tdClass: "custom-td",
          filterable: true,
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Progress", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.onColumnFilterYourGoals, //custom filter function that
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Action",
          field: "action",
          sortable: false,
          thClass: "custom-th disable-sorting",
          tdClass: "custom-td text-no wrap",
        },
      ],

      //your team mates goals table

      total_records_of_team_mates_goals: 0,
      Filtered_Records_Team_mates_Goals: 0,
      isLoadingTeamMatesGoals: false,
      team_mates_goals_list_row: [],

      team_mates_goals_list_column: [
        {
          label: "Assigned By",
          field: "assigned_by",
          sortable: true,
          thClass: "custom-th",
          tdClass: "custom-td",
          filterable: true,
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Username", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.onColumnFilterTeamMatesGoals, //custom filter function that
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Username",
          field: "username",
          sortable: true,
          thClass: "custom-th",
          tdClass: "custom-td",
          filterable: true,
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Username", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.onColumnFilterTeamMatesGoals, //custom filter function that
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Title",
          field: "title",
          sortable: true,
          thClass: "custom-th",
          tdClass: "custom-td",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Title", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.onColumnFilterTeamMatesGoals, //custom filter function that
          },
        },
        {
          label: "Description",
          field: "description",
          sortable: true,
          thClass: "custom-th",
          tdClass: "custom-td",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Description", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.onColumnFilterTeamMatesGoals, //custom filter function that
          },
        },
        {
          label: "Start Date",
          field: "start_date",
          sortable: true,
          thClass: "custom-th",
          tdClass: "custom-td",
          filterable: true,
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Start Date", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.onColumnFilterTeamMatesGoals, //custom filter function that
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "End Date",
          field: "end_date",
          sortable: true,
          thClass: "custom-th",
          tdClass: "custom-td",
          filterable: true,
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "End Date", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.onColumnFilterTeamMatesGoals, //custom filter function that
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Progress",
          field: "progress",
          sortable: true,
          thClass: "custom-th",
          tdClass: "custom-td",
          filterable: false,
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Progress", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.onColumnFilterTeamMatesGoals, //custom filter function that
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Action",
          field: "action",
          sortable: false,
          thClass: "custom-th disable-sorting",
          tdClass: "custom-td text-nowrap",
        },
      ],
      value: 50,
      perpage_list: [100, 200, 300, 400, 500],
      modal_title: null,
      goal_id: null,
      isFor: null,
    };
  },
  mounted() {
    this.setCurrentPage("Goals");
    this.getUserPermissions();
    $("#goalModal").on("hidden.bs.modal", this.hideModalEventHandler);
    if (this.permissions.includes("goals.read")) {
      // this.GetAssignedGoals();
      // this.GetTeamMatesGoals();
    }
  },
  created() {
    user.createSiteUsage(this.siteLogReq);
  },
  methods: {
    ...mapActions("userStore", ["setCurrentUser", "logoutUser"]),
    ...mapActions("common", ["setCurrentPage"]),
    hideModalEventHandler() {
      this.modal_title = null;
      this.goal_id = null;
      this.isFor = null;
      $("#goalModal").modal("hide");
    },
    pageChanger(page_name) {
      if (this.currentPage !== page_name) {
        this.currentPage = page_name; // Update currentPage
      }
    },

    goalModalHandler(action, id = null, isFor) {
      if (action == "add") {
        this.isFor = isFor;
        this.modal_title = "Add Goal";
        $("#goalModal").modal("show");
      } else if (action == "update") {
        this.isFor = isFor;
        this.modal_title = "Update Goal";
        this.goal_id = id;
        $("#goalModal").modal("show");
      } else {
        $("#goalModal").modal("hide");
        this.isFor == "your"
          ? this.GetAssignedGoals()
          : this.GetTeamMatesGoals();
      }
    },
    redmineFilterHandler() {
      this.GetAssignedGoals();
    },
    onResetServerParams(serverParams) {
      this.serverParams = Object.assign({}, serverParams);
      this.$nextTick(() => {
        this.$refs.redmineFilter.applyFilter();
      });
    },
    //first table
    GetAssignedGoals() {
      var fields = {
        filters: this.serverParams.filters,
        sort: this.serverParams.sort,
        page: this.serverParams.page,
        perpage: this.serverParams.perpage,
      };
      user.GetAssignedGoals(fields).then((result) => {
        this.your_goals_list_row = [];
        this.your_goals_list_row = result.data.data.data;
        this.Filtered_Records_Your_Goals = result.data.data.f_total;
        this.total_records_of_your_goals = result.data.data.total;
      });
    },
    //filter for first table

    updateParamsYourGoals(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },

    onPageChangeYourGoals(params) {
      this.updateParamsYourGoals({ page: params.currentPage });
      this.GetAssignedGoals();
    },

    onPerPageChangeYourGoals(params) {
      this.updateParamsYourGoals({ perpage: params.currentPerPage });
      this.serverParams.page = 1;
      this.GetAssignedGoals();
    },

    onSortChangeYourGoals(params) {
      this.updateParamsYourGoals({
        sort: [
          {
            type: params[0].type,
            field: params[0].field,
          },
        ],
      });
      this.GetAssignedGoals();
    },

    onColumnFilterYourGoals(params) {
      //remove empty fields
      var newObj = { columnFilters: Object.assign({}) };
      Object.keys(params.columnFilters).forEach(function (key) {
        if (params.columnFilters[key] !== "")
          newObj.columnFilters[key] = params.columnFilters[key];
      });
      this.updateParamsYourGoals(newObj);
      this.serverParams.page = 1;
      this.GetAssignedGoals();
    },

    redmineFilterHandlerTeamGoals() {
      this.GetTeamMatesGoals();
    },

    //second table
    GetTeamMatesGoals() {
      var fields = {
        filters: this.serverParams.filters,
        sort: this.serverParams.sort,
        page: this.serverParams.page,
        perpage: this.serverParams.perpage,
      };
      user.GetTeamMatesGoals(fields).then((result) => {
        this.team_mates_goals_list_row = [];
        this.team_mates_goals_list_row = result.data.data.data;
        this.Filtered_Records_Team_mates_Goals = result.data.data.f_total;
        this.total_records_of_team_mates_goals = result.data.data.total;
      });
    },
    //filter for second table
    updateParamsTeamMatesGoals(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },

    onPageChangeTeamMatesGoals(params) {
      this.updateParamsTeamMatesGoals({ page: params.currentPage });
      this.GetTeamMatesGoals();
    },

    onPerPageChangeTeamMatesGoals(params) {
      this.updateParamsTeamMatesGoals({ perpage: params.currentPerPage });
      this.serverParams.page = 1;
      this.GetTeamMatesGoals();
    },

    onSortChangeTeamMatesGoals(params) {
      this.updateParamsTeamMatesGoals({
        sort: [
          {
            type: params[0].type,
            field: params[0].field,
          },
        ],
      });
      this.GetTeamMatesGoals();
    },

    onColumnFilterTeamMatesGoals(params) {
      //remove empty fields
      var newObj = { columnFilters: Object.assign({}) };
      Object.keys(params.columnFilters).forEach(function (key) {
        if (params.columnFilters[key] !== "")
          newObj.columnFilters[key] = params.columnFilters[key];
      });
      this.updateParamsTeamMatesGoals(newObj);
      this.serverParams.page = 1;
      this.GetTeamMatesGoals();
    },
    //end filter of second table
    formateDate(date) {
      return moment(date).format("YYYY-MM-DD");
    },
    //concate first and last name
    ConcateFirstNameAndLastName: function (rowObj) {
      if (
        rowObj.first_name != null &&
        rowObj.last_name != null &&
        rowObj.middle_name != null
      ) {
        return (
          rowObj.first_name + " " + rowObj.last_name + " " + rowObj.middle_name
        );
      } else if (rowObj.middle_name == null) {
        return rowObj.first_name + " " + rowObj.last_name;
      } else if (rowObj.last_name == null && rowObj.middle_name == null) {
        return rowObj.first_name;
      }
    },

    ConcateAssignedBy: function (rowObj) {
      if (
        rowObj.ab_first_name != null &&
        rowObj.ab_last_name != null &&
        rowObj.ab_middle_name != null
      ) {
        return (
          rowObj.ab_first_name +
          " " +
          rowObj.ab_last_name +
          " " +
          rowObj.ab_middle_name
        );
      } else if (rowObj.ab_middle_name == null) {
        return rowObj.ab_first_name + " " + rowObj.ab_last_name;
      } else if (rowObj.ab_last_name == null && rowObj.ab_middle_name == null) {
        return rowObj.ab_first_name;
      }
    },

    UpdateProgress(data) {
      this.$swal({
        title: "Set Progress Percentage",
        input: "range",
        showCancelButton: true,
        confirmButtonText: "Submit",
        inputAttributes: {
          min: 0,
          max: 100,
          step: 1,
        },
        inputValue: data.progress,
      }).then((result) => {
        if (result.isConfirmed) {
          const update_progress = {
            action: "progress",
            start_date: data.start_date,
            end_date: data.end_date,
            title: data.title,
            description: data.title,
            assigned_to_user: data.user_id,
            progress: result.value,
            goal_id: data.id,
          };
          user.UpdateGoalDetails(update_progress).then((result) => {
            if (result.data.code == 200) {
              this.$swal("Success", "Goal Updated Succesfully", "success");
              this.GetAssignedGoals();
            } else {
              this.$swal("Error", "Goal not updated", "error");
            }
          });
        }
      });
    },
  },
  computed: {
    ...mapState("userStore", ["currentUser"]),
    yourStyle() {
      if (this.currentPage == "yr_goals") {
        return {
          color: "#583D72",
          "border-bottom": "3px solid #583D72",
          width: "4rem",
        };
      } else {
        return { color: "#79869F", "border-bottom": "", width: "4rem" };
      }
    },
    teamStyle() {
      if (this.currentPage == "tm_goals") {
        return {
          color: "#583D72",
          "border-bottom": "3px solid #583D72",
          width: "4rem",
        };
      } else {
        return { color: "#79869F", "border-bottom": "", width: "4rem" };
      }
    },
  },
};
</script>

<style scoped>
.startbuttons {
  padding: 5px 30px 5px 30px;
  margin: 10px;
  font-size: 20px;
}
.disableButton {
  opacity: 0.5;
  pointer-events: none;
}
.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 0.4s ease;
}
.slide-fade-enter,
.slide-fade-leave-to {
  opacity: 0;
  transform: translateY(10px);
}
</style>
