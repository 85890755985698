var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"my-4 mx-3"},[(_vm.currentUser.user_role == 1)?_c('div',[_c('div',{staticClass:"d-flex flex-md-row flex-column justify-content-between align-items-md-center pb-3 mt-3 gap-md-0 gap-3"},[_c('div',[_c('h3',{staticClass:"text-dark"},[_vm._v("Invoice's")]),_c('ol',{staticClass:"breadcrumb m-0"},[_c('li',{staticClass:"breadcrumb-item"},[_c('router-link',{attrs:{"to":{ name: 'DashboardContent' },"tag":"a"}},[_vm._v(" Dashboard ")])],1),_c('li',{staticClass:"breadcrumb-item active"},[_vm._v("Invoice's")])])]),_c('router-link',{staticClass:"btn btn-primary btn-sm ms-md-auto me-md-0 me-auto",attrs:{"tag":"button","to":{ name: 'AddInvoice' }}},[_vm._v(" Add Invoice ")])],1),_c('redmineFilter',{ref:"redmineFilter",attrs:{"page":"invoices","serverParams":_vm.serverParams},on:{"redmine-filter-data":_vm.redmineFilterHandler,"reset-server-params":_vm.onResetServerParams}}),_c('div',{staticClass:"card shadow-sm border-0 my-4"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"d-flex justify-content-end pb-1"}),_c('div',{staticClass:"p-2"},[_c('vue-good-table',{attrs:{"styleClass":"vgt-table no-border","mode":"remote","totalRows":_vm.total_records,"rows":_vm.invoice_rows,"columns":_vm.invoice_columns,"pagination-options":{
              enabled: true,
              perPageDropdown: _vm.perpage_list,
            }},on:{"on-page-change":_vm.onPageChange,"on-per-page-change":_vm.onPerPageChange,"on-sort-change":_vm.onSortChange,"on-column-filter":_vm.onColumnFilter},scopedSlots:_vm._u([{key:"column-filter",fn:function({ column, updateFilters }){return [(column.field == 'invoice_date')?_c('span',[_c('datepicker',{attrs:{"typeable":true,"placeholder":"yyyy-mm-dd"},on:{"input":(e) =>
                      updateFilters(
                        column,
                        e
                          ? e.toISOString().substring(0, 10)
                          : updateFilters(column, '')
                      )}})],1):(column.field == 'price')?_c('span',[_c('input',{staticClass:"form-control rounded-1",staticStyle:{"height":"25.47px","font-size":"14px"},attrs:{"type":"number","min":"0","placeholder":"price"},on:{"input":(e) => updateFilters(column, e.target.value)}})]):(column.field == 'total')?_c('span',[_c('input',{staticClass:"form-control rounded-1",staticStyle:{"height":"25.47px","font-size":"14px"},attrs:{"type":"number","min":"0","placeholder":"total"},on:{"input":(e) => updateFilters(column, e.target.value)}})]):(column.field == 'GST')?_c('span',[_c('input',{staticClass:"form-control rounded-1",staticStyle:{"height":"25.47px","font-size":"14px"},attrs:{"type":"number","min":"0","placeholder":"GST"},on:{"input":(e) => updateFilters(column, e.target.value)}})]):(column.field == 'total_assets')?_c('span',[_c('input',{staticClass:"form-control rounded-1",staticStyle:{"height":"25.47px","font-size":"14px"},attrs:{"type":"number","min":"0","placeholder":"No of assets"},on:{"input":(e) => updateFilters(column, e.target.value)}})]):_vm._e()]}},{key:"table-row",fn:function(props){return [(props.column.field == 'action')?_c('span',{staticClass:"d-flex justify-content-center align-items-center p-2 gap-3"},[_c('router-link',{staticClass:"btn btn-pencil",attrs:{"tag":"button","to":'/update-invoice/' + props.row.id}},[_c('i',{staticClass:"fa fa-pencil fs-5"})]),_c('button',{staticClass:"btn btn-trash",on:{"click":function($event){return _vm.deleteInvoice(props.row.id)}}},[_c('i',{staticClass:"fa fa-trash fs-5"})])],1):_vm._e()]}},{key:"pagination-bottom",fn:function(props){return [_c('CustomPagination',{staticClass:"p-2",attrs:{"pageTabs":4,"serverParams":_vm.serverParams,"total":props.total,"pageChanged":props.pageChanged,"perPageChanged":props.perPageChanged}})]}}],null,false,2906618787)})],1)])])],1):_c('div',[_c('h2',{staticClass:"text-muted"},[_vm._v("Unauthorized Access")])])])
}
var staticRenderFns = []

export { render, staticRenderFns }