<template>
  <div v-if="permissions.includes('summary_report.read')">
    <div>
      <div class="border-0 mt-4 mx-3">
        <div :class="currentUser.user_role == 1 ? '' : 'pb-3'">
          <h3 class="text-dark'">Summary Report</h3>
          <ul
            class="nav border-bottom text-center"
            v-if="currentUser.user_role == 1"
          >
            <li
              class="nav-item p-0 me-2"
              @click="pageChanger('your_summary')"
              :style="yourStyle"
            >
              <a
                class="nav-link px-0 py-2 fs-6"
                style="color: inherit; cursor: pointer"
                >Your</a
              >
            </li>

            <li
              class="nav-item p-0"
              style="cursor: pointer"
              :style="teamStyle"
              @click="pageChanger('team_summary')"
            >
              <a class="nav-link px-0 py-2 fs-6" style="color: inherit">Team</a>
            </li>
            <li
              class="ms-auto d-md-block d-none"
              v-if="current_page == 'your_summary'"
            >
              <button
                class="btn btn-primary ms-md-auto mx-md-0 mx-auto mt-md-0 mt-3 btn-sm"
                @click="reportSummaryModalHandler"
              >
                Add Summary
              </button>
            </li>
          </ul>
          <ol v-else class="breadcrumb m-0">
            <li class="breadcrumb-item">
              <router-link :to="{ name: 'DashboardContent' }" tag="a">
                Dashboard
              </router-link>
            </li>
            <li class="breadcrumb-item active">Summary Report</li>
          </ol>
          <button
            v-if="current_page == 'your_summary'"
            class="btn btn-primary btn-sm d-md-none d-block my-3"
            @click="reportSummaryModalHandler"
          >
            Add Summary
          </button>
        </div>
        <div class="bg-white p-4 shadow-sm rounded-bottom mb-4">
          <transition name="slide-fade" mode="out-in">
            <div v-if="current_page == 'your_summary'" key="your_summary">
              <div
                class="d-flex flex-wrap justify-content-md-start justify-content-center align-items-center"
              >
                <datepicker
                  :minimumView="'month'"
                  :maximumView="'year'"
                  :disabledDates="disableDateConfig"
                  v-model="your_filter_obj.selected_date"
                  :format="'yyyy-MM'"
                  :calendar-button="true"
                  :input-class="'border-start-0'"
                  :calendar-button-icon="'fa fa-calendar'"
                  :bootstrap-styling="true"
                  @selected="getYourSummaryReports"
                />
              </div>
              <div class="mt-3">
                <vue-good-table
                  mode="remote"
                  @on-page-change="onYourSummaryReportPageChange"
                  @on-sort-change="onYourSummaryReportSortChange"
                  @on-column-filter="onYourSummaryReportColumnFilter"
                  @on-per-page-change="onYourSummaryReportPerPageChange"
                  :totalRows="your_filter_obj.totalRecords"
                  :isLoading.sync="your_filter_obj.isLoading"
                  :pagination-options="{
                    enabled: true,
                  }"
                  :rows="your_filter_obj.rows"
                  :columns="your_filter_obj.columns"
                  styleClass="vgt-table "
                >
                  <template slot="table-row" slot-scope="props">
                    <div v-if="props.column.field == 'action'">
                      <div
                        class="d-flex justify-content-center align-items-center gap-3"
                      >
                        <button
                          class="btn-pencil bg-transparent"
                          @click="reportSummaryModalHandler($event, props.row)"
                        >
                          <i class="fa fa-pencil fs-5"></i>
                        </button>
                        <button
                          class="btn-trash bg-transparent"
                          @click="deleteSummaryReport(props.row.id)"
                        >
                          <i class="fa fa-trash fs-5"></i>
                        </button>
                      </div>
                    </div>
                    <div v-else-if="props.column.field == 'summary'">
                      <readMore
                        more-str="read more"
                        :text="props.row.summary"
                        less-str="read less"
                        :max-chars="100"
                      >
                      </readMore>
                    </div>
                    <!-- <div v-else-if="props.column.field == 'date_range_label'">
                    <h6>
                      <span
                        class="badge badge-light border mt-1"
                        v-text="props.row.date_range_label"
                      ></span>
                    </h6>
                  </div> -->
                  </template>
                  <template slot="pagination-bottom" slot-scope="props">
                    <div class="p-2">
                      <CustomPagination
                        :pageTabs="4"
                        :total="props.total"
                        :pageChanged="props.pageChanged"
                        :perPageChanged="props.perPageChanged"
                        :serverParams="your_filter_obj.serverParams"
                      />
                    </div>
                  </template>
                </vue-good-table>
              </div>
            </div>

            <div v-else>
              <div
                class="d-flex gap-2 flex-wrap align-items-center justify-content-md-start justify-content-center"
                key="team_summary"
              >
                <datepicker
                  :minimumView="'month'"
                  :maximumView="'year'"
                  :disabledDates="disableDateConfig"
                  v-model="team_filter_obj.selected_date"
                  :format="'yyyy-MM'"
                  :calendar-button="true"
                  :input-class="'border-start-0'"
                  :calendar-button-icon="'fa fa-calendar'"
                  :bootstrap-styling="true"
                  @selected="generateTeamMonthOptions"
                />
                <div class="col-lg-3 col-md-3 col-12">
                  <multiselect
                    :options="user_list"
                    v-model="team_filter_obj.users"
                    track-by="id"
                    label="full_name"
                    :multiple="true"
                    group-label="label"
                    group-values="users_data"
                    :group-select="true"
                    :close-on-select="false"
                    :custom-label="custom_label"
                    placeholder="Select the user(s)"
                  >
                  </multiselect>
                </div>
                <div class="col-lg-3 col-md-3 col-12">
                  <multiselect
                    v-model="team_filter_obj.selected_view_options"
                    :options="team_filter_obj.view_options"
                    label="label"
                    placeholder="Select View Option"
                    :allow-empty="false"
                  ></multiselect>
                </div>
                <div class="col-lg-3 col-md-3 col-12">
                  <multiselect
                    v-model="team_filter_obj.selected_month_options"
                    :options="team_filter_obj.month_options"
                    label="label"
                    placeholder="Select Week"
                  ></multiselect>
                </div>
                <button
                  class="btn btn-primary ms-md-auto mx-md-0 mx-auto"
                  @click="getTeamSummaryReports"
                >
                  Filter
                </button>
              </div>
              <div class="mt-3">
                <vue-good-table
                  mode="remote"
                  @on-page-change="onTeamSummaryReportPageChange"
                  @on-sort-change="onTeamSummaryReportSortChange"
                  @on-column-filter="onTeamSummaryReportColumnFilter"
                  @on-per-page-change="onTeamSummaryReportPerPageChange"
                  :totalRows="team_filter_obj.totalRecords"
                  :isLoading.sync="team_filter_obj.isLoading"
                  :pagination-options="{
                    enabled: true,
                  }"
                  :rows="team_filter_obj.rows"
                  :columns="team_filter_obj.columns"
                  styleClass="vgt-table "
                >
                  <template slot="table-row" slot-scope="props">
                    <div v-if="props.column.field == 'summary'">
                      <readMore
                        more-str="read more"
                        :text="props.row.summary"
                        less-str="read less"
                        :max-chars="100"
                      >
                      </readMore>
                    </div>
                    <!-- <div v-if="props.column.field == 'action'">
                  <div
                    class="d-flex justify-content-center align-items-center gap-3"
                  >
                    <button class="btn-pencil">
                      <i class="fa fa-pencil fs-5"></i>
                    </button>
                    <button class="btn-trash">
                      <i class="fa fa-trash fs-5"></i>
                    </button>
                  </div>
                </div> -->
                  </template>
                  <template slot="pagination-bottom" slot-scope="props">
                    <div class="p-2">
                      <CustomPagination
                        :pageTabs="4"
                        :total="props.total"
                        :pageChanged="props.pageChanged"
                        :perPageChanged="props.perPageChanged"
                        :serverParams="team_filter_obj.serverParams"
                      />
                    </div>
                  </template>
                </vue-good-table>
              </div>
            </div>
          </transition>
        </div>
      </div>
      <!-- Add summary model -->
      <div
        class="modal fade"
        id="summaryReportModal"
        role="dialog"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        aria-hidden="true"
        tabindex="-1"
      >
        <div
          class="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <div class="modal-content">
            <div class="modal-header">
              <h3
                class="text-dark font-weight-bold m-0"
                id="taskModalLongTitle"
                v-text="title"
              ></h3>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <div class="row">
                <div class="col-6">
                  <small class="mandatory">Select Month</small>
                  <div class="pt-1"></div>
                  <datepicker
                    :minimumView="'month'"
                    :maximumView="'year'"
                    :disabledDates="disableDateConfig"
                    v-model="popupObj.selected_month"
                    :format="'yyyy-MM'"
                    :calendar-button="true"
                    :input-class="'border-start-0 col-12'"
                    :calendar-button-icon="'fa fa-calendar'"
                    :bootstrap-styling="true"
                    @selected="generateYourMonthOptions"
                  />
                  <small
                    class="text-danger"
                    v-if="popupObj.validation.month"
                    v-text="popupObj.validation.month"
                  ></small>
                </div>
                <div class="col-6">
                  <small class="">Select Week</small>
                  <multiselect
                    :options="popupObj.month_week_options"
                    v-model="popupObj.selected_month_week_options"
                    label="label"
                    class="mt-1"
                  ></multiselect>
                </div>
              </div>

              <div class="mt-3">
                <div class="d-flex flex-column">
                  <small class="form-label mandatory"
                    >Description/Summary</small
                  >
                  <textarea
                    class="form-control"
                    rows="5"
                    placeholder="Enter the description/summary here..."
                    v-model="popupObj.summary"
                    @input="formatSummary"
                  ></textarea>
                  <div
                    class="d-flex justify-content-between align-items-center"
                  >
                    <small
                      class="text-danger"
                      v-if="popupObj.validation.summary"
                      v-text="popupObj.validation.summary"
                    ></small>
                    <small class="ms-auto text-secondary mt-1">{{
                      summaryWordCount
                    }}</small>
                  </div>
                </div>
              </div>
              <div
                class="d-flex justify-content-end align-items-center gap-2 mt-3"
              >
                <div class="btn btn-primary btn-sm" @click="submitBtnHandler">
                  Submit
                </div>
                <div
                  class="btn btn-outline-secondary btn-sm"
                  data-bs-dismiss="modal"
                >
                  Close
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <UnauthorizedPage />
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import { mapState, mapActions } from "vuex";
import moment from "moment";
import DatePicker from "vuejs-datepicker";
import user from "@/ajax/user";
import UnauthorizedPage from "@/views/Layouts/UnauthorizedPage.vue";
import CustomPagination from "../../components/CustomPagination.vue";
import ReadMore from "vue-read-more";
import Vue from "vue";

Vue.use(ReadMore);

export default {
  components: {
    Multiselect,
    datepicker: DatePicker,
    UnauthorizedPage,
    CustomPagination,
  },
  data() {
    return {
      title: "Add Report Summary",
      current_page: "your_summary",
      months_options: [],
      month_week_options: [],
      master_user_list: [],
      user_list: [],
      disableDateConfig: {
        to: moment("2024-04-01").toDate(),
        from: moment().toDate(),
      },
      popupObj: {
        month_week_options: [],
        selected_month: null,
        selected_month_week_options: null,
        summary: null,
        charLimit: 5000,
        validation: {
          summary: null,
          month: null,
        },
      },
      team_filter_obj: {
        selected_date: new Date(),
        month_options: [],
        selected_month_options: null,
        users: [],
        totalRecords: 0,
        isLoading: true,
        view_options: [
          {
            label: "Month/Week Both",
            value: "both_month_and_week",
          },
          {
            label: "Month Only",
            value: "month_only",
          },
          {
            label: "Week Only",
            value: "week_only",
          },
        ],
        selected_view_options: null,
        serverParams: {
          filters: {},
          sort: [],
          page: 1,
          perpage: 100,
        },
        columns: [
          {
            field: "for_month",
            label: "Month",
            width: "10rem",
            thClass: "custom-th",
            tdClass: "custom-td",
            type: "date",
            dateInputFormat: "yyyy-MM-dd",
            dateOutputFormat: "MMMM yyyy",
          },
          {
            field: "date_range_label",
            label: "Date Range",
            width: "20rem",
            thClass: "custom-th",
            tdClass: "custom-td",
          },
          {
            field: "full_name",
            label: "User",
            width: "22rem",
            thClass: "custom-th",
            tdClass: "custom-td",
          },
          {
            field: "summary",
            label: "Summary",
            width: "30rem",
            thClass: "custom-th",
            tdClass: "custom-td",
          },
        ],
        rows: [],
      },
      your_filter_obj: {
        selected_date: new Date(),
        totalRecords: 0,
        isLoading: true,
        serverParams: {
          filters: {},
          sort: [],
          page: 1,
          perpage: 100,
        },
        columns: [
          {
            field: "for_month",
            label: "Month",
            width: "10rem",
            thClass: "custom-th",
            tdClass: "custom-td",
            type: "date",
            dateInputFormat: "yyyy-MM-dd",
            dateOutputFormat: "MMMM yyyy",
          },
          {
            field: "date_range_label",
            label: "Date Range",
            width: "20rem",
            thClass: "custom-th",
            tdClass: "custom-td",
          },
          {
            field: "summary",
            label: "Summary",
            width: "30rem",
            thClass: "custom-th",
            tdClass: "custom-td",
          },
          {
            field: "action",
            label: "Action",
            width: "12rem",
            thClass: "custom-th",
            tdClass: "custom-td",
          },
        ],
        rows: [],
      },
      permissions: localStorage.getItem("permissions"),
      siteLogReq: {
        site_name: "summary_report",
      },
    };
  },
  computed: {
    ...mapState("userStore", ["currentUser"]),
    summaryWordCount() {
      if (this.popupObj.summary != null) {
        return `${this.popupObj.summary.length}/${this.popupObj.charLimit}`;
      }
      return `0/${this.popupObj.charLimit}`;
    },
    yourStyle() {
      // :style="{'color':current_page=='your_ts' ? '#583D72' : '#79869F','border-bottom': current_page=='your_ts' ? '3px solid #583D72' : ''}">
      if (this.current_page == "your_summary") {
        return {
          color: "#583D72",
          "border-bottom": "3px solid #583D72",
          width: "4rem",
        };
      } else {
        return { color: "#79869F", "border-bottom": "", width: "4rem" };
      }
    },
    teamStyle() {
      // :style="{'color':current_page=='your_ts' ? '#583D72' : '#79869F','border-bottom': current_page=='your_ts' ? '3px solid #583D72' : ''}">
      if (this.current_page == "team_summary") {
        return {
          color: "#583D72",
          "border-bottom": "3px solid #583D72",
          width: "4rem",
        };
      } else {
        return { color: "#79869F", "border-bottom": "", width: "4rem" };
      }
    },
  },
  created() {
    user.createSiteUsage(this.siteLogReq);
  },
  mounted() {
    this.setCurrentPage("Summary Report");
    moment.locale("en");
    this.getYourSummaryReports();
    this.getUsers();
    $("#summaryReportModal").on("hidden.bs.modal", this.hideModalEventHandler);
  },
  methods: {
    ...mapActions("common", ["setCurrentPage"]),
    formatSummary() {
      if (this.popupObj.summary.length > this.popupObj.charLimit) {
        this.popupObj.summary = this.popupObj.summary.slice(
          0,
          this.popupObj.charLimit
        );
      }
    },
    onYourSummaryReportPageChange(params) {
      this.onYourSummaryReportUpdateParams({ page: params.currentPage });
      this.getYourSummaryReports();
    },

    onYourSummaryReportPerPageChange(params) {
      this.your_filter_obj.serverParams.page = 1;
      this.onYourSummaryReportUpdateParams({ perpage: params.currentPerPage });
      this.getYourSummaryReports();
    },
    onYourSummaryReportColumnFilter(params) {
      this.onYourSummaryReportUpdateParams(params);
      this.getYourSummaryReports();
    },
    onYourSummaryReportUpdateParams(newProps) {
      this.your_filter_obj.serverParams = Object.assign(
        {},
        this.your_filter_obj.serverParams,
        newProps
      );
    },
    onYourSummaryReportSortChange(params) {
      this.onYourSummaryReportUpdateParams({
        sort: [
          {
            type: params[0].type,
            field: params[0].field,
          },
        ],
      });
      this.getYourSummaryReports();
    },

    onTeamSummaryReportPageChange(params) {
      this.onTeamSummaryReportUpdateParams({ page: params.currentPage });
      this.getTeamSummaryReports();
    },

    onTeamSummaryReportPerPageChange(params) {
      this.onTeamSummaryReportUpdateParams({ perpage: params.currentPerPage });
      this.team_filter_obj.serverParams.page = 1;
      this.getTeamSummaryReports();
    },
    onTeamSummaryReportColumnFilter(params) {
      this.onTeamSummaryReportUpdateParams(params);
      this.getTeamSummaryReports();
    },
    onTeamSummaryReportUpdateParams(newProps) {
      this.team_filter_obj.serverParams = Object.assign(
        {},
        this.team_filter_obj.serverParams,
        newProps
      );
    },
    onTeamSummaryReportSortChange(params) {
      this.onTeamSummaryReportUpdateParams({
        sort: [
          {
            type: params[0].type,
            field: params[0].field,
          },
        ],
      });
      this.getTeamSummaryReports();
    },

    hideModalEventHandler() {
      this.popupObj.selected_month = new Date();
      this.popupObj.selected_month_week_options = null;
      this.popupObj.summary = null;
      this.popupObj.validation.summary = null;
      this.popupObj.validation.month = null;
      if (Object.keys(this.popupObj).includes("id")) {
        delete this.popupObj.id;
      }
      this.generateYourMonthOptions(this.popupObj.selected_month);
    },
    getUsers() {
      user.getUsers().then((result) => {
        this.master_user_list = [
          { label: "Select all", users_data: result.data.data },
        ];
        this.getFilteredUsers();
      });
    },
    getFilteredUsers() {
      let end_of_month = moment(this.team_filter_obj.selected_date)
        .endOf("month")
        .format("YYYY-MM-DD");
      let end_date = moment(end_of_month);
      let filtered_user_list = this.master_user_list[0].users_data.filter(
        (item) => {
          return (
            item.id != this.currentUser.id &&
            (item.doe == null ||
              (item.doe != null &&
                moment(item.doe, "YYYY-MM-DD").isSameOrAfter(end_date)))
          );
        }
      );
      this.team_filter_obj.users = this.team_filter_obj.users.filter((item) => {
        return (
          item.id != this.currentUser.id &&
          (item.doe == null ||
            item.doe == null ||
            (item.doe != null &&
              moment(item.doe, "YYYY-MM-DD").isSameOrAfter(end_date)))
        );
      });
      this.user_list = structuredClone(this.master_user_list);
      this.user_list[0].users_data = filtered_user_list;
    },
    getYourSummaryReports(val) {
      if (val != null) {
        this.your_filter_obj.selected_date = new Date(val);
      }
      let from_date = moment(this.your_filter_obj.selected_date)
        .startOf("month")
        .format("YYYY-MM-DD");
      let to_date = moment(this.your_filter_obj.selected_date)
        .endOf("month")
        .format("YYYY-MM-DD");
      const reqObj = {
        from_date: from_date,
        for_month: from_date,
        to_date: to_date,
      };
      let serverParams = Object.assign(this.your_filter_obj.serverParams, {
        filters: reqObj,
      });
      this.your_filter_obj.isLoading = true;
      this.your_filter_obj.rows.length = 0;
      this.your_filter_obj.totalRecords = 0;
      user
        .get_your_summary_reports(serverParams)
        .then((res) => {
          this.your_filter_obj.rows = res.data.data.data;
          this.your_filter_obj.totalRecords = res.data.data.total;
          this.your_filter_obj.isLoading = false;
        })
        .catch((err) => (this.your_filter_obj.isLoading = false));
    },
    getTeamSummaryReports() {
      let from_date = moment(this.team_filter_obj.selected_date)
        .startOf("month")
        .format("YYYY-MM-DD");
      let to_date = moment(this.team_filter_obj.selected_date)
        .endOf("month")
        .format("YYYY-MM-DD");

      const reqObj = {
        from_date: from_date,
        to_date: to_date,
        for_month: from_date,
        users: this.team_filter_obj.users,
        view_type: this.team_filter_obj.selected_view_options.value,
      };
      if (this.team_filter_obj.selected_month_options != null) {
        reqObj.from_date =
          this.team_filter_obj.selected_month_options.from_date;
        reqObj.to_date = this.team_filter_obj.selected_month_options.to_date;
      }
      reqObj.users = reqObj.users.map((e) => e.id);
      let serverParams = Object.assign(this.team_filter_obj.serverParams, {
        filters: reqObj,
      });
      this.team_filter_obj.isLoading = true;
      this.team_filter_obj.rows.length = 0;
      this.team_filter_obj.totalRecords = 0;
      user
        .get_team_summary_reports(serverParams)
        .then((res) => {
          this.team_filter_obj.rows = res.data.data.data;
          this.team_filter_obj.totalRecords = res.data.data.total;
          this.team_filter_obj.isLoading = false;
        })
        .catch((err) => {
          this.team_filter_obj.isLoading = false;
        });
    },
    validator() {
      let isValid = true;
      if (this.popupObj.selected_month == null) {
        this.popupObj.validation.month = "Please select the month";
        isValid = false;
        return isValid;
      } else {
        this.popupObj.validation.month = null;
      }

      if (this.popupObj.summary == null || this.popupObj.summary == "") {
        this.popupObj.validation.summary = "Please enter the summary";
        isValid = false;
        return isValid;
      } else {
        this.popupObj.validation.summary = null;
      }
      return isValid;
    },
    submitBtnHandler() {
      if (this.validator()) {
        let firstDate = moment(this.popupObj.selected_month)
          .startOf("month")
          .format("YYYY-MM-DD");
        let lastDate = moment(this.popupObj.selected_month)
          .endOf("month")
          .format("YYYY-MM-DD");
        const reqObj = {
          from_date: firstDate,
          to_date: lastDate,
          for_month: firstDate,
          summary: this.popupObj.summary.trim(),
        };
        if (this.popupObj.selected_month_week_options != null) {
          reqObj.from_date =
            this.popupObj.selected_month_week_options.from_date;
          reqObj.to_date = this.popupObj.selected_month_week_options.to_date;
        }
        if (Object.keys(this.popupObj).includes("id")) {
          user
            .update_summary_report(this.popupObj.id, reqObj)
            .then((res) => {
              if (res.status == 200) {
                Swal.fire({
                  title: "Summary Report Updated Successfully",
                  icon: "success",
                });
                this.getYourSummaryReports();
              } else {
                Swal.fire({
                  title: "Failed To Update Summary Report",
                  icon: "error",
                });
              }
            })
            .catch((err) => {
              console.error(err);
              let error_msg = err.response
                ? err.response.data.detail
                : "Something Went Wrong";
              Swal.fire({
                title: error_msg,
                icon: "error",
              });
            });
        } else {
          user
            .add_summary_report(reqObj)
            .then((res) => {
              if (res.status == 200) {
                Swal.fire({
                  title: "Summary Report Added Successfully",
                  icon: "success",
                });
                this.getYourSummaryReports();
              } else {
                Swal.fire({
                  title: "Failed To Add Summary Report",
                  icon: "error",
                });
              }
            })
            .catch((err) => {
              console.error(err);
              let error_msg = err.response
                ? err.response.data.detail
                : "Something Went Wrong";
              Swal.fire({
                title: error_msg,
                icon: "error",
              });
            });
        }

        $("#summaryReportModal").modal("hide");
      }
    },
    generateDropdownOptions() {
      const baseDate = new Date();

      const createOption = (date) => {
        const label = moment(date).format("MMMM YYYY");
        const fromDate = moment(date).startOf("month").format("YYYY-MM-DD");
        const toDate = moment(date).endOf("month").format("YYYY-MM-DD");

        return {
          label: label,
          from_date: fromDate,
          to_date: toDate,
        };
      };

      const currentMonth = createOption(baseDate);
      const lastMonth = createOption(moment(baseDate).subtract(1, "months"));
      const twoMonthsAgo = createOption(moment(baseDate).subtract(2, "months"));

      this.popupObj.month_options = [currentMonth, lastMonth, twoMonthsAgo];
      this.popupObj.selected_month = baseDate;
      this.popupObj.month_week_options = this.getWeeksOfMonth(
        baseDate.getFullYear(),
        baseDate.getMonth() + 1
      );
    },
    getWeeksOfMonth(year, month) {
      const baseDate = moment().toDate();
      baseDate.setFullYear(year);
      baseDate.setMonth(month - 1);
      baseDate.setDate(1);
      const firstDateOfMonth = moment(baseDate, "YYYY-MM-DD").startOf("month");
      const firstDateOfWeek = firstDateOfMonth.clone().startOf("isoWeek");
      const noOfWeeks = 5;
      const dates = [];
      let monday = firstDateOfWeek.clone();
      for (let i = 0; i < noOfWeeks; i++) {
        let sunday = monday.clone().endOf("isoWeek");
        dates.push({
          label: `${monday.format("Do MMM")} to ${sunday.format(
            "Do MMM"
          )} (Week ${i + 1}) `,
          from_date: monday.format("YYYY-MM-DD"),
          to_date: sunday.format("YYYY-MM-DD"),
        });
        monday = monday.clone().add(1, "week");
      }
      return dates;
    },
    reportSummaryModalHandler(e, summaryReport) {
      this.popupObj.selected_month = moment().toDate();
      if (summaryReport != null) {
        this.popupObj.id = summaryReport.id;
        this.popupObj.summary = summaryReport.summary;
        this.popupObj.selected_month = summaryReport.for_month;
        this.generateYourMonthOptions(
          moment(this.popupObj.selected_month).toDate()
        );
        this.popupObj.selected_month_week_options =
          this.popupObj.month_week_options.find((e) => {
            return (
              e.from_date == summaryReport.from_date &&
              e.to_date == summaryReport.to_date
            );
          });
        this.title = "Update Report Summary";
      } else {
        this.generateYourMonthOptions(this.popupObj.selected_month);
      }
      $("#summaryReportModal").modal("show");
    },
    custom_label(name) {
      if (name.last_name != null && name.middle_name != null) {
        return `${name.first_name} ${name.middle_name} ${name.last_name}`;
      } else if (name.last_name == null) {
        return `${name.first_name} ${name.middle_name}`;
      } else if (name.middle_name == null) {
        return `${name.first_name} ${name.last_name}`;
      }
    },
    generateTeamMonthOptions(val) {
      if (val != null) {
        this.team_filter_obj.selected_date = val;
      }
      if (this.team_filter_obj.selected_month_options != null) {
        this.team_filter_obj.selected_month_options = null;
      }
      this.team_filter_obj.month_options = this.getWeeksOfMonth(
        val.getFullYear(),
        val.getMonth() + 1
      );
      this.getFilteredUsers();
    },
    generateYourMonthOptions(val) {
      // console.log(val);
      if (this.popupObj.selected_month_week_options != null) {
        this.popupObj.selected_month_week_options = null;
      }
      this.popupObj.month_week_options = this.getWeeksOfMonth(
        val.getFullYear(),
        val.getMonth() + 1
      );
    },
    pageChanger(page) {
      if (page != this.current_page) {
        this.current_page = page;
        if (page == "your_summary") {
          this.your_filter_obj.selected_date = new Date();
          this.generateYourMonthOptions(this.your_filter_obj.selected_date);
          this.getYourSummaryReports();
        }
        if (page == "team_summary") {
          this.team_filter_obj.selected_date = new Date();
          this.team_filter_obj.selected_view_options =
            this.team_filter_obj.view_options[0];
          this.generateTeamMonthOptions(this.team_filter_obj.selected_date);
          this.getTeamSummaryReports();
        }
      }
    },
    deleteSummaryReport(id) {
      Swal.fire({
        title: "Do you want to delete the summary report ?",
        icon: "question",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        showCancelButton: true,
        showCloseButton: true,
        confirmButtonColor: "#32a852",
        cancelButtonColor: "#f00",
      }).then((res) => {
        if (res.value) {
          user
            .delete_summary_report(id)
            .then((res) => {
              if (res.status == 200) {
                Swal.fire({
                  title: "Summary Report Deleted Successfully",
                  icon: "success",
                });
                this.getYourSummaryReports();
              } else {
                Swal.fire({
                  title: "Failed To Delete Summary Report",
                  icon: "error",
                });
              }
            })
            .catch((err) => {
              console.error(err);
              Swal.fire({
                title: "Failed To Delete Summary Report",
                icon: "error",
              });
            });
        }
      });
    },
  },
};
</script>

<style scoped>
.mandatory:after {
  content: "*";
  padding: 0px 2px;
  color: red;
}
::v-deep .vdp-datepicker__calendar-button .input-group-text {
  height: 100%;
  background-color: whitesmoke;
  border-top-right-radius: 0;
  border-right: 0px;
  border-bottom-right-radius: 0;
}
::v-deep .vdp-datepicker__clear-button .input-group-text {
  height: 100%;
  background-color: white;
  border-top-left-radius: 0;
  border-left: 0px;
  border-bottom-left-radius: 0;
  border-color: #f0f0f0;
}
.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 0.5s ease;
}
.slide-fade-enter,
.slide-fade-leave-to {
  opacity: 0;
  transform: translateY(10px);
}
</style>
