var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-4 mx-3"},[_c('div',{staticClass:"d-flex flex-md-row flex-column justify-content-between align-items-md-center pb-3"},[_c('div',[_c('h3',[_vm._v("Users")]),_c('ol',{staticClass:"breadcrumb m-0"},[_c('li',{staticClass:"breadcrumb-item"},[_c('router-link',{attrs:{"to":{ name: 'DashboardContent' },"tag":"a"}},[_vm._v(" Dashboard ")])],1),_c('li',{staticClass:"breadcrumb-item active"},[_vm._v("Users")])])]),_c('div',{staticClass:"d-flex gap-2 mt-md-0",class:_vm.currentUser.user_role == 1 ? 'mt-3' : ''},[(
          _vm.permissions.includes('users.write') &&
          _vm.permissions.includes('users.add')
        )?_c('router-link',{staticClass:"btn btn-sm btn-primary",class:[_vm.currentPage == 'AddUser' ? 'active' : '', 'nav-item'],attrs:{"to":'/add-user'}},[_vm._v(" Add User ")]):_vm._e(),(_vm.permissions.includes('user_list.export'))?_c('button',{staticClass:"btn btn-outline-primary btn-sm",on:{"click":_vm.exportUserList}},[_vm._v(" Export ")]):_vm._e()],1)]),_c('redmineFilter',{ref:"redmineFilter",attrs:{"page":"users","serverParams":_vm.serverParams},on:{"redmine-filter-data":_vm.redmineFilterHandler}}),(_vm.permissions.includes('users.read'))?_c('div',{staticClass:"card shadow-sm border-0 mb-4 mt-4"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"d-flex align-items-center justify-content-md-between justify-content-center flex-md-nowrap flex-wrap gap-md-0 gap-2 px-2 pb-3"}),(_vm.permissions.includes('users.read'))?_c('div',{staticClass:"p-2"},[_c('div',{staticClass:"table-responsive"},[_c('vue-good-table',{attrs:{"mode":"remote","totalRows":_vm.totalRecords,"isLoading":_vm.isLoading,"dropdownAllowAll":false,"pagination-options":{
              enabled: true,
              perPageDropdown: _vm.perpage_list,
            },"rows":_vm.rows,"columns":_vm.columns,"styleClass":"vgt-table"},on:{"on-page-change":_vm.onPageChange,"on-sort-change":_vm.onSortChange,"on-column-filter":_vm.onColumnFilter,"on-per-page-change":_vm.onPerPageChange,"update:isLoading":function($event){_vm.isLoading=$event},"update:is-loading":function($event){_vm.isLoading=$event}},scopedSlots:_vm._u([{key:"column-filter",fn:function({ column, updateFilters }){return [(column.field == 'doj')?_c('span',[_c('datepicker',{attrs:{"clear-button":true,"format":_vm.formateDate,"placeholder":"yyyy-mm-dd"},on:{"input":(e) =>
                      updateFilters(column, e.toISOString().substring(0, 10))}})],1):_vm._e()]}},{key:"table-row",fn:function(props){return [(props.column.label == 'Action')?_c('span',[_c('tr',[(_vm.columns != _vm.inactiveColumns)?_c('div',{staticClass:"d-flex justify-content-center align-items-center p-2 gap-3"},[_c('router-link',{staticClass:"btn-view",attrs:{"to":'/prof/' + props.row.id,"tag":"button"}},[_c('i',{staticClass:"fa fa-eye fs-5"})]),(
                        _vm.permissions.includes('users.delete') &&
                        props.row.id != _vm.currentUser.id
                      )?_c('button',{staticClass:"btn-trash",on:{"click":function($event){return _vm.delete_user(props.row.id, props.row.doe)}}},[_c('i',{staticClass:"fa fa-user-times fs-5"})]):_vm._e()],1):_c('div',{staticClass:"d-flex justify-content-center align-items-center gap-3 p-2"},[(_vm.permissions.includes('users.read'))?_c('router-link',{staticClass:"btn btn-view",attrs:{"to":'/prof/' + props.row.id,"tag":"button"}},[_c('i',{staticClass:"fa fa-eye fs-5"})]):_vm._e(),(_vm.permissions.includes('users.enable'))?_c('button',{staticClass:"btn btn-add",on:{"click":function($event){return _vm.make_user_active(props.row.id)}}},[_c('i',{staticClass:"fa fa-user fs-5"})]):_vm._e()],1)])]):(props.column.field == 'manager_of_projects')?_c('span',[_c('readMore',{staticClass:"p-1",attrs:{"more-str":"Show more","text":props.row.manager_of_projects,"less-str":"Show less","max-chars":50}})],1):_vm._e()]}},{key:"pagination-bottom",fn:function(props){return [_c('CustomPagination',{staticClass:"p-2",attrs:{"pageTabs":4,"serverParams":_vm.serverParams,"total":props.total,"pageChanged":props.pageChanged,"perPageChanged":props.perPageChanged}})]}}],null,false,723531412)})],1)]):_vm._e()])]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }