<template>
  <div class="my-4 mx-3">
    <div v-if="currentUser.user_role == 1">
      <div
        class="d-flex flex-md-row flex-column justify-content-between align-items-md-center pb-3 mt-3 gap-md-0 gap-3"
      >
        <div>
          <h3 class="text-dark">Invoice's</h3>
          <ol class="breadcrumb m-0">
            <li class="breadcrumb-item">
              <router-link :to="{ name: 'DashboardContent' }" tag="a">
                Dashboard
              </router-link>
            </li>
            <li class="breadcrumb-item active">Invoice's</li>
          </ol>
        </div>
        <router-link
          tag="button"
          :to="{ name: 'AddInvoice' }"
          class="btn btn-primary btn-sm ms-md-auto me-md-0 me-auto"
        >
          Add Invoice
        </router-link>
      </div>
      <redmineFilter
        ref="redmineFilter"
        page="invoices"
        @redmine-filter-data="redmineFilterHandler"
        @reset-server-params="onResetServerParams"
        :serverParams="serverParams"
      ></redmineFilter>
      <div class="card shadow-sm border-0 my-4">
        <div class="card-body">
          <div class="d-flex justify-content-end pb-1"></div>
          <div class="p-2">
            <vue-good-table
              styleClass="vgt-table no-border"
              mode="remote"
              @on-page-change="onPageChange"
              @on-per-page-change="onPerPageChange"
              @on-sort-change="onSortChange"
              @on-column-filter="onColumnFilter"
              :totalRows="total_records"
              :rows="invoice_rows"
              :columns="invoice_columns"
              :pagination-options="{
                enabled: true,
                perPageDropdown: perpage_list,
              }"
            >
              <template
                slot="column-filter"
                slot-scope="{ column, updateFilters }"
              >
                <span v-if="column.field == 'invoice_date'">
                  <datepicker
                    :typeable="true"
                    placeholder="yyyy-mm-dd"
                    @input="
                      (e) =>
                        updateFilters(
                          column,
                          e
                            ? e.toISOString().substring(0, 10)
                            : updateFilters(column, '')
                        )
                    "
                  />
                </span>
                <span v-else-if="column.field == 'price'">
                  <input
                    type="number"
                    min="0"
                    class="form-control rounded-1"
                    style="height: 25.47px; font-size: 14px"
                    placeholder="price"
                    @input="(e) => updateFilters(column, e.target.value)"
                  />
                </span>
                <span v-else-if="column.field == 'total'">
                  <input
                    type="number"
                    min="0"
                    class="form-control rounded-1"
                    style="height: 25.47px; font-size: 14px"
                    placeholder="total"
                    @input="(e) => updateFilters(column, e.target.value)"
                  />
                </span>
                <span v-else-if="column.field == 'GST'">
                  <input
                    type="number"
                    min="0"
                    class="form-control rounded-1"
                    style="height: 25.47px; font-size: 14px"
                    placeholder="GST"
                    @input="(e) => updateFilters(column, e.target.value)"
                  />
                </span>
                <span v-else-if="column.field == 'total_assets'">
                  <input
                    type="number"
                    min="0"
                    class="form-control rounded-1"
                    style="height: 25.47px; font-size: 14px"
                    placeholder="No of assets"
                    @input="(e) => updateFilters(column, e.target.value)"
                  />
                </span>
              </template>
              <template slot="table-row" slot-scope="props">
                <span
                  class="d-flex justify-content-center align-items-center p-2 gap-3"
                  v-if="props.column.field == 'action'"
                >
                  <router-link
                    tag="button"
                    :to="'/update-invoice/' + props.row.id"
                    class="btn btn-pencil"
                  >
                    <i class="fa fa-pencil fs-5"></i>
                  </router-link>
                  <button
                    class="btn btn-trash"
                    @click="deleteInvoice(props.row.id)"
                  >
                    <i class="fa fa-trash fs-5"></i>
                  </button>
                </span>
              </template>
              <template slot="pagination-bottom" slot-scope="props">
                <CustomPagination
                  class="p-2"
                  :pageTabs="4"
                  :serverParams="serverParams"
                  :total="props.total"
                  :pageChanged="props.pageChanged"
                  :perPageChanged="props.perPageChanged"
                ></CustomPagination>
              </template>
            </vue-good-table>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <h2 class="text-muted">Unauthorized Access</h2>
    </div>
  </div>
</template>

<script>
import user from "@/ajax/user";
import { mapState, mapActions } from "vuex";
import CustomPagination from "../../components/CustomPagination.vue";
import Datepicker from "vuejs-datepicker";
import myMixin from "@/mixins/Permission";
import redmineFilter from "../../components/redmine_filter.vue";
export default {
  mixins: [myMixin],
  components: {
    CustomPagination,
    Datepicker,
    redmineFilter,
  },
  computed: {
    ...mapState("userStore", ["currentUser"]),
  },
  mounted() {
    this.setCurrentPage("Assets");
    this.getUserPermissions();
    // this.getInvoices();
  },
  created() {
    user.createSiteUsage(this.siteLogReq);
  },
  data() {
    return {
      siteLogReq: {
        site_name: "invoices",
      },
      invoice_rows: [],
      invoice_columns: [
        {
          label: "Invoice Number",
          field: "invoice_no",
          filterable: true,
          thClass: "custom-th",
          tdClass: "custom-td",
          filterOptions: {
            enabled: false,
            trigger: "enter",
          },
        },
        {
          label: "Invoice Date",
          field: "invoice_date",
          filterable: true,
          thClass: "custom-th",
          tdClass: "custom-td",
          filterOptions: {
            enabled: false,
            trigger: "enter",
          },
        },
        {
          label: "Price",
          field: "price",
          filterable: true,
          thClass: "custom-th",
          tdClass: "custom-td",
          filterOptions: {
            enabled: false,
            trigger: "enter",
          },
        },
        {
          label: "Total",
          field: "total",
          filterable: true,
          thClass: "custom-th",
          tdClass: "custom-td",
          filterOptions: {
            enabled: false,
            trigger: "enter",
          },
        },
        {
          label: "GST",
          field: "GST",
          filterable: true,
          thClass: "custom-th",
          tdClass: "custom-td",
          filterOptions: {
            enabled: false,
            trigger: "enter",
          },
        },
        {
          label: "Paid",
          field: "paid",
          filterable: true,
          thClass: "custom-th",
          tdClass: "custom-td",
          filterOptions: {
            enabled: false,
            filterDropdownItems: ["true", "false"],
            trigger: "enter",
          },
        },
        {
          label: "No. of Assets",
          field: "total_assets",
          filterable: true,
          thClass: "custom-th",
          tdClass: "custom-td",
          filterOptions: {
            enabled: false,
            trigger: "enter",
          },
        },
        {
          label: "Action",
          field: "action",
          sortable: false,
          thClass: "custom-th",
          tdClass: "custom-td",
        },
      ],
      serverParams: {
        filters: {},
        page: 1,
        perpage: 100,
        setCurrentPage: 1,
        sort: [
          {
            field: "",
            type: "asc",
          },
        ],
      },

      total_records: null,
      perpage_list: [100, 200, 300, 400, 500],
    };
  },
  methods: {
    ...mapActions("common", ["setCurrentPage"]),
    deleteInvoice(id) {
      Swal.fire({
        title: "Do you want to delete invoice ?",
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        icon: "warning",
      }).then((res) => {
        if (res.isConfirmed) {
          user
            .deleteInvoice(id)
            .then((res) => {
              Swal.fire({
                title: res.data.message,
                icon: "success",
              });
              this.getInvoices();
            })
            .catch((err) => {
              Swal.fire({
                title: err.response.data.detail,
                icon: "error",
              });
            });
        }
      });
    },
    redmineFilterHandler() {
      this.getInvoices();
    },
    onResetServerParams(serverParams) {
      this.serverParams = Object.assign({}, serverParams);
      this.$nextTick(() => {
        this.$refs.redmineFilter.applyFilter();
      });
    },
    getInvoices() {
      user.getInvoices(this.serverParams).then((res) => {
        this.invoice_rows = res.data.data.data;
        this.total_records = res.data.data.total;
      });
    },
    onColumnFilter(params) {
      var newObj = { filters: Object.assign({}) };
      Object.keys(params.columnFilters).forEach(function (key) {
        if (params.columnFilters[key] !== "")
          newObj.filters[key] = params.columnFilters[key];
      });
      this.updateParams(newObj);
      this.serverParams.page = 1;
      this.getInvoices();
    },
    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },
    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      this.getInvoices();
    },

    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage });
      this.serverParams.page = 1;
      this.getInvoices();
    },
    onSortChange(params) {
      this.updateParams({
        sort: [
          {
            type: params[0].type,
            field: params[0].field,
          },
        ],
      });
      this.getInvoices();
    },
  },
};
</script>
