<template>
  <div class="my-4 mx-3">
    <div v-if="currentUser.user_role == 1">
      <!-- Title and button -->
      <div class="pb-3 mt-3">
        <div>
          <h3 class="text-dark">Asset's</h3>
          <ol class="breadcrumb m-0">
            <li class="breadcrumb-item">
              <router-link :to="{ name: 'DashboardContent' }" tag="a">
                Dashboard
              </router-link>
            </li>
            <li class="breadcrumb-item active">Asset's</li>
          </ol>
        </div>
      </div>
      <redmine-filter
        ref="redmineFilter"
        page="new_assets"
        :serverParams="serverParams"
        :set_filters="assets_params"
        @redmine-filter-data="onRedmineFilterHandler"
        @reset-server-params="onResetServerParams"
      ></redmine-filter>
      <div class="card my-4 border-0 shadow-sm">
        <div class="card-body">
          <div class="p-2">
            <h6 class="text-end m-0 mb-3" v-if="total_price != null">
              Total Value : &#8377;{{ total_price.toLocaleString() }}
            </h6>
            <vue-good-table
              styleClass="vgt-table no-border"
              mode="remote"
              @on-page-change="onPageChange"
              @on-per-page-change="onPerPageChange"
              @on-sort-change="onSortChange"
              @on-column-filter="onColumnFilter"
              @on-cell-click="onCellClick"
              :totalRows="total_records"
              :isLoading.sync="isLoading"
              :rows="assets_rows"
              :columns="assets_columns"
              :pagination-options="{
                enabled: true,
                perPageDropdown: perpage_list,
              }"
            >
              <template
                slot="column-filter"
                slot-scope="{ column, updateFilters }"
              >
                <span v-if="column.field == 'price'">
                  <input
                    type="number"
                    min="0"
                    class="form-control rounded-1"
                    style="height: 25.47px; font-size: 14px"
                    placeholder="Price"
                    @input="(e) => updateFilters(column, e.target.value)"
                  />
                </span>
              </template>
              <template slot="table-row" slot-scope="props">
                <template v-if="props.column.field == 'action'">
                  <div
                    class="d-flex justify-content-center align-items-center gap-3 p-2"
                  >
                    <button
                      class="btn-pencil"
                      @click="assetModalHandler(props.row.id)"
                    >
                      <i class="fa fa-pencil fs-5"></i>
                    </button>
                    <button
                      class="btn-trash"
                      @click="deleteAsset(props.row.id)"
                    >
                      <i class="fa fa-trash fs-5"></i>
                    </button>
                  </div>
                </template>
                <template v-else-if="props.column.field == 'serial_no'">
                  <a
                    href="#"
                    @click.prevent
                    class="cursor-pointer link link-underline link-underline-opacity-0 link-underline-opacity-100-hover"
                    >{{ props.row.serial_no }}</a
                  >
                </template>
                <template v-else-if="props.column.field == 'price'">
                  &#8377;{{ props.row.price.toLocaleString() }}
                </template>
              </template>
              <template slot="pagination-bottom" slot-scope="props">
                <CustomPagination
                  class="p-2"
                  :pageTabs="4"
                  :serverParams="serverParams"
                  :total="props.total"
                  :pageChanged="props.pageChanged"
                  :perPageChanged="props.perPageChanged"
                ></CustomPagination>
              </template>
            </vue-good-table>
            <!-- styleClass="vgt-table condensed" -->
          </div>
        </div>
        <!-- update -->
        <div
          class="modal fade assetModal"
          id="assetModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="vendorModal"
          aria-hidden="true"
        >
          <div
            class="modal-dialog modal-dialog-centered modal-xl"
            role="document"
          >
            <div class="modal-content">
              <div class="modal-header">
                <h3 class="m-0" id="vendorModal">Update Asset</h3>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body pb-4">
                <div
                  class="d-flex justify-content-center align-items-center p-5"
                  v-if="assets_obj == null"
                >
                  <div
                    class="spinner-border text-primary"
                    style="height: 4rem; width: 4rem"
                  ></div>
                </div>
                <div v-else>
                  <form @submit.prevent="submitAsset">
                    <div
                      class=""
                      v-for="(item, index) in assets_obj"
                      :key="index"
                    >
                      <div
                        class="mb-4 d-flex justify-content-md-between align-items-center flex-md-row flex-column justify-content-center"
                      >
                        <h5>Invoice No: {{ assets_obj[0].invoice_no }}</h5>
                        <h5>Category Name: {{ category_name }}</h5>
                      </div>
                      <div
                        class="row mb-3 g-0"
                        v-for="(field, index) in item.fields"
                        :key="index"
                      >
                        <div class="col-md-2 col-12 mb-md-0 mb-2">
                          <h6
                            class="m-0 text-dark pt-1"
                            v-text="field.field_name"
                            :class="field.is_mandatory ? 'mandatory' : ''"
                            v-if="field.is_active"
                          ></h6>
                        </div>
                        <div class="col-md-10 col-12">
                          <input
                            :readonly="field.field_name == 'Prefix'"
                            class="form-control"
                            type="text"
                            :required="field.is_mandatory"
                            v-model="field.default_value"
                            :placeholder="'Enter the ' + field.field_name"
                            v-if="field.field_type == 'str' && field.is_active"
                            :maxlength="field.max_chars"
                          />
                          <input
                            class="form-control"
                            type="number"
                            :required="field.is_mandatory"
                            v-model="field.default_value"
                            :placeholder="'Enter the ' + field.field_name"
                            v-if="field.field_type == 'int' && field.is_active"
                            :max="field.max_limit"
                            :min="field.min_limit"
                          />
                          <!-- For float -->
                          <input
                            class="form-control"
                            type="number"
                            :required="field.is_mandatory"
                            v-model="field.default_value"
                            :placeholder="'Enter the ' + field.field_name"
                            v-if="
                              field.field_type == 'float' && field.is_active
                            "
                            step="0.5"
                            :max="field.max_limit"
                            :min="field.min_limit"
                          />
                          <!-- For bool -->
                          <input
                            type="checkbox"
                            v-model="field.default_value"
                            class="mt-2 ml-1"
                            :required="field.is_mandatory"
                            style="scale: 1.3"
                            v-if="field.field_type == 'bool' && field.is_active"
                          />
                          <!-- For single select -->
                          <multiselect
                            :options="field.options"
                            v-if="
                              field.field_type == 'singleselect dropdown' &&
                              field.is_active
                            "
                            :placeholder="'Enter the ' + field.field_name"
                            v-model="field.default_value"
                          ></multiselect>
                          <!-- For single multiselect -->
                          <multiselect
                            :options="field.options"
                            v-if="
                              field.field_type == 'multiselect dropdown' &&
                              field.is_active
                            "
                            :placeholder="'Enter the ' + field.field_name"
                            :close-on-select="false"
                            :multiple="true"
                            v-model="field.default_value"
                          ></multiselect>
                          <!-- For single date -->
                          <flat-pickr
                            :config="field.config"
                            v-model="field.default_value"
                            modelValue=""
                            class="form-control bg-white"
                            v-if="
                              field.field_type == 'single date' &&
                              field.is_active
                            "
                            :placeholder="'Enter the ' + field.field_name"
                            @on-change="dateHandler($event, field)"
                          ></flat-pickr>
                          <!-- For range date -->
                          <flat-pickr
                            :config="field.config"
                            v-model="field.default_value"
                            modelValue=""
                            class="form-control bg-white"
                            v-if="
                              field.field_type == 'range date' &&
                              field.is_active
                            "
                            :placeholder="'Enter the ' + field.field_name"
                            @on-change="dateHandler($event, field)"
                          ></flat-pickr>
                        </div>
                      </div>
                    </div>
                    <div class="d-flex pull-right">
                      <button class="btn btn-primary me-2" type="submit">
                        Update
                      </button>
                      <button
                        class="btn btn-outline-secondary"
                        type="button"
                        data-bs-dismiss="modal"
                      >
                        Cancel
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <h2 class="text-muted">Unauthorized Access</h2>
    </div>
  </div>
</template>

<script>
import user from "@/ajax/user";
import moment from "moment";
import { mapState, mapActions } from "vuex";
import CustomPagination from "../../components/CustomPagination.vue";
import myMixin from "@/mixins/Permission";
import redmineFilter from "../../components/redmine_filter.vue";

export default {
  mixins: [myMixin],
  components: {
    CustomPagination,
    redmineFilter,
  },
  computed: {
    ...mapState("userStore", ["currentUser"]),
  },
  mounted() {
    this.setCurrentPage("Assets");
    $("#assetModal").on("hidden.bs.modal", this.resetModal);
    this.getUserPermissions();
    this.getAssetCategory();
    this.assetsListParamsHandler();
    localStorage.setItem("serial-no-list", JSON.stringify([]));
  },
  created() {
    user.createSiteUsage(this.siteLogReq);
  },
  destroyed() {
    localStorage.removeItem("serial-no-list");
  },
  data() {
    return {
      siteLogReq: {
        site_name: "assets_list",
      },
      isLoading: false,
      assets_rows: [],
      assets_obj: null,
      assets_columns: [
        {
          label: "Asset Category",
          field: "category_name",
          filterable: true,
          thClass: "custom-th",
          tdClass: "custom-td",
          // filterOptions: {
          //   enabled: true,
          //   trigger: "enter",
          // },
        },
        {
          label: "Asset Name",
          field: "name",
          filterable: true,
          thClass: "custom-th",
          tdClass: "custom-td",
          // filterOptions: {
          //   enabled: true,
          //   trigger: "enter",
          // },
        },
        {
          label: "Prefix",
          field: "prefix",
          filterable: true,
          thClass: "custom-th",
          tdClass: "custom-td",
          // filterOptions: {
          //   enabled: true,
          //   trigger: "enter",
          // },
        },
        {
          label: "Invoice Number",
          field: "invoice_no",
          filterable: true,
          thClass: "custom-th",
          tdClass: "custom-td",
          // filterOptions: {
          //   enabled: true,
          //   trigger: "enter",
          // },
        },
        {
          label: "Allocated To",
          field: "assigned_user",
          filterable: true,
          thClass: "custom-th",
          tdClass: "custom-td",
          // filterOptions: {
          //   enabled: true,
          //   trigger: "enter",
          // },
        },
        {
          label: "Allocated By",
          field: "allocated_by",
          filterable: true,
          thClass: "custom-th",
          tdClass: "custom-td",
          // filterOptions: {
          //   enabled: true,
          //   trigger: "enter",
          // },
        },
        {
          label: "Allocated At",
          field: "allocated_at",
          filterable: true,
          thClass: "custom-th",
          tdClass: "custom-td",
          // filterOptions: {
          //   enabled: true,
          //   trigger: "enter",
          // },
        },
        {
          label: "Model",
          field: "model",
          filterable: true,
          thClass: "custom-th",
          tdClass: "custom-td",
          // filterOptions: {
          //   enabled: true,
          //   trigger: "enter",
          // },
        },
        {
          label: "Serial No",
          field: "serial_no",
          filterable: true,
          thClass: "custom-th",
          tdClass: "custom-td",
          // filterOptions: {
          //   enabled: true,
          //   trigger: "enter",
          // },
        },
        {
          label: "Price",
          field: "price",
          filterable: true,
          thClass: "custom-th",
          tdClass: "custom-td",
          // filterOptions: {
          //   enabled: true,
          //   trigger: "enter",
          // },
        },
        {
          label: "Status",
          field: "status",
          filterable: true,
          thClass: "custom-th",
          tdClass: "custom-td",
          // filterOptions: {
          //   enabled: true,
          //   trigger: "enter",
          // },
        },
        {
          label: "Action",
          field: "action",
          thClass: "custom-th",
          tdClass: "custom-td text-nowrap",
          sortable: false,
        },
      ],
      serverParams: {
        filters: {},
        page: 1,
        perpage: 100,
        setCurrentPage: 1,
        sort: [
          {
            field: "",
            type: "asc",
          },
        ],
      },
      category: [],
      total_records: null,
      category_name: null,
      perpage_list: [100, 200, 300, 400, 500],
      total_price: null,
      assets_params: null,
    };
  },
  methods: {
    ...mapActions("common", ["setCurrentPage"]),
    assetsListParamsHandler() {
      if (this.currentUser.user_role == 1) {
        let assetsParams = JSON.parse(localStorage.getItem("assets-list"));
        if (assetsParams) {
          this.assets_params = assetsParams.shift();
          localStorage.setItem("assets-list", JSON.stringify(assetsParams));
          console.log(this.assetsParams);
        }
      }
    },
    onCellClick(params) {
      if (params.column.field == "serial_no") {
        const redirect_link = this.$router.resolve({ name: "AssetsHistory" });
        window.open(redirect_link.href, "_blank");
        let list = JSON.parse(localStorage.getItem("serial-no-list"));
        list.push({ asset_id: params.row.id });
        localStorage.setItem("serial-no-list", JSON.stringify(list));
      }
    },
    onResetServerParams(serverParams) {
      this.serverParams = Object.assign({}, serverParams);
      this.$nextTick(() => {
        this.$refs.redmineFilter.applyFilter();
      });
    },
    onRedmineFilterHandler(data, total, total_price) {
      this.assets_rows = data;
      this.total_records = total;
      this.total_price = total_price;
    },
    dateHandler(event, item) {
      item.default_value = moment(event[0]).format("YYYY-MM-DD");
    },
    submitFormatter(assets) {
      let slice_amount = 11;
      assets.forEach((item) => {
        item.prefix = item.fields[0].default_value;
        item.name = item.fields[1].default_value;
        item.model = item.fields[2].default_value;
        item.serial_no = item.fields[3].default_value;
        item.status = item.fields[4].default_value;
        item.warranty = item.fields[5].default_value;
        item.is_insured = item.fields[6].default_value;
        item.insurance_date = item.fields[7].default_value;
        item.purchase_date = item.fields[8].default_value;
        item.description = item.fields[9].default_value;
        item.price = Number(item.fields[10].default_value);
        item.asset_cat_id = item.asset_cat_id;
        item.fields = item.fields.slice(slice_amount);
        delete item["invoice_no"];
      });
      // console.log("Assets ",assets);
    },
    submitAsset() {
      this.submitFormatter(this.assets_obj);
      user
        .updateAssets(this.assets_obj[0])
        .then((res) => {
          Swal.fire({
            title: res.data.message,
            icon: "success",
          });
          this.getAssets();
        })
        .catch((err) => {
          Swal.fire({
            title: err.response.data.detail,
            icon: "error",
          });
        });
      $("#assetModal").modal("hide");
    },
    resetModal() {
      this.assets_obj = null;
    },
    assetModalHandler(id) {
      $("#assetModal").modal("show");
      this.getAssetsById(id);
    },
    getAssetsById(id) {
      user.getAssetsById(id).then((res) => {
        this.assets_obj = res.data.data;
        this.assets_obj.map((item) => {
          let new_fields = [
            {
              field_name: "Prefix",
              field_type: "str",
              default_value: item.prefix,
              max_chars: 1000,
              is_active: true,
              is_mandatory: true,
            },
            {
              field_name: "Name",
              field_type: "str",
              default_value: item.name,
              max_chars: 1000,
              is_active: true,
              is_mandatory: true,
            },
            {
              field_name: "Model",
              default_value: item.model,
              field_type: "str",
              max_chars: 255,
              is_active: true,
              is_mandatory: false,
            },
            {
              field_name: "Serial No.",
              default_value: item.serial_no,
              field_type: "str",
              max_chars: 255,
              is_active: true,
              is_mandatory: true,
            },
            {
              field_name: "Status",
              default_value: item.status,
              field_type: "singleselect dropdown",
              options: ["ACTIVE", "INACTIVE", "STOCK", "SOLD"],
              max_chars: 255,
              is_active: true,
              is_mandatory: false,
            },
            {
              field_name: "Warranty",
              default_value: item.warranty,
              field_type: "str",
              max_chars: 255,
              is_active: true,
              is_mandatory: false,
            },
            {
              field_name: "Is Insured",
              default_value: item.is_insured,
              field_type: "bool",
              is_active: true,
              is_mandatory: false,
            },
            {
              field_name: "Insurance Date",
              default_value: item.insurance_date,
              field_type: "single date",
              is_active: true,
              is_mandatory: false,
            },
            {
              field_name: "Purchase Date",
              default_value: item.purchase_date,
              field_type: "single date",
              is_active: true,
              is_mandatory: false,
            },
            {
              field_name: "Description",
              default_value: item.description,
              field_type: "str",
              max_chars: 1000,
              is_active: true,
              is_mandatory: false,
            },
            {
              field_name: "Price",
              default_value: item.price,
              field_type: "int",
              min_limit: 0,
              is_active: true,
              is_mandatory: false,
            },
          ];
          this.category_name = this.category.find(
            (cat) => cat.id == item.asset_cat_id
          ).name;
          item.fields.unshift(...new_fields);
          item.fields.map((obj) => {
            if (obj.field_type == "range date") {
              obj.config.enable = [
                {
                  from: obj.start_date,
                  to: obj.end_date,
                },
              ];
            }
          });
        });
      });
    },
    getAssets() {
      user.getAssets(this.serverParams).then((res) => {
        this.assets_rows = res.data.data.data;
        this.total_records = res.data.data.total;
        this.total_price = Number(res.data.data.total_price).toLocaleString();
      });
    },
    deleteAsset(id) {
      Swal.fire({
        title: "Do you want to delete asset ?",
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        icon: "warning",
      }).then((res) => {
        if (res.isConfirmed) {
          user
            .deleteAsset(id)
            .then((res) => {
              Swal.fire({
                title: res.data.message,
                icon: "success",
              });
              this.getAssets();
            })
            .catch((err) => {
              Swal.fire({
                title: err.response.data.detail,
                icon: "error",
              });
            });
        }
      });
    },
    onColumnFilter(params) {
      // var newObj = { filters: Object.assign({}) };
      // Object.keys(params.columnFilters).forEach(function (key) {
      //   if (params.columnFilters[key] !== "")
      //     newObj.filters[key] = params.columnFilters[key];
      // });
      // this.updateParams(newObj);
      // this.serverParams.page = 1;
      // this.getAssets();
    },
    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
      this.$nextTick(() => this.$refs.redmineFilter.applyFilter(true));
    },
    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
    },

    onPerPageChange(params) {
      this.updateParams({ perpage: params.currentPerPage });
      this.serverParams.page = 1;
    },
    onSortChange(params) {
      this.updateParams({
        sort: [
          {
            type: params[0].type,
            field: params[0].field,
          },
        ],
      });
    },
    getAssetCategory() {
      user.getAssetCategoryList().then((res) => {
        this.category = [];
        this.category = res.data.data;
      });
    },
  },
};
</script>

<style scoped>
.modal-content {
  transition: 0.2s ease-in-out;
}
</style>
