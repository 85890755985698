<template>
  <!-- Begin Page Content -->
  <div class="">
    <div
      class="d-flex flex-md-row flex-column justify-content-between align-items-md-center mt-4 mx-3 pb-3 gap-md-0 gap-3"
    >
      <div>
        <h3 class="text-dark">Milestones</h3>
        <ol class="breadcrumb m-0">
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'DashboardContent' }" tag="a">
              Dashboard
            </router-link>
          </li>
          <li class="breadcrumb-item active">Milestones</li>
        </ol>
      </div>
      <button
        class="btn btn-primary btn-sm ms-md-auto me-md-0 me-auto"
        v-if="permissions.includes('milestones.add')"
        @click="milestoneModalHandler('add')"
      >
        Add Milestone
      </button>
      <!-- <router-link v-if="permissions.includes('milestones.add')" class="btn btn-primary" :to="'/add-milestones'" v-bind:class="[currentPage=='Addmilestones' ? 'active' : '','nav-item']">
              Add Milestone
              </router-link> -->
    </div>
    <div class="mx-3">
      <redmineFilter
        ref="redmineFilter"
        page="milestones"
        @redmine-filter-data="redmineFilterHandler"
        @reset-server-params="onResetServerParams"
        :serverParams="serverParams"
      ></redmineFilter>
    </div>
    <div class="card border-0 shadow-sm my-4 mx-3">
      <div class="card-body">
        <div v-if="permissions.includes('milestones.read')" class="p-2">
          <vue-good-table
            styleClass="vgt-table no-border"
            mode="remote"
            @on-page-change="onPageChange"
            @on-sort-change="onSortChange"
            @on-column-filter="onColumnFilter"
            @on-per-page-change="onPerPageChange"
            :totalRows="totalRecords"
            :isLoading.sync="isLoading"
            :pagination-options="{
              enabled: true,
              perPageDropdown: perpage_list,
            }"
            :rows="rows"
            :columns="columns"
          >
            <template slot="table-row" scope="props">
              <template v-if="props.column.label == 'Action'">
                <div
                  class="d-flex justify-content-center align-items-center p-2 gap-3"
                >
                  <span v-if="permissions.includes('milestones.write')">
                    <button
                      class="btn-pencil"
                      @click="milestoneModalHandler('update', props.row.id)"
                    >
                      <i class="fa fa-pencil fs-5"></i>
                    </button>
                  </span>
                  <span v-if="permissions.includes('milestones.delete')">
                    <button
                      class="btn-trash"
                      @click="delete_milestone(props.row.id)"
                    >
                      <i class="fa fa-trash fs-5"></i>
                    </button>
                  </span>
                </div>
              </template>
            </template>
            <template
              slot="column-filter"
              slot-scope="{ column, updateFilters }"
            >
              <span v-if="column.field == 'estimated_start_time'">
                <datepicker
                  :clear-button="true"
                  :clear-button-icon="'fa fa-times'"
                  :format="formateDate"
                  placeholder="yyyy-mm-dd"
                  @input="
                    (e) =>
                      e ? updateFilters(column, e) : updateFilters(column, '')
                  "
                />
              </span>
              <span v-else-if="column.field == 'estimated_end_time'">
                <datepicker
                  :clear-button="true"
                  :clear-button-icon="'fa fa-times'"
                  :format="formateDate"
                  placeholder="yyyy-mm-dd"
                  @input="
                    (e) =>
                      e ? updateFilters(column, e) : updateFilters(column, '')
                  "
                />
              </span>
              <span v-else-if="column.field == 'actual_start_time'">
                <datepicker
                  :clear-button="true"
                  :clear-button-icon="'fa fa-times'"
                  :format="formateDate"
                  placeholder="yyyy-mm-dd"
                  @input="
                    (e) =>
                      e ? updateFilters(column, e) : updateFilters(column, '')
                  "
                />
              </span>
              <span v-else-if="column.field == 'actual_end_time'">
                <datepicker
                  :clear-button="true"
                  :clear-button-icon="'fa fa-times'"
                  :format="formateDate"
                  placeholder="yyyy-mm-dd"
                  @input="
                    (e) =>
                      e ? updateFilters(column, e) : updateFilters(column, '')
                  "
                />
              </span>
            </template>
            <template slot="pagination-bottom" slot-scope="props">
              <CustomPagination
                class="p-2"
                :pageTabs="4"
                :total="props.total"
                :pageChanged="props.pageChanged"
                :perPageChanged="props.perPageChanged"
                :serverParams="serverParams"
              ></CustomPagination>
            </template>
          </vue-good-table>
        </div>
      </div>
      <div v-if="!permissions.includes('milestones.read')">
        <UnauthorizedPage></UnauthorizedPage>
      </div>
    </div>
    <!-- Client add/update modal -->
    <div
      class="modal fade"
      id="milestoneModal"
      role="dialog"
      aria-hidden="true"
      tabindex="-1"
    >
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h3
              class="modal-title text-dark font-weight-bold pl-3"
              id="taskModalLongTitle"
              style="font-size: 26px"
              v-text="modal_title"
            ></h3>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body mb-2">
            <add-milestones
              @close-modal="milestoneModalHandler('close')"
              v-if="modal_title == 'Update Milestone'"
              :milestone_id="milestone_id"
            />
            <add-milestones
              @close-modal="milestoneModalHandler('close')"
              v-if="modal_title == 'Add Milestone'"
            ></add-milestones>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- End of Main Content -->
</template>
<script>
import user from "@/ajax/user";
import { mapState, mapActions } from "vuex";
import Vue from "vue";
import moment from "moment";
import Datepicker from "vuejs-datepicker";
import axios from "axios";
import myMixin from "@/mixins/Permission";
import UnauthorizedPage from "@/views/Layouts/UnauthorizedPage.vue";
import AddMilestones from "./Addmilestones.vue";
import CustomPagination from "../../components/CustomPagination.vue";
import redmineFilter from "../../components/redmine_filter.vue";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";

Vue.use(VueSweetalert2);

// import VueAxios from 'vue-axios';
// Vue.use(VueAxios,axios)

export default {
  name: "Milestones",
  mixins: [myMixin],
  components: {
    Datepicker,
    UnauthorizedPage,
    AddMilestones,
    CustomPagination,
    redmineFilter,
  },
  data: function () {
    return {
      reqObj: {
        name: "",
      },
      siteLogReq: {
        site_name: "milestones",
      },
      currentPage: "",
      permissions: window.localStorage.getItem("permissions"),
      isLoading: false,
      columns: [
        {
          label: " Project Name",
          field: "project_name",
          type: "value",
          sortable: true,
          thClass: "custom-th",
          tdClass: "custom-td",
          filterable: true,
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Project name", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.onColumnFilter, //custom filter function that
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: " Name",
          field: "name",
          sortable: true,
          thClass: "custom-th",
          tdClass: "custom-td",
          filterable: true,
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Milestone name", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.onColumnFilter, //custom filter function that
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Estimate start date",
          field: "estimated_start_time",
          // type: "date",
          // dateInputFormat: 'yyyy-MM-dd',
          // dateOutputFormat: 'dd/MM/yyyy',
          filterable: true,
          thClass: "custom-th",
          tdClass: "custom-td",
          sortable: true,
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "yyyy-mm-dd", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.onColumnFilter, //custom filter function that
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Estimated end date",
          field: "estimated_end_time",
          // type: "date",
          // dateInputFormat: 'yyyy-MM-dd',
          // dateOutputFormat: 'dd/MM/yyyy',
          filterable: true,
          thClass: "custom-th",
          tdClass: "custom-td",
          sortable: true,
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "yyyy-mm-dd", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.onColumnFilter, //custom filter function that
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Actual start time",
          field: "actual_start_time",
          // type: "date",
          // dateInputFormat: 'yyyy-MM-dd',
          // dateOutputFormat: 'dd/MM/yyyy',
          filterable: true,
          thClass: "custom-th",
          tdClass: "custom-td",
          sortable: true,
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "yyyy-mm-dd", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.onColumnFilter, //custom filter function that
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Actual end time",
          field: "actual_end_time",
          // type: "date",
          // dateInputFormat: 'yyyy-MM-dd',
          // dateOutputFormat: 'dd/MM/yyyy',
          filterable: true,
          thClass: "custom-th",
          tdClass: "custom-td",
          sortable: true,
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "yyyy-mm-dd", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.onColumnFilter, //custom filter function that
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Detail",
          field: "details",
          sortable: true,
          thClass: "custom-th",
          tdClass: "custom-td",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Milestone details", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.onColumnFilter, //custom filter function that
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Action",
          field: "action",
          sortable: false,
          thClass: "disable-sorting custom-th",
          tdClass: "custom-td text-nowrap",
        },
      ],
      rows: [],
      totalRecords: 0,
      serverParams: {
        filters: {},
        sort: [
          {
            field: "",
            type: "asc",
          },
        ],
        page: 1,
        perpage: 100,
      },
      perpage_list: [100, 200, 300, 400, 500],
      modal_title: null,
      milestone_id: null,
    };
  },
  mounted() {
    this.setCurrentPage("Milestones");
    $("#milestoneModal").on("hidden.bs.modal", this.hideModalEventHandler);
    if (this.permissions.includes("milestones.read")) {
      // this.getAllMilestones();
      // this.search_milestones();
      // this.displayAllMilestonePages();
      this.getUserPermissions();
    }
  },
  created() {
    user.createSiteUsage(this.siteLogReq);
  },
  methods: {
    ...mapActions("userStore", ["setCurrentUser", "logoutUser"]),
    ...mapActions("common", ["setCurrentPage"]),

    hideModalEventHandler() {
      this.modal_title = null;
      this.client_id = null;
      $("#milestoneModal").modal("hide");
    },
    milestoneModalHandler(action, id) {
      console.log(action, id);
      if (action == "add") {
        this.modal_title = "Add Milestone";
        $("#milestoneModal").modal("show");
      } else if (action == "update") {
        this.modal_title = "Update Milestone";
        this.milestone_id = id;
        $("#milestoneModal").modal("show");
      } else {
        $("#milestoneModal").modal("hide");
        this.search_milestones();
      }
    },
    redmineFilterHandler() {
      this.search_milestones();
    },
    onResetServerParams(serverParams) {
      this.serverParams = Object.assign({}, serverParams);
      this.$nextTick(() => {
        this.$refs.redmineFilter.applyFilter();
      });
    },
    getAllMilestones() {
      user.getAllMilestones(this.reqObj).then((result) => {
        this.rows = [];
        this.rows = result.data.data;
        this.totalRecords = result.data.data.length;
        // console.log(JSON.stringify(result.data));
        // this.reqObj = result.data;
      });
    },
    // displayAllMilestonePages() {
    //   user.displayAllMilestonePages(this.serverParams.page,this.serverParams.perPage).then((result) => {
    //     //console.log(this.serverParams.page);
    //     //console.log(this.serverParams.perPage)
    //     //console.log(result.data.data);
    //     this.rows = [];
    //     this.reqObj = result.data.data;
    //     this.rows = result.data.data.data;
    //     // console.log(this.rows);
    //     this.totalRecords = result.data.data.total;
    //   });
    // },
    search_milestones() {
      var fields = {
        filters: this.serverParams.filters,
        messageData: this.message,
        sort: this.serverParams.sort,
        page: this.serverParams.page,
        perpage: this.serverParams.perpage,
      };
      // console.log("fieldsdata",fields);
      user.search_milestones(fields).then((result) => {
        this.rows = [];
        this.reqObj = result.data.data;
        this.rows = result.data.data.data;
        this.totalRecords = result.data.data.total;
        // this.paginationDisplayRowsTask = this.getRecords(this.serverParams.page, this.serverParams.perPage, this.totalRecords);
      });
    },
    getMilestonebyid(id) {
      user.getMilestonebyid(id).then((result) => {
        // console.log(result.data);
      });
    },
    delete_milestone(id) {
      this.$swal({
        title: "Delete",
        text: "Are you sure you want to delete?",
        type: "warning",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#32a852",
        cancelButtonColor: "#f00",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      }).then((result) => {
        if (result.value) {
          // this.$swal('Deleted', 'You successfully delete this milestone', 'success')
          user.delete_milestone(id).then((result) => {
            if (result.data.code == 424) {
              this.$swal(result.data.data, result.data.message, "warning");
            }
            if (result.data.code == 200) {
              this.$swal(result.data.data, result.data.message, "success");
            }
            this.search_milestones();
          });
        } else {
          user.delete_milestone(id, (error) => {
            // console.log(error);
          });
        }
      });
    },
    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      this.search_milestones();
      // this.displayAllMilestonePages()
    },

    onPerPageChange(params) {
      this.updateParams({ perpage: params.currentPerPage });
      this.serverParams.page = 1;
      // this.displayAllMilestonePages()
      this.search_milestones();
    },

    onSortChange(params) {
      this.updateParams({
        sort: [
          {
            type: params[0].type,
            field: params[0].field,
          },
        ],
      });
      this.search_milestones();
    },

    onColumnFilter(params) {
      //remove empty fields
      var newObj = { columnFilters: Object.assign({}) };
      Object.keys(params.columnFilters).forEach(function (key) {
        if (params.columnFilters[key] !== "")
          newObj.columnFilters[key] = params.columnFilters[key];
      });
      this.updateParams(newObj);
      // console.log("params",params);
      {
        field: "project_name";
        field: "name";
        field: "estimated_start_time";
        field: "estimated_end_time";
        field: "actual_start_time";
        field: "actual_end_time";
        field: "details";
      }
      this.serverParams.page = 1;
      var filtersPr = JSON.parse(JSON.stringify(this.serverParams));
      var filters = filtersPr.columnFilters;
      // console.log("milestone filters",filters);
      this.search_milestones();
    },
    formateDate(date) {
      return moment(date).format("yyyy-MM-DD");
    },
  },
  computed: {
    ...mapState("userStore", ["currentUser"]),
  },
};
</script>

<style scoped>
.startbuttons {
  padding: 5px 30px 5px 30px;
  margin: 10px;
  font-size: 20px;
}
.disableButton {
  opacity: 0.5;
  pointer-events: none;
}
</style>
