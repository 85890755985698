<template>
  <div class="w-100">
    <!-- Begin Page Content -->
    <div
      class="d-flex flex-md-row flex-column justify-content-between align-items-md-center mt-4 mx-3 pb-3 gap-md-0 gap-3"
    >
      <div>
        <h3 class="text-dark">Projects</h3>
        <ol class="breadcrumb m-0">
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'DashboardContent' }" tag="a">
              Dashboard
            </router-link>
          </li>
          <li class="breadcrumb-item active">Projects</li>
        </ol>
      </div>
      <button
        class="btn btn-primary btn-sm ms-md-auto me-md-0 me-auto"
        v-if="permissions.includes('projects.add')"
        @click="projectModalHandler('add')"
      >
        Add Project
      </button>
      <!-- <router-link
      v-if="permissions.includes('projects.add')"
      class="btn btn-primary"
      :to="'/add-projects'"
      >
      Add Project
    </router-link> -->
    </div>
    <div class="mx-3">
      <redmineFilter
        ref="redmineFilter"
        page="projects"
        @redmine-filter-data="redmineFilterHandler"
        @reset-server-params="onResetServerParams"
        :serverParams="serverParams"
      ></redmineFilter>
    </div>
    <div class="card shadow-sm border-0 my-4 mx-3">
      <div class="card-body">
        <!-- <div class="d-flex justify-content-end pb-1">
          <button
            class="btn btn-primary btn-sm"
            v-if="permissions.includes('projects.add')"
            @click="projectModalHandler('add')"
          >
            Add Project
          </button>
        </div> -->
        <div v-if="permissions.includes('projects.read')">
          <div class="p-2">
            <vue-good-table
              styleClass="vgt-table no-border"
              :columns="columns"
              :rows="rows"
              mode="remote"
              @on-page-change="onPageChange"
              @on-per-page-change="onPerPageChange"
              @on-sort-change="onSortChange"
              @on-column-filter="onColumnFilter"
              :totalRows="FilteredRecords"
              :isLoading.sync="isLoading"
              :pagination-options="{
                enabled: true,
                perPageDropdown: perpage_list,
              }"
            >
              <template
                slot="column-filter"
                slot-scope="{ column, updateFilters }"
              >
                <span v-if="column.field == 'start_date'">
                  <datepicker
                    :clear-button="true"
                    :clear-button-icon="'fa fa-times'"
                    placeholder="yyyy-mm-dd"
                    @input="
                      (e) =>
                        updateFilters(
                          column,
                          e
                            ? e.toISOString().substring(0, 10)
                            : updateFilters(column, '')
                        )
                    "
                  />
                </span>
                <span v-else-if="column.field == 'estimated_end_date'">
                  <datepicker
                    :clear-button="true"
                    :clear-button-icon="'fa fa-times'"
                    placeholder="yyyy-mm-dd"
                    @input="
                      (e) =>
                        updateFilters(
                          column,
                          e
                            ? e.toISOString().substring(0, 10)
                            : updateFilters(column, '')
                        )
                    "
                  />
                </span>
              </template>
              <template slot="table-row" scope="props">
                <template v-if="props.column.label == 'Action'">
                  <div
                    class="d-flex justify-content-center align-items-center gap-3 p-2 flex-md-nowrap flex-wrap"
                  >
                    <button
                      @click="AssignUser(props.row.id)"
                      class="btn-view"
                      v-if="permissions.includes('projects.write')"
                    >
                      <i class="opt-users fs-5"></i>
                    </button>
                    <span
                      v-if="
                        permissions.includes('projects.write') &&
                        props.row.button == 1
                      "
                    >
                      <!-- <router-link
                      :to="'/update-project/' + props.row.id"
                      v-bind:class="[
                        currentPage == 'updateProject' ? 'active' : '',
                      ]"
                      tag="button"
                      class="btn btn-outline-warning border-0 px-2"
                    >
                    <i class="fa fa-pencil"></i>
                  </router-link> -->
                      <button
                        class="btn-pencil"
                        @click="projectModalHandler('update', props.row.id)"
                      >
                        <i class="fa fa-pencil fs-5"></i>
                      </button>
                    </span>
                    <button
                      @click="delete_project(props.row.id)"
                      class="btn-trash"
                      v-if="
                        permissions.includes('projects.delete') &&
                        props.row.button == 1
                      "
                    >
                      <i class="fa fa-trash fs-5"></i>
                    </button>
                  </div>
                </template>
                <span v-if="props.column.field == 'description'">
                  <read-more
                    more-str="read more"
                    :text="props.row.description"
                    less-str="read less"
                    :max-chars="100"
                  ></read-more>
                </span>
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>
              <template slot="pagination-bottom" slot-scope="props">
                <CustomPagination
                  class="p-2"
                  :pageTabs="4"
                  :total="props.total"
                  :pageChanged="props.pageChanged"
                  :perPageChanged="props.perPageChanged"
                  :serverParams="serverParams"
                ></CustomPagination>
              </template>
            </vue-good-table>
          </div>
        </div>
      </div>
    </div>
    <div v-if="!permissions.includes('projects.read')">
      <UnauthorizedPage></UnauthorizedPage>
    </div>
    <!-- model for assign project START -->
    <div
      class="modal fade"
      id="projectAssignModal"
      role="dialog"
      aria-hidden="true"
      tabindex="-1"
    >
      <div class="modal-dialog modal-dialog-centered modal-lg p-2">
        <div class="modal-content">
          <div class="modal-header">
            <h3 class="m-0">Assign User</h3>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div
              class="d-flex justify-content-center align-items-center p-4"
              v-if="assign.users == null"
            >
              <div
                class="spinner-border text-primary"
                style="height: 4rem; width: 4rem"
              ></div>
            </div>
            <div v-else>
              <multiselect
                class="project_multiselect"
                v-model="assign.users"
                :hide-selected="true"
                :options="users"
                :multiple="true"
                :custom-label="custom_label"
                placeholder="Select Multiple User"
                label="first_name"
                track-by="id"
                group-label="label"
                group-values="user_data"
                :group-select="true"
              >
                <template
                  slot="selection"
                  slot-scope="{ values, search, isOpen }"
                ></template>
              </multiselect>
              <div
                class="d-flex justify-content-end align-items-center gap-2 mt-3"
              >
                <button class="btn btn-primary btn-sm" @click="handleSubmit">
                  Submit
                </button>
                <button
                  class="btn btn-outline-secondary btn-sm"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- model for assign project START -->
    <div
      class="modal fade"
      id="projectModal"
      role="dialog"
      aria-hidden="true"
      tabindex="-1"
    >
      <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h3
              class="modal-title text-dark font-weight-bold pl-3"
              id="taskModalLongTitle"
              style="font-size: 26px"
              v-text="modal_title"
            ></h3>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <Addproject
              @close-modal="projectModalHandler('close')"
              v-if="modal_title == 'Update Project'"
              :project_id="project_id"
            />
            <Addproject
              @close-modal="projectModalHandler('close')"
              v-if="modal_title == 'Add Project'"
            />
          </div>
        </div>
      </div>
    </div>
    <!-- End of Main Content -->
  </div>
</template>
<script>
import user from "@/ajax/user";
import { mapState, mapActions } from "vuex";
import Vue from "vue";
import moment from "moment";
import Datepicker from "vuejs-datepicker";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import redmineFilter from "../../components/redmine_filter.vue";
import myMixin from "@/mixins/Permission";
import UnauthorizedPage from "@/views/Layouts/UnauthorizedPage.vue";
import Multiselect from "vue-multiselect";
import ReadMore from "vue-read-more";
import Addproject from "./Addproject.vue";
import CustomPagination from "../../components/CustomPagination.vue";

Vue.use(ReadMore);
Vue.use(VueSweetalert2);

export default {
  name: "Projects",
  mixins: [myMixin],
  components: {
    Multiselect,
    Datepicker,
    UnauthorizedPage,
    Addproject,
    CustomPagination,
    redmineFilter,
  },
  data: function () {
    return {
      firstLoad: 0,
      params: {
        searchTerm: "",
      },
      siteLogReq: {
        site_name: "projects",
      },
      currentPage: "",
      isLoading: true,
      columns: [
        {
          label: "Sub Client",
          field: "subclientname",
          type: "value",
          sortable: true,
          thClass: "custom-th",
          tdClass: "custom-td",
          filterable: true,
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Sub Client name", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.onColumnFilter, //custom filter function that
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Project Name",
          field: "project_name",
          sortable: true,
          thClass: "custom-th",
          tdClass: "custom-td",
          filterable: true,
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Project name", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.onColumnFilter, //custom filter function that
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Start date",
          field: "start_date",
          type: "date",
          dateInputFormat: "yyyy-MM-dd",
          dateOutputFormat: "yyyy-MM-dd",
          filterable: true,
          thClass: "custom-th",
          tdClass: "custom-td",
          sortable: true,
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "yyyy-mm-dd", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.onColumnFilter, //custom filter function that
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Estimated end date",
          field: "estimated_end_date",
          type: "date",
          dateInputFormat: "yyyy-MM-dd",
          dateOutputFormat: "yyyy-MM-dd",
          filterable: true,
          sortable: true,
          thClass: "custom-th",
          tdClass: "custom-td",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "yyyy-mm-dd", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.onColumnFilter, //custom filter function that
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Type",
          field: "project_type",
          sortable: true,
          thClass: "custom-th",
          tdClass: "custom-td",
          formatFn: this.formatFn,
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Project type", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.onColumnFilter, //custom filter function that
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Manager",
          field: "fullname",
          type: "value",
          sortable: true,
          thClass: "custom-th",
          tdClass: "custom-td",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Manager name", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.onColumnFilter, //custom filter function that
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Description",
          field: "description",
          sortable: true,
          thClass: "custom-th",
          tdClass: "custom-td",
          filterable: true,
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Project description", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.onColumnFilter, //custom filter function that
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Status",
          field: "project_status",
          sortable: true,
          thClass: "custom-th",
          tdClass: "custom-td",
          filterable: true,
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Project status", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.onColumnFilter, //custom filter function that
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Action",
          field: "action",
          sortable: false,
          thClass: "custom-th",
          tdClass: "custom-td text-nowrap",
          //   thClass: "disable-sorting"
        },
      ],
      rows: [],
      totalRecords: 0,
      FilteredRecords: 0,
      serverParams: {
        filters: {},
        page: 1,
        perpage: 100,
        setCurrentPage: 1,
        sort: [
          {
            field: "",
            type: "asc",
          },
        ],
      },
      assign: {
        users: null,
        project_id: 0,
      },
      UserState: "",
      users: [],
      checkval: [],
      recordsCounts: "",
      selectedRows: 0,
      displayPageTask: 7,
      jumpToPageTask: 0,
      pageCompareTask: 0,
      paginationDisplayRowsTask: "",
      permissions: window.localStorage.getItem("permissions"),
      perpage_list: [100, 200, 300, 400, 500],
      modal_title: null,
      project_id: null,
    };
  },
  mounted() {
    this.setCurrentPage("Projects");
    $("#projectAssignModal").on("hidden.bs.modal", this.hideModalEventHandler);
    $("#projectModal").on("hidden.bs.modal", this.hideModalEventHandler);
    this.getUserPermissions();
    if (this.permissions.includes("projects.read")) {
      // this.getAllProjectscolumnfilter();
    }
  },
  created() {
    user.createSiteUsage(this.siteLogReq);
  },
  methods: {
    ...mapActions("userStore", ["setCurrentUser", "logoutUser"]),
    ...mapActions("common", ["setCurrentPage"]),
    hideModalEventHandler() {
      this.assign.users = null;
      this.modal_title = null;
      this.project_id = null;
      // $("#projectModal").modal("hide");
    },
    projectModalHandler(action, id) {
      if (action == "add") {
        this.modal_title = "Add Project";
        $("#projectModal").modal("show");
      } else if (action == "update") {
        this.modal_title = "Update Project";
        this.project_id = id;
        $("#projectModal").modal("show");
      } else {
        $("#projectModal").modal("hide");
        // this.getAllProjects();
        this.getAllProjectscolumnfilter();
      }
    },
    custom_label(name) {
      // console.log(name.first_name,name.middle_name,name.last_name);
      if (name.last_name != null && name.middle_name != null) {
        return `${name.first_name} ${name.middle_name} ${name.last_name}`;
      } else if (name.last_name == null) {
        return `${name.first_name} ${name.middle_name}`;
      } else if (name.middle_name == null) {
        return `${name.first_name} ${name.last_name}`;
      }
    },
    getProjectAssignUsers(id) {
      user.getProjectAssignUsers(id).then((result) => {
        this.users = [{ label: "Select all", user_data: result.data.data }];
        // console.log(this.users);
      });
    },
    getProjectUnAssignUsers(id) {
      user.getProjectUnAssignUsers(id).then((result) => {
        this.assign.users = result.data.data;
        this.checkval = this.assign.users;
      });
    },
    checkFormValidity() {
      let check = false;
      if (!this.assign.users) {
        check = false;
      } else {
        check = true;
      }
      return check;
    },
    AssignUser(id) {
      this.assign.project_id = id;
      this.assign.users = null;
      this.getProjectAssignUsers(id);
      this.getProjectUnAssignUsers(id);
      $("#projectAssignModal").modal("show");
    },
    resetModal() {
      this.assign.users = [];
      this.assign.project_id = 0;
      this.UserState = null;
    },
    handleOk(bvModalEvent) {
      // Prevent modal from closing
      // bvModalEvent.preventDefault()
      // Trigger submit handler
      this.handleSubmit();
    },
    handleSubmit() {
      // Exit when the form isn't valid
      if (this.checkFormValidity()) {
        user.AssignProjectToUser(this.assign).then((result) => {
          if (result.data.code == 200) {
            this.$swal(
              "Assigned",
              "User is successfully assigned to project...",
              "success"
            );
          } else if (result.data.code == 401) {
            this.$swal(
              "Error",
              "User is already assigned to this project...",
              "warning"
            );
          } else {
            this.$swal("Error", "Project Not Assigned...", "warning");
          }
          $("#projectAssignModal").modal("hide");
        });
      } else {
      }
      // Hide the modal manually
      // this.$nextTick(() => {
      //   this.$bvModal.hide('modal-prevent-closing')
      // })
    },
    formatFn: function (value) {
      if (value == 1) {
        return "Fixed";
      } else {
        return "Hourly";
      }
    },
    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },
    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      this.getAllProjectscolumnfilter();
    },

    onPerPageChange(params) {
      this.updateParams({ perpage: params.currentPerPage });
      this.serverParams.page = 1;
      this.getAllProjectscolumnfilter();
    },
    onSortChange(params) {
      this.updateParams({
        sort: [
          {
            type: params[0].type,
            field: params[0].field,
          },
        ],
      });
      this.getAllProjectscolumnfilter();
    },

    formateDate(date) {
      return moment(date).format("yyyy-MM-DD");
    },

    onColumnFilter(params) {
      //remove empty fields
      var newObj = { columnFilters: Object.assign({}) };
      Object.keys(params.columnFilters).forEach(function (key) {
        if (params.columnFilters[key] !== "")
          newObj.columnFilters[key] = params.columnFilters[key];
      });
      this.updateParams(newObj);
      this.serverParams.page = 1;
      this.getAllProjectscolumnfilter();
    },

    getAllProjects() {
      user.getAllProjects().then((result) => {
        this.rows = [];
        this.rows = result.data.data;
        this.totalRecords = result.data.total;
        // console.log(result)
      });
    },
    redmineFilterHandler() {
      this.getAllProjectscolumnfilter();
    },
    onResetServerParams(serverParams) {
      this.serverParams = Object.assign({}, serverParams);
      this.$nextTick(() => {
        this.$refs.redmineFilter.applyFilter();
      });
    },
    getAllProjectscolumnfilter() {
      var fields = {
        filters: this.serverParams.filters,
        messageData: this.message,
        sort: this.serverParams.sort,
        page: this.serverParams.page,
        perpage: this.serverParams.perpage,
      };
      // console.log("fieldsdata",fields);
      user.getAllProjectscolumnfilter(fields).then((result) => {
        this.rows = [];
        this.rows = result.data.data.data;
        this.FilteredRecords = result.data.data.total;
      });
    },
    delete_project(id) {
      this.$swal({
        title: "Delete",
        text: "Are you sure you want to delete?",
        type: "warning",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#32a852",
        cancelButtonColor: "#f00",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      }).then((result) => {
        if (result.value) {
          user.delete_project(id).then((result) => {
            if (result.data.code == 424) {
              this.$swal(result.data.data, result.data.message, "warning");
            }
            if (result.data.code == 200) {
              this.$swal(result.data.data, result.data.message, "success");
            }
            this.getAllProjectscolumnfilter();
          });
        }
      });
    },
  },
  computed: {
    ...mapState("userStore", ["currentUser"]),
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
