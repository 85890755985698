<template>
  <div class="">
    <!-- for filters -->
    <!-- <transition name="slide-fade" mode="out-in"> -->
    <div :key="page">
      <div
        class="my-2"
        v-if="team_rewards_rows || your_rewards_rows || manage_rewards_rows"
      >
        <!-- class="d-flex justify-content-between align-items-center flex-wrap"> -->
        <div
          class="d-flex justify-content-start align-items-center flex-md-row flex-column gap-3 flex-wrap"
        >
          <div class="">
            <div
              class="d-flex justify-content-between align-items-center flex-md-row flex-column gap-2"
            >
              <div
                class="d-flex justify-content-between align-items-center gap-2"
              >
                <p class="m-0">From</p>
                <datepicker
                  v-model="filter_data.from"
                  @input="filter_data.to = filter_data.from"
                  :format="'yyyy-MM-dd'"
                  placeholder="Select from date"
                  :calendar-button="true"
                  :clear-button-icon="'fa fa-times'"
                  :input-class="'border-start-0'"
                  :calendar-button-icon="'fa fa-calendar'"
                  :bootstrap-styling="true"
                >
                </datepicker>
              </div>
              <div
                class="d-flex justify-content-between align-items-center gap-md-2 gap-4"
              >
                <p class="m-0">To</p>
                <datepicker
                  v-model="filter_data.to"
                  :format="'yyyy-MM-dd'"
                  placeholder="Select to date"
                  :disabled-dates="{ to: new Date(filter_data.from) }"
                  :calendar-button="true"
                  :clear-button-icon="'fa fa-times'"
                  :input-class="'border-start-0'"
                  :calendar-button-icon="'fa fa-calendar'"
                  :bootstrap-styling="true"
                >
                </datepicker>
              </div>
            </div>
          </div>
          <div
            class=""
            v-if="
              !['your_rwd', 'manage_cat'].includes(page) &&
              filter_data.users[0].values.length
            "
          >
            <multiselect
              :options="filter_data.users"
              v-model="filter_data.selected_users"
              :multiple="true"
              placeholder="Select Users"
              :custom-label="custom_label"
              :group-select="true"
              group-label="label"
              group-values="values"
              track-by="id"
              style="width: 18rem"
            ></multiselect>
          </div>

          <div class="">
            <multiselect
              :options="filter_data.rewards"
              v-model="filter_data.selected_rewards"
              :multiple="true"
              label="name"
              placeholder="Select Reward Category"
              track-by="id"
              style="width: 18rem"
            ></multiselect>
          </div>

          <div class="" v-if="this.page == 'manage_rwd'">
            <multiselect
              :options="filter_data.entry_type_options"
              v-model="filter_data.selected_entry_type"
              placeholder="Select Entry Type"
              :show-labels="false"
              style="width: 18rem"
            ></multiselect>
          </div>
          <div
            class="flex-grow-1 d-flex justify-content-end align-items-center gap-2"
          >
            <button class="btn btn-primary btn-sm" @click="filterHandler">
              Filter
            </button>
            <button
              class="btn btn-success btn-sm"
              v-if="
                currentUser.user_role == 1 &&
                page === 'manage_rwd' &&
                permissions.includes('rewards.assign')
              "
              @click="rewardModalHandler(null)"
            >
              Award Rewards
            </button>
          </div>
        </div>
      </div>
      <!-- For your reward points -->
      <!-- For spinner -->
      <div
        v-if="
          !your_rewards_rows &&
          !team_rewards_rows &&
          !rewards_rows &&
          !manage_rewards_rows
        "
        class="text-center py-5 bg-white"
      >
        <div
          class="spinner-border text-primary"
          style="height: 5rem; width: 5rem"
        ></div>
      </div>
      <div v-if="page === 'your_rwd' && your_rewards_rows">
        <div class="">
          <div
            class="d-flex justify-content-center align-items-center mb-2 p-2 gap-3"
          >
            <span v-if="showAvailable">
              Available: {{ summaryObj.available }}
            </span>
            <span> Opening: {{ summaryObj.opening }} </span>
            <span> Earned: {{ summaryObj.earned }} </span>
            <button
              class="btn-view"
              data-bs-toggle="tooltip"
              data-bs-title="Detailed Reward Summary"
              title="Detailed Reward Summary"
              @click="summaryModalHandler"
            >
              <i class="fa fa-external-link"></i>
            </button>
          </div>
          <!-- <div
          class="ms-auto btn btn-link link-dark d-block p-2 mb-2"
          @click="summaryModalHandler"
          style="width: max-content"
          title="Click here to see more details"
        >
        </div> -->
          <div>
            <vue-good-table
              mode="remote"
              @on-page-change="onPageChange"
              @on-sort-change="onSortChange"
              @on-per-page-change="onPerPageChange"
              :isLoading.sync="isLoading"
              :totalRows="filter_data.total_records"
              :pagination-options="{ enabled: true }"
              :rows="your_rewards_rows"
              :columns="your_rewards_columns"
              styleClass="vgt-table no-border"
            >
              <template slot="pagination-bottom" slot-scope="props">
                <CustomPagination
                  class="p-2"
                  :pageTabs="4"
                  :total="props.total"
                  :pageChanged="props.pageChanged"
                  :perPageChanged="props.perPageChanged"
                  :serverParams="filter_data"
                ></CustomPagination>
              </template>
            </vue-good-table>
          </div>
        </div>
      </div>
      <!-- For manage reward categories -->
      <div
        v-if="
          page === 'manage_cat' &&
          rewards_rows &&
          permissions.includes('reward_categories.read')
        "
      >
        <div class="">
          <div class="mb-2">
            <button
              class="btn btn-primary btn-sm d-block ms-md-auto ms-0 mx-md-0 mx-auto my-2"
              :disabled="disabled"
              :class="disabled ? 'disabled' : ''"
              v-if="permissions.includes('reward_categories.add')"
              @click="rewardHandler('add')"
            >
              Add Rewards Category
            </button>
          </div>
          <vue-good-table
            :columns="rewards_columns"
            :rows="rewards_rows"
            :isLoading.sync="isLoading"
            styleClass="vgt-table no-border"
          >
            <template slot="table-row" scope="props">
              <div
                v-if="props.column.label == 'Action'"
                class="p-2 d-flex justify-content-center align-items-center gap-3"
              >
                <button
                  class="btn-pencil"
                  v-if="permissions.includes('rewards.write')"
                  @click="rewardHandler('update', props.row)"
                >
                  <i class="fa fa-pencil fs-5"></i>
                </button>
                <button
                  class="btn-trash"
                  v-if="permissions.includes('rewards.delete')"
                  @click="deleteRewards(props.row.id)"
                >
                  <i class="fa fa-trash fs-5"></i>
                </button>
              </div>
            </template>
          </vue-good-table>
        </div>
      </div>
      <!-- For manage reward points -->
      <div
        v-if="
          page === 'manage_rwd' &&
          manage_rewards_rows &&
          permissions.includes('rewards.read')
        "
        class=""
      >
        <!-- For summary -->
        <span class="text-center p-2 mb-2 d-block">
          Total Points: {{ summaryObj.total_points }}
        </span>
        <!--Actual table -->
        <div class="">
          <vue-good-table
            mode="remote"
            @on-page-change="onPageChange"
            @on-sort-change="onSortChange"
            @on-per-page-change="onPerPageChange"
            :pagination-options="{ enabled: true }"
            :isLoading.sync="isLoading"
            :rows="manage_rewards_rows"
            :columns="manage_rewards_columns"
            :totalRows="filter_data.total_records"
            styleClass="vgt-table"
          >
            <template slot="table-row" scope="props">
              <div
                v-if="props.column.label == 'Action'"
                class="p-2 d-flex justify-content-center align-items-center gap-3"
              >
                <button
                  class="btn-pencil"
                  v-if="permissions.includes('rewards.write')"
                  @click="rewardModalHandler(props.row)"
                >
                  <i class="fa fa-pencil fs-5"></i>
                </button>
                <button
                  class="btn-trash"
                  v-if="permissions.includes('rewards.delete')"
                  @click="deleteUserRewards(props.row)"
                >
                  <i class="fa fa-trash fs-5"></i>
                </button>
              </div>
              <div v-else-if="props.column.label == 'Entry Type'">
                <h6 class="m-0">
                  <span
                    v-if="props.row.entry_type == 'CREDIT'"
                    v-text="props.row.entry_type"
                    class="badge badge-success"
                  ></span>
                  <span
                    v-if="props.row.entry_type == 'DEBIT'"
                    v-text="props.row.entry_type"
                    class="badge badge-danger"
                  ></span>
                </h6>
              </div>
            </template>
            <template slot="pagination-bottom" slot-scope="props">
              <CustomPagination
                class="p-2"
                :pageTabs="4"
                :total="props.total"
                :pageChanged="props.pageChanged"
                :perPageChanged="props.perPageChanged"
                :serverParams="filter_data"
              ></CustomPagination>
            </template>
          </vue-good-table>
        </div>
      </div>
      <!-- For teammates reward points -->
      <div
        v-if="
          page === 'team_rwd' &&
          team_rewards_rows &&
          permissions.includes('rewards.read')
        "
        class=""
      >
        <!-- For summary -->
        <span class="text-center p-2 mb-2 d-block">
          Total Points: {{ summaryObj.total_points }}
        </span>
        <!--Actual table -->
        <div class="">
          <vue-good-table
            mode="remote"
            @on-page-change="onPageChange"
            @on-sort-change="onSortChange"
            @on-per-page-change="onPerPageChange"
            :pagination-options="{ enabled: true }"
            :isLoading.sync="isLoading"
            :rows="team_rewards_rows"
            :columns="team_rewards_columns"
            :totalRows="filter_data.total_records"
            styleClass="vgt-table"
          >
            <template slot="pagination-bottom" slot-scope="props">
              <CustomPagination
                class="p-2"
                :pageTabs="4"
                :total="props.total"
                :pageChanged="props.pageChanged"
                :perPageChanged="props.perPageChanged"
                :serverParams="filter_data"
              ></CustomPagination>
            </template>
          </vue-good-table>
        </div>
      </div>
    </div>
    <!-- </transition> -->

    <!-- For rank board -->
    <div class="" v-if="page == 'rank_board'">
      <!-- <h1>Rank Board</h1> -->
      <div class="p-4">
        <div class="row gy-3">
          <div
            class="col-6"
            v-for="category in rank_board_rows"
            :key="category.cat_id"
          >
            <h4 class="m-0 border-bottom pb-3">
              {{ category.category_name }}
            </h4>
            <table class="table table-striped">
              <thead>
                <tr>
                  <th>Rank No.</th>
                  <th>Username</th>
                  <th>Points</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(user, index) in category.users" :key="index">
                  <td>{{ index + 1 }}</td>
                  <td>{{ user.full_name }}</td>
                  <td>
                    {{ user.total_points }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <!-- Reward Modal -->
    <div class="modal modal" tabindex="-1" role="dialog" id="rewardModal">
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h3 class="mb-0 text-dark" v-if="multiSelection">Award Rewards</h3>
            <h3 class="mb-0 text-dark" v-else>Update Awarded Reward</h3>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <form @submit.prevent="formHandler">
              <div class="mb-3">
                <label class="form-label">Select Reward Category</label>
                <multiselect
                  :options="modal_rewards"
                  label="name"
                  v-model="awardObj.cat_id"
                  placeholder="Select Reward"
                  track-by="id"
                  class="mr-3"
                  required
                ></multiselect>
                <small
                  class="text-danger mb-2"
                  v-text="messages.category_error"
                ></small>
              </div>
              <div class="mb-3">
                <label class="form-label">Select Users</label>
                <multiselect
                  :options="users"
                  v-model="awardObj.user_id"
                  :multiple="multiSelection"
                  placeholder="Select user to award reward"
                  :custom-label="custom_label"
                  :close-on-select="!multiSelection"
                  track-by="id"
                  :group-select="true"
                  group-label="label"
                  group-values="values"
                  class="mr-3"
                  ref="modal_user_ms"
                  required
                ></multiselect>
                <small
                  class="text-danger mb-2"
                  v-text="messages.user_error"
                ></small>
              </div>
              <div class="mb-3">
                <label class="form-label">Select Date</label>
                <datepicker
                  format="yyyy-MM-dd"
                  :clear-button="true"
                  :calendar-button="true"
                  :clear-button-icon="'fa fa-times'"
                  :input-class="'border-start-0'"
                  :calendar-button-icon="'fa fa-calendar'"
                  :bootstrap-styling="true"
                  v-model="awardObj.month"
                  :disabled-dates="{
                    from: new Date(),
                    to: new Date(2022, 4, 1),
                  }"
                  placeholder="Select month"
                  :typeable="true"
                ></datepicker>
              </div>
              <div class="mb-3">
                <label class="form-label">Select Action</label>
                <multiselect
                  :options="credit_debit_obj.dropdown_options"
                  v-model="credit_debit_obj.selected_option"
                  placeholder="Select credit/debit"
                  class="mr-3"
                  required
                ></multiselect>
                <small
                  class="text-danger"
                  v-text="messages.action_error"
                ></small>
              </div>
              <div class="mb-3" v-if="credit_debit_obj.show_input">
                <label class="form-label">Entries</label>
                <input
                  class="form-control"
                  type="number"
                  min="1"
                  max="1000"
                  style="height: 43px"
                  required
                  v-model.number="credit_debit_obj.credit_debit_value"
                  placeholder="Enter number of entries"
                />
              </div>
              <div class="mb-3">
                <label class="form-label">Notes</label>
                <input
                  class="form-control"
                  type="text"
                  style="height: 43px"
                  v-model="awardObj.notes"
                  placeholder="Enter notes here"
                />
              </div>
              <div class="d-flex justify-content-end gap-2 m-0">
                <!-- @click.prevent="formHandler" -->
                <button class="btn btn-primary">Submit</button>
                <button
                  type="button"
                  class="btn btn-outline-secondary"
                  data-bs-dismiss="modal"
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <!-- Add Reward Modal -->
    <div
      class="modal fade"
      tabindex="-1"
      role="dialog"
      id="addRewardModal"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h3
              class="text-dark m-0"
              id="exampleModalLabel"
              v-text="modal_title"
            ></h3>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <form @submit.prevent="submitHandler">
              <div class="mb-3">
                <label class="form-label mandatory">Name</label>
                <input
                  v-model="rewardObj.name"
                  class="form-control"
                  placeholder="Enter the reward name"
                  required
                />
              </div>
              <div class="mb-3">
                <label class="form-label mandatory">Code</label>
                <input
                  v-model="rewardObj.code"
                  @input="
                    (e) =>
                      rewardObj.code.length > 5
                        ? (rewardObj.code = rewardObj.code.slice(0, 5))
                        : ''
                  "
                  class="form-control"
                  placeholder="Enter the reward code"
                  required
                  :readonly="Object.hasOwn(rewardObj, 'id')"
                />
              </div>
              <div class="mb-3">
                <label class="form-label mandatory">Points</label>
                <input
                  v-model.number="rewardObj.points"
                  type="number"
                  min="1"
                  @keypress="isNumber"
                  class="form-control"
                  required
                  placeholder="Enter the reward points"
                />
              </div>
              <div class="mb-3">
                <label class="form-check-label">Active</label>
                <input
                  v-model="rewardObj.is_active"
                  type="checkbox"
                  class="form-check-input ms-3"
                />
              </div>
              <div>
                <div class="d-flex justify-content-end gap-2 m-0">
                  <button class="btn btn-primary">Submit</button>
                  <button
                    class="btn btn-outline-secondary"
                    data-bs-dismiss="modal"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <!-- Reward Summary Modal-->
    <div
      class="modal fade"
      tabindex="-1"
      role="dialog"
      id="rewardSummaryModal"
      aria-hidden="true"
    >
      <div
        class="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable"
      >
        <div class="modal-content">
          <div class="modal-header">
            <h3 class="text-dark m-0" id="exampleModalLabel">
              Your Reward Summary
            </h3>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body p-0">
            <div
              class="d-flex justify-content-center align-items-center p-4"
              v-if="!rewardSummary"
            >
              <div
                class="spinner-border text-primary"
                style="height: 4rem; width: 4rem"
              ></div>
            </div>
            <div v-else>
              <div
                class="row g-0 py-3 text-center border-bottom position-sticky top-0 shadow-sm bg-light"
              >
                <div class="col-3 fw-bold">Reward Name</div>
                <div class="col-3 fw-bold">Available</div>
                <div class="col-3 fw-bold">Opening</div>
                <div class="col-3 fw-bold">Earned</div>
              </div>
              <div
                class="row border-bottom py-2 g-0 text-center"
                v-for="(item, index) in rewardSummary"
                :key="index"
              >
                <div class="col-3">
                  <span v-text="item.name"></span>
                </div>
                <div class="col-3">
                  <span v-text="item.available"></span>
                </div>
                <div class="col-3">
                  <span v-text="item.opening"></span>
                </div>
                <div class="col-3">
                  <span v-text="item.earned"></span>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button class="btn btn-outline-secondary" data-bs-dismiss="modal">
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import myMixin from "@/mixins/Permission";
import user from "@/ajax/user";
import { mapState, mapActions } from "vuex";
import Multiselect from "vue-multiselect";
import moment from "moment";
import Datepicker from "vuejs-datepicker";
import CustomPagination from "./CustomPagination.vue";
// import 'vue2-datepicker/index.css';

export default {
  name: "dsa",
  mixins: [myMixin],
  components: {
    Datepicker,
    Multiselect,
    CustomPagination,
  },
  props: {
    page: { default: "your_rwd" },
    user_id: {
      default: parseInt(localStorage.getItem("current_user")),
      setPage: { default: true },
    },
  },
  data() {
    return {
      disabled: false,
      submitAction: "add",
      multiSelection: true,
      isLoading: false,
      tempObj: null,
      users: [],
      awardObj: {
        user_id: [],
        month: moment().format("YYYY-MM-DD"),
        cat_id: null,
        notes: null,
      },
      modal_rewards: [],
      showAdminColumns: false,
      credit_debit_obj: {
        dropdown_options: ["Credit", "Debit"],
        selected_option: null,
        credit_debit_value: 1,
        show_input: false,
      },

      messages: {
        user_error: "",
        category_error: "",
        action_error: "",
      },
      filter_data: {
        users: [],
        selected_users: [],
        rewards: [],
        selected_rewards: [],
        entry_type_options: ["CREDIT", "DEBIT", "BOTH"],
        selected_entry_type: "BOTH",
        from: moment().startOf("month").format("YYYY-MM-DD"),
        to: moment().format("YYYY-MM-DD"),
        page: 1,
        perpage: 100,
        total_pages: 1,
        total_records: 0,
        sort: [],
      },
      summaryObj: {
        available: 0,
        earned: 0,
        opening: 0,
        total_points: 0,
      },
      permissions: localStorage.getItem("permissions"),
      action: null,
      rewards_columns: [
        {
          label: "Reward Code",
          field: "code",
          thClass: "custom-th",
          tdClass: "custom-td",
          sortable: true,
        },
        {
          label: "Reward Name",
          field: "name",
          thClass: "custom-th",
          tdClass: "custom-td",
          sortable: true,
        },
        {
          label: "Reward Points",
          field: "points",
          thClass: "custom-th",
          tdClass: "custom-td",
          sortable: true,
        },
        {
          label: "Status",
          field: this.fieldFn,
          thClass: "custom-th",
          tdClass: "custom-td",
          sortable: true,
        },
        {
          label: "Action",
          field: "action",
          thClass: "custom-th",
          tdClass: "custom-td",
          sortable: false,
        },
      ],
      your_rewards_columns: [
        {
          label: "Reward",
          field: "name",
          thClass: "custom-th",
          tdClass: "custom-td",
          sortable: false,
        },
        {
          label: "Date",
          field: "date",
          thClass: "custom-th",
          tdClass: "custom-td",
          sortable: false,
        },
        {
          label: "Points Earned",
          field: "total",
          thClass: "custom-th",
          tdClass: "custom-td",
          sortable: false,
        },
        {
          label: "Notes",
          field: "notes",
          thClass: "custom-th",
          tdClass: "custom-td",
          sortable: false,
        },
        {
          label: "Rewarded By",
          field: "created_name",
          thClass: "custom-th",
          tdClass: "custom-td",
          sortable: false,
        },
      ],
      team_rewards_columns: [
        {
          label: "Username",
          field: "full_name",
          thClass: "custom-th",
          tdClass: "custom-td",
          sortable: false,
        },
        {
          label: "Date",
          field: "date",
          thClass: "custom-th",
          tdClass: "custom-td",
          sortable: false,
        },
        {
          label: "Category",
          field: "name",
          thClass: "custom-th",
          tdClass: "custom-td",
          sortable: false,
        },
        {
          label: "Credit Points",
          field: "credit",
          thClass: "custom-th",
          tdClass: "custom-td",
          sortable: false,
        },
        {
          label: "Debit Points",
          field: "debit",
          thClass: "custom-th",
          tdClass: "custom-td",
          sortable: false,
        },
        {
          label: "Notes",
          field: "notes",
          thClass: "custom-th",
          tdClass: "custom-td",
          sortable: false,
        },
        {
          label: "Rewarded By",
          field: "created_name",
          thClass: "custom-th",
          tdClass: "custom-td",
          sortable: false,
        },
      ],
      manage_rewards_columns: [
        {
          label: "Username",
          field: "full_name",
          thClass: "custom-th",
          tdClass: "custom-td",
          sortable: false,
        },
        {
          label: "Date",
          field: "date",
          thClass: "custom-th",
          tdClass: "custom-td",
          sortable: false,
        },
        {
          label: "Category",
          field: "name",
          thClass: "custom-th",
          tdClass: "custom-td",
          sortable: false,
        },
        {
          label: "Entry Type",
          field: "entry_type",
          thClass: "custom-th",
          tdClass: "custom-td",
          sortable: false,
        },
        {
          label: "Points",
          field: "points",
          thClass: "custom-th",
          tdClass: "custom-td",
          sortable: false,
        },
        {
          label: "Notes",
          field: "notes",
          thClass: "custom-th",
          tdClass: "custom-td",
          sortable: false,
        },
        {
          label: "Rewarded By",
          field: "rewarded_by",
          thClass: "custom-th",
          tdClass: "custom-td",
          sortable: false,
        },
        {
          label: "Action",
          field: "action",
          thClass: "custom-th",
          tdClass: "custom-td",
          sortable: false,
        },
      ],
      rank_board_columns: [
        {
          label: "Username",
          field: "full_name",
          thClass: "custom-th",
          tdClass: "custom-td",
          sortable: false,
        },
        {
          label: "Total",
          field: "total",
          thClass: "custom-th",
          tdClass: "custom-td",
          sortable: false,
        },
      ],
      rank_board_rows: null,
      rewards_rows: null,
      your_rewards_rows: null,
      team_rewards_rows: null,
      manage_rewards_rows: [],
      rewardSummary: null,
      totalRecords: 0,
      rewardObj: {
        name: "",
        points: 0,
        code: "",
        is_active: true,
      },
      modal_title: "",
    };
  },
  computed: {
    ...mapState("userStore", ["currentUser"]),
    showAvailable() {
      return moment(this.filter_data.from).isBefore(moment());
    },
    selectedOption() {
      return this.credit_debit_obj.selected_option;
    },
  },
  mounted() {
    //this.getUserPermissions();
    if (this.setPage) {
      this.setCurrentPage("Rewards");
    }
    this.optionChanger();
    $("#rewardModal,#addRewardModal").on(
      "hidden.bs.modal",
      this.closeModalHandler
    );
  },
  watch: {
    selectedOption: {
      handler(nv, ov) {
        // console.log("changed");
        if (nv != ov && nv != null) {
          this.credit_debit_obj.show_input = true;
          this.credit_debit_obj.credit_debit_value = 1;
          if (nv == "Credit" && this.multiSelection == false) {
            this.credit_debit_obj.credit_debit_value = this.tempObj.credit;
          }
          if (nv == "Debit" && this.multiSelection == false) {
            this.credit_debit_obj.credit_debit_value = this.tempObj.debit;
          }
        } else {
          this.credit_debit_obj.show_input = false;
        }
      },
      deep: true,
    },
    page: {
      handler(nv, ov) {
        this.optionChanger(nv);
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    ...mapActions("common", ["setCurrentPage"]),

    updateParams(newProps) {
      this.filter_data = Object.assign({}, this.filter_data, newProps);
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      this.filterHandler();
    },

    onPerPageChange(params) {
      this.updateParams({ perpage: params.currentPerPage });
      this.filter_data.page = 1;
      this.filterHandler();
    },

    onSortChange(params) {
      this.updateParams({
        sort: [
          {
            type: params[0].type,
            field: params[0].field,
          },
        ],
      });
      this.filterHandler();
    },
    summaryModalHandler() {
      this.getRewardsSummary();
      $("#rewardSummaryModal").modal("show");
    },
    getRewardsSummary() {
      let obj = {
        id: this.user_id,
        start_date: moment(this.filter_data.from).format("YYYY-MM-DD"),
        end_date: moment(this.filter_data.to).format("YYYY-MM-DD"),
      };
      user.getRewardsSummary(obj).then((res) => {
        this.rewardSummary = res.data.data;
      });
    },
    isNumber(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        (charCode < 48 || charCode > 57) &&
        (charCode !== 46 || this.rewardObj.points.indexOf(".") >= 0)
      ) {
        // 46 is for dot
        evt.preventDefault();
      }
      return true;
    },
    fieldFn(value) {
      return value.is_active ? "Active" : "In Active";
    },
    getRewardsCreditDebitEntries() {
      user.getRewardsCreditDebitEntries().then((res) => {
        this.manage_rewards_rows = res.data;
      });
    },
    filterHandler() {
      let obj = {
        id: this.user_id,
        cat_ids: this.filter_data.selected_rewards.map((item) => item.id),
        user_ids: this.filter_data.selected_users.map((item) => item.id),
        month: [
          moment(this.filter_data.from).format("YYYY-MM-DD"),
          moment(this.filter_data.to).format("YYYY-MM-DD"),
        ],
        page: this.filter_data.page,
        perpage: this.filter_data.perpage,
        sort: this.filter_data.sort,
      };

      if (this.page == "your_rwd" || this.page == "team_rwd") {
        let action = null;
        this.page == "your_rwd" ? (action = "your") : (action = "team");
        obj["rewards"] = action;
        this.filter_data.from == null ? (this.filter_data.from = moment()) : "";

        user.getUserRewards(obj).then((result) => {
          this.filter_data.total_records = result.data.data.total;
          if (action == "your") {
            this.your_rewards_rows = result.data.data.data;
            // console.log(this.your_rewards_rows, this.totalRecords);
            this.summaryObj.available = result.data.data.available;
            this.summaryObj.opening = result.data.data.opening;
            this.summaryObj.earned = result.data.data.earned;
          } else {
            if (
              !(
                result.data.data instanceof Object &&
                result.data.data instanceof Array
              )
            ) {
              this.team_rewards_rows = result.data.data.data;
              this.summaryObj.total_points = result.data.data.total_points;
            } else {
              this.team_rewards_rows = null;
            }
          }
          this.isLoading = false;
          return;
        });
      }
      if (this.page == "manage_rwd") {
        obj["entry_type"] = this.filter_data.selected_entry_type;
        user.getRewardsCreditDebitEntries(obj).then((result) => {
          this.manage_rewards_rows = result.data.data.data;
          this.filter_data.total_records = result.data.data.total;
          this.summaryObj.total_points = result.data.data.total_points;
        });
      }
    },
    dateHandler(event) {
      this.filter_data.from = moment(event).endOf("month").format("YYYY-MM-DD");
    },
    closeModalHandler() {
      // To reset award reward modal fields
      this.rewardObj = {
        name: "",
        points: 0,
        code: "",
        is_active: false,
      };
      this.awardObj = {
        user_id: [],
        month: moment().format("YYYY-MM-DD"),
        cat_id: null,
        notes: "",
      };
      // To reset error messages
      (this.messages = {
        user_error: "",
        category_error: "",
        action_error: "",
      }),
        // To reset the points feild value and visibility in modal
        (this.credit_debit_obj = {
          dropdown_options: ["Credit", "Debit"],
          selected_option: null,
          credit_debit_value: 1,
          show_input: false,
        });
    },
    formHandler() {
      // To check for award reward modal fields validation and set error messages
      let check = true;
      if (this.awardObj.user_id.length == 0) {
        this.messages.user_error = "Please select user";
        check = false;
      } else {
        this.messages.user_error = "";
        check = true;
      }
      if (this.awardObj.cat_id == null) {
        this.messages.category_error = "Please select category";
        check = false;
      } else {
        this.messages.category_error = "";
        check = true;
      }
      if (
        this.credit_debit_obj.selected_option == null &&
        this.multiSelection
      ) {
        this.messages.action_error = "Please select action";
        check = false;
      } else {
        this.messages.action_error = "";
        check = true;
      }

      if (check) {
        // To set credit and debit values in object if present
        this.credit_debit_obj.selected_option == "Credit"
          ? (this.awardObj.credit = this.credit_debit_obj.credit_debit_value)
          : this.credit_debit_obj.selected_option == "Debit"
          ? (this.awardObj.debit = this.credit_debit_obj.credit_debit_value)
          : "";
        // To set month if blank value passed
        this.awardObj.month == "" ? (this.awarObj.month = new Date()) : "";

        let tempObj = structuredClone(this.awardObj);
        tempObj.user_id = tempObj.user_id.map((i) => i.id);
        tempObj.cat_id = tempObj.cat_id.id;

        // To call api for create or update
        if (this.multiSelection) {
          user
            .setUserRewards(tempObj)
            .then((result) => {
              Swal.fire("Success", result.data.message, "success");
              this.filterHandler();
            })
            .catch((err) => {
              Swal.fire("Error", result.data.message, "error");
            });
        } else {
          user
            .updateUserRewards(tempObj)
            .then((result) => {
              Swal.fire("Success", result.data.message, "success");
              this.filterHandler();
            })
            .catch((err) => {
              Swal.fire("Error", result.data.message, "error");
            });
        }
        $("#rewardModal").modal("hide");
      }
    },
    rewardModalHandler(item) {
      if (item) {
        this.tempObj = JSON.parse(JSON.stringify(item));
        this.multiSelection = false;

        // The below line of code will make the watcher to trigger form where we will set the values
        this.credit_debit_obj.selected_option =
          item.entry_type == "CREDIT" ? "Credit" : "Debit";

        this.awardObj.id = item.reward_id;
        this.awardObj.notes = item.notes;
        this.awardObj.month = item.date;
        this.awardObj.cat_id = this.rewards_rows.find(
          (i) => i.id == item.cat_id
        );
        this.awardObj.user_id = this.users[0].values.filter(
          (i) => i.id == item.user_id
        );
        console.log(
          this.users[0].values.filter((i) => i.id == item.user_id),
          this.awardObj.user_id
        );
        // this.awardObj.user_id = [fetchedUser];
      } else {
        this.multiSelection = true;
      }
      $("#rewardModal").modal("show");
      // console.log("AFTER MODAL SHOW ---- ", this.awardObj);
    },
    // For credit/debit modal
    addRewardHandler() {
      $("#addRewardModal").modal("show");
    },
    // For modification of username
    custom_label(name) {
      // console.log(name.first_name,name.middle_name,name.last_name);
      if (name.last_name != null && name.middle_name != null) {
        return `${name.first_name} ${name.middle_name} ${name.last_name}`;
      } else if (name.last_name == null) {
        return `${name.first_name} ${name.middle_name}`;
      } else if (name.middle_name == null) {
        return `${name.first_name} ${name.last_name}`;
      }
    },
    // To fetch the users for award assignment
    awardUserHandler() {
      user.getUsers().then((result) => {
        // for award reward modal
        this.users = [
          {
            label: "Select All",
            values: result.data.data.filter(
              (item) => [1, 2].includes(item.user_type) && !item.is_deleted
            ),
          },
        ];

        // for filters
        this.filter_data.users = [
          {
            label: "Select All",
            values: result.data.data.filter((item) => {
              if (this.page == "team_rwd") {
                return (
                  [1, 2].includes(item.user_type) &&
                  item.id != this.currentUser.id
                );
              } else {
                return [1, 2].includes(item.user_type);
              }
            }),
          },
        ];
        // console.log("user before-------- ",this.filter_data.users);
        // if(this.page=="team_rwd"){
        //   this.filter_data.users[0].values=this.filter_data.users[0].values.filter(item=>item.id!=this.currentUser.id);
        //   console.log("user if-------- ",this.filter_data.users);
        // }
      });
    },
    // To handle nav tab changes
    optionChanger() {
      this.rewards_rows =
        this.your_rewards_rows =
        this.team_rewards_rows =
        this.manage_rewards_rows =
        this.rank_board_rows =
          null;
      this.isLoading = true;
      this.filter_data = Object.assign(this.filter_data, {
        id: this.user_id,
        users: [],
        selected_users: [],
        rewards: [],
        selected_rewards: [],
        from: moment().startOf("month").toDate(),
        to: moment().endOf("month").toDate(),
        page: 1,
        perpage: 100,
        total_pages: null,
      });

      if (this.page == "manage_cat") {
        this.rewards_rows = null;
        this.getRewardsCategories();
      } else if (this.page == "your_rwd") {
        // this.awardUserHandler();
        this.getRewardsCategories();
        this.filterHandler("your");
      } else if (this.page == "team_rwd") {
        this.awardUserHandler();
        this.getRewardsCategories();
        this.filterHandler();
      } else if (this.page == "manage_rwd") {
        this.awardUserHandler();
        this.getRewardsCategories();
        this.filterHandler();
      } else if (this.page == "rank_board") {
        this.getRewardsRankBoard();
      }
    },
    getRewardsRankBoard() {
      user.getRewardsRankBoard().then((result) => {
        // console.log(result);
        this.rank_board_rows = result.data.data;
        console.log(this.rank_board_rows);
      });
    },
    // To get list of reward category
    getRewardsCategories() {
      user.getRewardsCategories().then((result) => {
        // this.rewards = result.data.data;
        this.rewards_rows = result.data.data;
        this.filter_data.rewards = result.data.data.filter(
          (item) => item.is_active
        );
        this.modal_rewards = result.data.data.filter((item) => item.is_active);
        this.isLoading = false;
      });
    },
    // To call the add or update api for reward category
    submitHandler() {
      if (!Object.keys(this.rewardObj).includes("id")) {
        user.addRewards(this.rewardObj).then((result) => {
          if (result.data.code == 200) {
            Swal.fire("Success", result.data.message, "success");
            this.getRewardsCategories();
          }
          if (result.data.code == 400) {
            Swal.fire("Error", result.data.message, "error");
          }
          $("#addRewardModal").modal("hide");
        });
      } else {
        user.updateRewards(this.rewardObj).then((result) => {
          if (result.data.code == 200) {
            Swal.fire("Success", result.data.message, "success");
            this.getRewardsCategories();
          }
          if (result.data.code == 400) {
            this.rewardObj.name = this.tempObj.name;
            this.rewardObj.points = this.tempObj.points;
            Swal.fire("Error", result.data.message, "error");
          }
          $("#addRewardModal").modal("hide");
        });
        // }
        // else{
        // this.disabled = false;
        // this.awardObj=this.tempObj;
        // Swal.fire("Error","Nothing to update","error");
        // }
      }
    },
    // To delete the user rewards
    deleteUserRewards(item) {
      Swal.fire({
        title: "Delete",
        text: "Are you sure you want to delete?",
        type: "warning",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#32a852",
        cancelButtonColor: "#f00",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      }).then((res) => {
        if (res.value) {
          user
            .deleteUserRewards(item.user_id, item.reward_id)
            .then((result) => {
              if (result.data.code == 200) {
                Swal.fire("Success", result.data.message, "success");
                this.filterHandler();
              }
              if (result.data.code == 400) {
                Swal.fire("Error", result.data.message, "error");
              }
            })
            .catch((err) => {
              Swal.fire("Error", "Something Went wrong", "error");
            });
        }
      });
    },
    // To delete the reward category
    deleteRewards(id) {
      // console.log("delete ",id);
      Swal.fire({
        title: "Delete",
        text: "Are you sure you want to delete?",
        type: "warning",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#32a852",
        cancelButtonColor: "#f00",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      }).then((res) => {
        if (res.value) {
          user
            .deleteRewards(id)
            .then((result) => {
              user;
              this.getRewardsCategories();
              if (result.data.code == 200) {
                Swal.fire("Success", result.data.message, "success");
                this.getRewardsCategories();
              }
              if (result.data.code == 400) {
                Swal.fire("Error", result.data.message, "error");
              }
            })
            .catch((err) => {
              Swal.fire("Error", "Something Went wrong", "error");
            });
        }
      });
    },
    // To modify the list for add and update functionality
    rewardHandler(action, item) {
      if (action == "update") {
        this.modal_title = "Update Reward Category";
        this.rewardObj.name = item.name;
        this.rewardObj.code = item.code;
        this.rewardObj.points = item.points;
        this.rewardObj.is_active = item.is_active;
        this.rewardObj.id = item.id;
      } else {
        delete this.rewardObj.id;
        this.modal_title = "Add Reward Category";
      }
      $("#addRewardModal").modal("show");
    },
  },
};
</script>

<style scoped>
#reward_table tr td {
  font-size: 18px;
}
.active a {
  color: #583d72;
  border-bottom: 3px solid #583d72;
}
.nav-link {
  font-size: 17px !important;
  color: #79869f;
  cursor: pointer;
}

.tab {
  cursor: pointer;
  border-bottom: 2px solid transparent;
  border-width: 0px;
}

.tab:hover {
  /* box-shadow: 0px 0px 4px gainsboro; */
  border-bottom: 2px solid dodgerblue;
  border-width: 100%;
  /* transition: 0.2s ease-in-out; */
}

input[type="number"]:disabled {
  background: white;
}

::v-deep .vdp-datepicker__calendar-button .input-group-text {
  height: 100%;
  background-color: whitesmoke;
  border-top-right-radius: 0;
  border-right: 0px;
  border-bottom-right-radius: 0;
}
::v-deep .vdp-datepicker__clear-button .input-group-text {
  height: 100%;
  background-color: white;
  border-top-left-radius: 0;
  border-left: 0px;
  border-bottom-left-radius: 0;
  border-color: #f0f0f0;
}
/* .slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 0.4s ease;
}
.slide-fade-enter,
.slide-fade-leave-to {
  opacity: 0;
  transform: translateY(10px);
} */
</style>
