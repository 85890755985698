<template>
  <div class="mx-3 my-4">
    <div v-if="currentUser.user_role == 1">
      <div
        class="d-flex flex-md-row flex-column justify-content-between align-items-md-center pb-3 mt-3 gap-md-0 gap-3"
      >
        <div>
          <h3 class="text-dark">Vendor</h3>
          <ol class="breadcrumb m-0">
            <li class="breadcrumb-item">
              <router-link :to="{ name: 'DashboardContent' }" tag="a">
                Dashboard
              </router-link>
            </li>
            <li class="breadcrumb-item active">Vendor</li>
          </ol>
        </div>
        <button
          class="btn btn-primary btn-sm ms-md-auto me-md-0 me-auto"
          @click="vendorModalHandler('add')"
        >
          Add Vendor
        </button>
      </div>
      <redmineFilter
        ref="redmineFilter"
        page="vendor"
        @redmine-filter-data="redmineFilterHandler"
        @reset-server-params="onResetServerParams"
        :serverParams="serverParams"
      ></redmineFilter>
      <div class="card shadow-sm border-0 my-4">
        <div class="card-body">
          <div class="p-2">
            <vue-good-table
              styleClass="vgt-table no-border"
              mode="remote"
              @on-page-change="onPageChange"
              @on-per-page-change="onPerPageChange"
              @on-sort-change="onSortChange"
              @on-column-filter="onColumnFilter"
              :columns="vendor_columns"
              :rows="vendor_rows"
              :totalRows="total_records"
              :pagination-options="{
                enabled: true,
                perPageDropdown: perpage_list,
              }"
            >
              <template slot="table-row" slot-scope="props">
                <span
                  v-if="
                    props.column.field == 'address' && props.row.address == null
                  "
                  >-</span
                >
                <span
                  v-else-if="
                    props.column.field == 'bank_name' &&
                    props.row.bank_name == null
                  "
                  >-</span
                >
                <span
                  class="table-icon d-flex justify-content-center align-items-center gap-3 p-2"
                  v-else-if="props.column.field == 'action'"
                >
                  <button
                    class="btn-pencil"
                    @click="vendorModalHandler('update', props.row.id)"
                  >
                    <i class="fa fa-pencil fs-5"></i>
                  </button>
                  <button class="btn-trash" @click="deleteVendor(props.row.id)">
                    <i class="fa fa-trash fs-5"></i>
                  </button>
                </span>
              </template>
              <template slot="pagination-bottom" slot-scope="props">
                <CustomPagination
                  class="p-2"
                  :pageTabs="4"
                  :total="props.total"
                  :pageChanged="props.pageChanged"
                  :perPageChanged="props.perPageChanged"
                  :serverParams="serverParams"
                ></CustomPagination>
              </template>
            </vue-good-table>
          </div>
        </div>
      </div>
      <!-- Add,update -->
      <div
        class="modal fade"
        id="vendorModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="vendorModal"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h3
                class="text-dark m-0"
                id="vendorModal"
                v-text="modal_obj.title"
              ></h3>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="close"
              ></button>
            </div>
            <div class="modal-body">
              <form @submit.prevent="submitFormHandler">
                <div class="row no-gutters">
                  <div class="col-md-6 col-12 mb-4">
                    <div class="">
                      <label for="vendor_name" class="form-label mandatory">
                        Vendor Name
                      </label>
                      <input
                        type="text"
                        id="vendor_name"
                        class="form-control"
                        placeholder="Enter the vendor name"
                        required
                        v-model="reqObj.name"
                      />
                    </div>
                  </div>
                  <div class="col-md-6 col-12 mb-4">
                    <div class="">
                      <label for="gst_no" class="form-label mandatory">
                        GST No.
                      </label>
                      <input
                        type="text"
                        id="gst_no"
                        required
                        class="form-control"
                        placeholder="Enter GST number"
                        maxlength="15"
                        v-model="reqObj.GST_NO"
                      />
                      <small
                        class="text-danger"
                        v-text="error_msg.gst_no"
                      ></small>
                    </div>
                  </div>
                  <div class="col-12 mb-4">
                    <div class="">
                      <label for="vendor_address" class="form-label">
                        Vendor Address
                      </label>
                      <textarea
                        class="form-control"
                        id="vendor_address"
                        cols="30"
                        rows="5"
                        placeholder="Enter the vendor address"
                        v-model="reqObj.address"
                      ></textarea>
                    </div>
                  </div>
                  <div class="col-md-6 col-12 mb-4">
                    <div class="pr-3">
                      <label for="bank_name" class="form-label">
                        Bank Name
                      </label>
                      <input
                        type="text"
                        id="bank_name"
                        class="form-control"
                        placeholder="Enter the bank name"
                        v-model="reqObj.bank_name"
                      />
                    </div>
                  </div>
                  <div class="col-md-6 col-12 mb-4">
                    <div class="">
                      <label for="account_no" class="form-label mandatory">
                        Account No.
                      </label>
                      <input
                        type="text"
                        id="account_no"
                        class="form-control"
                        placeholder="Enter the account no."
                        required
                        v-model="reqObj.account_no"
                      />
                      <small
                        class="text-danger"
                        v-text="error_msg.account_no"
                      ></small>
                    </div>
                  </div>
                  <div class="col-md-6 col-12 mb-4 pr-3">
                    <div class="">
                      <label for="contact_no" class="form-label mandatory">
                        Contact No.
                      </label>
                      <input
                        type="tel"
                        id="contact_no"
                        required
                        class="form-control"
                        placeholder="Enter contact number"
                        v-model="reqObj.contact_no"
                      />
                      <small
                        class="text-danger"
                        v-text="error_msg.contact_no"
                      ></small>
                    </div>
                    <!-- pattern="[0-9]{10}" -->
                  </div>
                </div>
                <div
                  class="d-flex justify-content-end align-items-center gap-2"
                >
                  <button
                    class="btn btn-primary"
                    type="submit"
                    v-if="modal_obj.button == 'Save'"
                  >
                    Save
                  </button>
                  <button class="btn btn-primary" type="submit" v-else>
                    Update
                  </button>
                  <button
                    class="btn btn-outline-secondary"
                    data-bs-dismiss="modal"
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <h2 class="text-muted">Unauthorized access</h2>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import user from "@/ajax/user";
import VueGoodTable from "vue-good-table";
import { mapState, mapActions } from "vuex";
import CustomPagination from "../../components/CustomPagination.vue";
import myMixin from "@/mixins/Permission";
import redmineFilter from "../../components/redmine_filter.vue";
Vue.use(VueGoodTable);
export default {
  mixins: [myMixin],
  computed: {
    ...mapState("userStore", ["currentUser"]),
  },
  components: {
    CustomPagination,
    redmineFilter,
  },
  data() {
    return {
      siteLogReq: {
        site_name: "vendor",
      },
      reqObj: {
        name: null, //mandatory
        address: null,
        GST_NO: null,
        account_no: null,
        bank_name: null,
        contact_no: null,
      },
      modal_obj: {
        title: "Add Vendor",
        button: "Save",
      },
      error_msg: {
        gst_no: "",
        contact_no: "",
        account_no: "",
      },
      vendor_columns: [
        {
          label: "Vendor Name",
          field: "name",
          filterable: true,
          thClass: "custom-th",
          tdClass: "custom-td",
          filterOptions: {
            enabled: false,
            trigger: "enter",
          },
        },
        {
          label: "Vendor Address",
          field: "address",
          filterable: true,
          thClass: "custom-th",
          tdClass: "custom-td",
          filterOptions: {
            enabled: false,
            trigger: "enter",
          },
        },
        {
          label: "GST No.",
          field: "GST_NO",
          filterable: true,
          thClass: "custom-th",
          tdClass: "custom-td",
          filterOptions: {
            enabled: false,
            trigger: "enter",
          },
        },
        {
          label: "Bank name",
          field: "bank_name",
          filterable: true,
          thClass: "custom-th",
          tdClass: "custom-td",
          filterOptions: {
            enabled: false,
            trigger: "enter",
          },
        },
        {
          label: "Account No",
          field: "account_no",
          filterable: true,
          thClass: "custom-th",
          tdClass: "custom-td",
          filterOptions: {
            enabled: false,
            trigger: "enter",
          },
        },
        {
          label: "Contact No",
          field: "contact_no",
          filterable: true,
          thClass: "custom-th",
          tdClass: "custom-td",
          filterOptions: {
            enabled: false,
            trigger: "enter",
          },
        },
        {
          label: "Action",
          field: "action",
          sortable: false,
          thClass: "custom-th",
          tdClass: "custom-td text-nowrap",
        },
      ],
      serverParams: {
        filters: {},
        page: 1,
        perpage: 100,
        sort: [
          {
            field: "",
            type: "asc",
          },
        ],
      },
      total_records: null,
      vendor_rows: [],
      perpage_list: [100, 200, 300, 400, 500],
    };
  },
  mounted() {
    this.setCurrentPage("Assets");
    $("#vendorModal").on("hidden.bs.modal", this.resetModal);
    this.getUserPermissions();
    // this.getVendors();
  },
  created() {
    user.createSiteUsage(this.siteLogReq);
  },
  methods: {
    ...mapActions("common", ["setCurrentPage"]),
    vendorModalHandler(action, id) {
      if (action == "add") {
        this.modal_obj.title = "Add Vendor";
        this.modal_obj.button = "Save";
        $("#vendorModal").modal("show");
      }
      if (action == "update") {
        this.modal_obj.title = "Update Vendor";
        this.modal_obj.button = "Update";
        this.getVendorById(id);
        $("#vendorModal").modal("show");
      }
    },
    onColumnFilter(params) {
      var newObj = { filters: Object.assign({}) };
      Object.keys(params.columnFilters).forEach(function (key) {
        if (params.columnFilters[key] !== "")
          newObj.filters[key] = params.columnFilters[key];
      });
      this.updateParams(newObj);
      this.serverParams.page = 1;
      this.getVendors();
    },
    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },
    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      this.getVendors();
      // this.getAllProjectscolumnfilter();
    },

    onPerPageChange(params) {
      this.updateParams({ perpage: params.currentPerPage });
      this.serverParams.page = 1;
      this.getVendors();
      // this.getAllProjectscolumnfilter();
    },
    onSortChange(params) {
      this.updateParams({
        sort: [
          {
            type: params[0].type,
            field: params[0].field,
          },
        ],
      });
      this.getVendors();
      // this.getAllProjectscolumnfilter();
    },
    resetModal() {
      this.reqObj = Object.assign(
        {},
        {
          name: null,
          address: null,
          GST_NO: null,
          account_no: null,
          bank_name: null,
          contact_no: null,
        }
      );
      this.error_msg = Object.assign(
        {},
        {
          gst_no: "",
          contact_no: "",
          account_no: "",
        }
      );
    },
    addVendor() {
      this.error_msg = Object.assign(
        {},
        {
          gst_no: "",
          contact_no: "",
          account_no: "",
        }
      );
      for (let item in this.reqObj) {
        if (this.reqObj[item] == "") {
          this.reqObj[item] = null;
        }
      }
      user
        .addVendor(this.reqObj)
        .then((res) => {
          if (res.data.code == 200) {
            Swal.fire({
              title: res.data.message,
              icon: "success",
            });
            this.getVendors();
          }
          $("#vendorModal").modal("hide");
        })
        .catch((err) => {
          if (Object.keys(err.response.data.detail).includes("detail")) {
            Swal.fire({
              title: err.response.data.detail,
              icon: "error",
            });
          } else {
            let key = Object.keys(err.response.data.detail)[0];
            this.error_msg[key] = err.response.data.detail[key];
          }
        });
    },
    submitFormHandler() {
      if (this.modal_obj.button == "Save") {
        this.addVendor();
      } else {
        this.updateVendor();
      }
    },
    updateVendor() {
      this.error_msg = Object.assign(
        {},
        {
          gst_no: "",
          contact_no: "",
          account_no: "",
        }
      );
      for (let item in this.reqObj) {
        if (this.reqObj[item] == "") {
          this.reqObj[item] = null;
        }
      }
      user
        .updateVendor(this.reqObj)
        .then((res) => {
          if (res.data.code == 200) {
            Swal.fire({
              title: res.data.message,
              icon: "success",
            });
            this.getVendors();
          }
          if (res.data.code == 400) {
            Swal.fire({
              title: res.data.message,
              icon: "error",
            });
          }
          $("#vendorModal").modal("hide");
        })
        .catch((err) => {
          if (Object.keys(err.response.data.detail).includes("detail")) {
            Swal.fire({
              title: err.response.data.detail,
              icon: "error",
            });
          } else {
            let key = Object.keys(err.response.data.detail)[0];
            this.error_msg[key] = err.response.data.detail[key];
          }
        });
    },
    deleteVendor(id) {
      Swal.fire({
        title: "Do you want to delete vendor ?",
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        icon: "warning",
      }).then((res) => {
        if (res.isConfirmed) {
          user
            .deleteVendor(id)
            .then((res) => {
              if (res.data.code == 200) {
                Swal.fire({
                  title: res.data.message,
                  icon: "success",
                });
                this.getVendors();
              }
            })
            .catch((err) => {
              Swal.fire({
                title: err.response.data.detail,
                icon: "error",
              });
            });
        }
      });
    },
    redmineFilterHandler() {
      this.getVendors();
    },
    onResetServerParams(serverParams) {
      this.serverParams = Object.assign({}, serverParams);
      this.$nextTick(() => {
        this.$refs.redmineFilter.applyFilter();
      });
    },
    getVendors() {
      user.getVendors(this.serverParams).then((res) => {
        this.vendor_rows = [];
        this.vendor_rows = res.data.data.data;
        this.total_records = res.data.data.total;
      });
    },
    getVendorById(id) {
      user.getVendorById(id).then((res) => {
        this.reqObj = res.data.data;
      });
    },
  },
};
</script>

<style scoped>
.modal-dialog {
  max-width: 70rem;
}
.mandatory:after {
  content: "*";
  padding: 0px 0px;
  color: red;
}
#vgt-table {
  text-align: center;
}
</style>
