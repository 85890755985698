<template>
  <div class="">
    <div class="card shadow-sm border-0 my-4">
      <div class="card-body">
        <div
          class="d-flex align-items-center justify-content-md-between justify-content-center gap-md-0 gap-2 flex-md-nowrap flex-wrap px-2 pb-3"
        >
          <h4 class="m-0 font-weight-bold text-primary">
            Monthly Attendance Report
          </h4>
          <form @submit.prevent="getUserReportsByDate()">
            <div class="d-flex justify-content-center gap-2 align-items-center">
              <button
                class="btn btn-primary btn-sm rounded-end"
                @click="ChangeDate('pre')"
              >
                <i class="fa fa-arrow-left"></i>
              </button>

              <datepicker
                :minimumView="'month'"
                :maximumView="'year'"
                v-model="reqObj.selected_date"
                :disabled-dates="{
                  from: new Date(),
                }"
                :format="'yyyy-MM'"
                :bootstrap-styling="true"
                @input="getUserReportsByDate"
              >
              </datepicker>

              <button
                class="btn btn-primary btn-sm rounded-start"
                @click="ChangeDate('next')"
              >
                <i class="fa fa-arrow-right"></i>
              </button>
            </div>
          </form>
        </div>
        <div>
          <transition name="fade" mode="out-in">
            <div
              key="spinner"
              v-if="this.rows.length == 0"
              class="container-fluid m-0 p-5 d-flex justify-content-center align-items-center"
            >
              <div
                class="spinner-border"
                role="status"
                style="height: 3rem; width: 3rem; color: #4e73df"
              >
                <span class="sr-only">Loading...</span>
              </div>
            </div>
            <div class="p-md-2" key="monthly-attendance" v-else>
              <!-- <newNC :some="rows"></newNC> -->
              <!-- Here is the spinner -->

              <div class="container-fluid m-0 p-0">
                <!-- <div
                class="d-flex align-items-center justify-content-md-between justify-content-center gap-md-0 gap-2 flex-md-nowrap flex-wrap"
               > -->

                <div class="row g-0">
                  <div class="col-md-2 col-12">
                    <h5 v-text="getMonthName" class="text-primary mb-0"></h5>
                  </div>
                  <!-- <div
                class="d-flex align-items-center justify-content-md-between justify-content-center gap-2 flex-md-nowrap flex-wrap col-md-8"
                > -->
                  <div
                    class="mt-md-0 mt-3 col-lg-10 col-12 d-flex align-items-center justify-content-md-between justify-content-center gap-2 flex-md-nowrap flex-wrap"
                  >
                    <multiselect
                      v-model="reqObj.filtered_users"
                      :hide-selected="true"
                      :options="active_users_list"
                      :multiple="true"
                      placeholder="Select one or more User"
                      @search-change="customFilterHandler"
                      label="email"
                      track-by="id"
                      :custom-label="custom_label"
                      group-label="label"
                      group-values="users_data"
                      :group-select="true"
                    >
                      <template #option="props">
                        {{
                          Object.keys(props.option).includes("$groupLabel")
                            ? props.option["$groupLabel"]
                            : props.option.middle_name != null
                            ? `${props.option.first_name} ${props.option.middle_name}
                    ${props.option.last_name}`
                            : `${props.option.first_name} ${props.option.last_name}`
                        }}
                      </template>
                    </multiselect>

                    <multiselect
                      v-model="reqObj.user_type"
                      :hide-selected="true"
                      :options="user_types"
                      :multiple="true"
                      placeholder="User Type"
                      track-by="id"
                      label="name"
                      group-label="label"
                      group-values="user_type"
                      :group-select="true"
                    >
                    </multiselect>

                    <multiselect
                      v-model="reqObj.report_value"
                      :close-on-select="true"
                      :options="report_options"
                      :multiple="false"
                      placeholder="Reporting"
                      :preselect-first="true"
                    >
                    </multiselect>

                    <multiselect
                      v-model="reqObj.user_organization"
                      :hide-selected="true"
                      :options="user_organizations"
                      :multiple="true"
                      placeholder="Organization"
                      label="name"
                      track-by="id"
                      group-label="label"
                      group-values="user_organization"
                      :group-select="true"
                    >
                    </multiselect>

                    <button
                      @click="getUserReportsByDate"
                      class="btn btn-primary"
                    >
                      Filter
                    </button>
                  </div>
                </div>
                <div
                  class="d-flex flex-lg-row flex-column justify-content-between align-items-center mt-3 mb-lg-0 mb-3"
                >
                  <div
                    class="d-flex justify-content-center align-items-center gap-3 mb-lg-0 mb-2"
                    style="font-size: 12px"
                  >
                    <div
                      class="d-flex flex-column justify-content-start align-items-center mb-2"
                    >
                      <small>Time Spent</small>
                      <hr class="p-0 m-0 w-100" />
                      <small>Approved Hours</small>
                    </div>
                    <div class="mb-2">
                      <div class="hstack gap-1">
                        <div
                          style="height: 10px; width: 10px"
                          class="bg-success"
                        ></div>
                        <small>Approved Hours = Per Day Hours</small>
                      </div>
                      <div class="hstack gap-1">
                        <div
                          style="height: 10px; width: 10px"
                          class="bg-danger mb-3"
                        ></div>
                        <small
                          >Approved Hours < Per Day Hours <b>or</b><br />
                          Approved Hours > Time Spent</small
                        >
                      </div>
                    </div>
                  </div>
                  <h6 class="fw-normal" v-if="showData">
                    <b>WD : </b>Working Days | <b>P : </b>Present |
                    <b>OB : </b>Opening Balance | <b>L : </b>Leave |
                    <b>CB : </b>Closing Balance
                  </h6>
                </div>
              </div>
              <scroll-table
                :monthly_report="rows"
                v-if="Object.keys(rows).length != 0 && showData"
              ></scroll-table>
              <div v-if="!showData" class="p-5 text-center">
                <h4>No data available</h4>
              </div>
            </div>
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import user from "@/ajax/user";
import { mapState, mapActions } from "vuex";
import Vue from "vue";
import axios from "axios";
import UnauthorizedPage from "@/views/Layouts/UnauthorizedPage.vue";
import myMixin from "@/mixins/Permission";
import Datepicker from "vuejs-datepicker";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import Multiselect from "vue-multiselect";
import ScrollTable from "../../components/ScrollTable.vue";
import { utils, writeFileXLSX } from "xlsx-js-style";

import autoTable from "jspdf-autotable";
import { jsontoexcel } from "vue-table-to-excel";
import moment from "moment";
moment.locale("en");
Vue.use(VueSweetalert2);

// import VueAxios from 'vue-axios';
// Vue.use(VueAxios,axios)
export default {
  name: "MonthlyAttendance",
  mixins: [myMixin],
  components: {
    UnauthorizedPage,
    Multiselect,
    Datepicker,
    flatPickr,
    ScrollTable,
  },
  data: function () {
    return {
      reqObj: {
        selected_date: new Date().toISOString().slice(0, 10),
        filtered_users: [],
        report_value: "",
        user_type: [],
        user_organization: [],
      },
      siteLogReq: {
        site_name: "monthly_attendance",
      },
      users: [],
      active_users_list: [],
      user_types: [],
      user_organizations: [],
      report_options: ["YES", "NO"],
      permissions: window.localStorage.getItem("permissions"),
      currentPage: "",
      isLoading: false,
      columns: [
        {
          label: "Image",
          field: "image",
          sortable: true,
          filterable: true,
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Quote Image", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.onColumnFilter, //custom filter function that
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Text",
          field: "text",
          sortable: true,
          filterable: true,
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Quote Text", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.onColumnFilter, //custom filter function that
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Date",
          field: "date",
          sortable: true,
          filterable: true,
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Display Date", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.onColumnFilter, //custom filter function that
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Action",
          field: "action",
          sortable: false,
          thClass: "disable-sorting",
        },
      ],
      rows: [],
      totalRecords: 0,
      serverParams: {
        columnFilters: {},
        sort: [
          {
            field: "",
            type: "asc",
          },
        ],
        page: 1,
        perPage: 10,
      },
      showData: false,
    };
  },
  mounted() {
    this.setCurrentPage("Attendance");
    this.getUserReportsByDate();
    this.getUserPermissions();
    this.getUsers();
    this.getUserTypeDropdown();
    this.getUserOrganizationDropdown();
    // if(this.permissions.includes('quotes.list')){
    //     this.getAllQuotes();
    //     this.getAllQuotesPages();
    //   }
  },
  methods: {
    ...mapActions("userStore", ["setCurrentUser", "logoutUser"]),
    ...mapActions("common", ["setCurrentPage"]),
    customFilterHandler(search_query, id) {
      if (search_query) {
        let search_query_array = search_query.split(" ").filter((e) => e);
        let fname, lname, mname;
        let temp = this.active_users_list[0]["users_data"].filter((item) => {
          if (search_query_array.length == 1) {
            [fname] = search_query_array;
            return item.first_name.toLowerCase().includes(fname);
          } else if (search_query_array.length == 2) {
            [fname, lname] = search_query_array;
            // console.log(item.first_name.toLowerCase().includes(fname), item.last_name.toLowerCase().includes(lname));
            return (
              item.first_name.toLowerCase().includes(fname) &&
              item.last_name.toLowerCase().includes(lname)
            );
          } else {
            [fname, mname, lname] = search_query_array;
            return (
              item.first_name.toLowerCase().includes(fname) &&
              item.middle_name.toLowerCase().includes(mname) &&
              item.last_name.toLowerCase().includes(lname)
            );
          }
        });
        // this.active_users_list[0]["users_data"] = temp;
      } else {
        this.userListFilter();
      }
      // console.log(this.active_users_list);
    },
    //Excel sheet generator
    excelDownload() {
      setTimeout(() => {
        // this.excel.data = [];
        const arr = [];
        const wb = utils.book_new();
        const data = this.rows;
        arr.push([
          "Working Days",
          "Present",
          "Opening Balance",
          "Leave",
          "Closing Balance",
        ]);
        for (const user in this.rows) {
          for (const i in this.rows[user]) {
            // user.forEach((obj, index) => {
            // console.log(
            //   "----- ",
            //   user[i],
            //   Object.getOwnPropertyNames(this.rows).length
            // );
          }
          // });
        }
        var ws2 = utils.aoa_to_sheet(arr);
        // ws2["!cols"] = [
        //   { width: 11 },
        //   { width: 15 },
        //   { width: 15 },
        //   { width: 18 },
        //   { width: 8 },
        //   { width: 45 },
        //   { width: 45 },
        //   { width: 10 },
        // ];
        utils.book_append_sheet(wb, ws2, "test");

        // download excel
        // writeFileXLSX(
        //   wb,
        //   new Date().toISOString().substring(0, 10) + ".xlsx"
        // );
      }, 500);
    },

    custom_label(name) {
      // console.log(name.first_name,name.middle_name,name.last_name);
      // if (name.last_name != null && name.middle_name != null) {
      //   return `${name.first_name} ${name.middle_name} ${name.last_name}`;
      // } else if (name.last_name == null) {
      //   return `${name.first_name} ${name.middle_name}`;
      // } else if (name.middle_name == null) {
      //   return `${name.first_name} ${name.last_name}`;
      //   }
      // return `${name.first_name} ${name.middle_name} ${name.last_name}`;
      return `${name.first_name} ${name.last_name}`;
    },
    getUsers() {
      user.getUsers().then((result) => {
        this.users = [{ label: "Select all", users_data: result.data.data }];
        this.active_users_list = this.users.slice();
        this.userListFilter();
      });
    },
    getUserTypeDropdown() {
      user.getUserTypeDropdown().then((result) => {
        this.user_types = [
          { label: "Select all", user_type: result.data.data },
        ];
        // console.log("user type--------",this.user_types)
      });
    },
    getUserOrganizationDropdown() {
      user.getListOrganization().then((result) => {
        this.user_organizations = [
          { label: "Select all", user_organization: result.data.data },
        ];
        // console.log("user type--------",this.user_types)
      });
    },
    //filters of attendance
    getUserReportsByDate() {
      // console.log(this.reqObj.report_value);
      this.showData = true;
      let type = this.reqObj.user_type;
      let organization = this.reqObj.user_organization;

      if (this.reqObj.user_type.length !== 0) {
        this.reqObj.user_type = this.reqObj.user_type.map((item) => item.id);
      }

      if (this.reqObj.user_organization.length !== 0) {
        this.reqObj.user_organization = this.reqObj.user_organization.map(
          (item) => item.id
        );
      }

      this.rows = [];
      let tempObj = {
        selected_date: moment(this.reqObj.selected_date).format("yyyy-MM-DD"),
        filtered_users: this.reqObj.filtered_users.map((item) => item.id),
        report_value: this.reqObj.report_value,
        user_type: this.reqObj.user_type,
        user_organization: this.reqObj.user_organization,
      };
      user.getUserReportsByDate(tempObj).then((result) => {
        this.rows = result.data.data;
        let length = Object.values(result.data.data).length - 1;
        this.showData = Object.values(result.data.data)
          .slice(0, length - 6)
          .every((item) => item.length > 0);
        // console.log(this.showData);
      });

      this.reqObj.user_type = type;
      this.reqObj.user_organization = organization;
    },
    userListFilter() {
      let temp = [];
      // console.log(this.users[0]['users_data']);
      this.users[0]["users_data"].forEach((item) => {
        // console.log("doe ",item.doe);
        if (item.doe !== null) {
          // console.log("if-- ",this.reqObj.searched_date);
          if (
            moment(this.reqObj.selected_date).format("yyyy-MM") <=
            moment(item.doe).format("yyyy-MM")
          ) {
            temp.push(item);
          }
        } else if (item.doe == null && item.is_deleted != true) {
          temp.push(item);
        } else if (item.doe == null && item.is_deleted != true) {
          temp.push(item);
        }
      });
      // console.log(temp);
      this.active_users_list = [{ label: "Select all", users_data: temp }];
    },
    ChangeDate(val) {
      if (val == "pre") {
        let get_date = new Date(this.reqObj.selected_date);
        this.reqObj.selected_date = moment(
          new Date(get_date.setMonth(get_date.getMonth() - 1))
        ).format("yyyy-MM-DD");
      }
      if (val == "next") {
        let get_date = new Date(this.reqObj.selected_date);
        if (
          new Date().toISOString().slice(0, 10) >=
          moment(new Date(get_date.setMonth(get_date.getMonth() + 1))).format(
            "yyyy-MM-DD"
          )
        ) {
          let get_date = new Date(this.reqObj.selected_date);
          this.reqObj.selected_date = moment(
            new Date(get_date.setMonth(get_date.getMonth() + 1))
          ).format("yyyy-MM-DD");
        }
      }
      this.userListFilter();
    },
  },
  created() {
    user.createSiteUsage(this.siteLogReq);
  },
  computed: {
    ...mapState("userStore", ["currentUser"]),
    getMonthName() {
      let months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      let month_num = new Date(this.reqObj.selected_date).getMonth();
      let year = new Date(this.reqObj.selected_date).getFullYear();
      return months[month_num] + "\t" + year;
    },
  },
};
</script>
<style scoped>
.startbuttons {
  padding: 5px 30px 5px 30px;
  margin: 10px;
  font-size: 20px;
}

.disableButton {
  opacity: 0.5;
  pointer-events: none;
}
.fade-enter-active,
.fade-leave-action {
  transition: opacity 0.3s ease;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
