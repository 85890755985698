<template>
  <!-- Begin Page Content -->
  <div class="mt-4 mx-3">
    <div
      class="d-flex flex-md-row flex-column justify-content-between align-items-md-center pb-3"
    >
      <div>
        <h3>Users</h3>
        <ol class="breadcrumb m-0">
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'DashboardContent' }" tag="a">
              Dashboard
            </router-link>
          </li>
          <li class="breadcrumb-item active">Users</li>
        </ol>
      </div>
      <div
        class="d-flex gap-2 mt-md-0"
        :class="currentUser.user_role == 1 ? 'mt-3' : ''"
      >
        <router-link
          class="btn btn-sm btn-primary"
          :to="'/add-user'"
          v-bind:class="[currentPage == 'AddUser' ? 'active' : '', 'nav-item']"
          v-if="
            permissions.includes('users.write') &&
            permissions.includes('users.add')
          "
        >
          Add User
        </router-link>
        <button
          v-if="permissions.includes('user_list.export')"
          class="btn btn-outline-primary btn-sm"
          @click="exportUserList"
        >
          Export
        </button>
      </div>
    </div>
    <redmineFilter
      ref="redmineFilter"
      page="users"
      :serverParams="serverParams"
      @redmine-filter-data="redmineFilterHandler"
    ></redmineFilter>
    <div
      class="card shadow-sm border-0 mb-4 mt-4"
      v-if="permissions.includes('users.read')"
    >
      <div class="card-body">
        <div
          class="d-flex align-items-center justify-content-md-between justify-content-center flex-md-nowrap flex-wrap gap-md-0 gap-2 px-2 pb-3"
        >
          <!-- for old user page  :to="'/old-add-user'" -->
          <!-- <div
            class="btn-group"
            role="group"
            style="width: 15rem"
            v-if="currentUser.user_role == 1"
          > -->
          <!-- <router-link
              :to="{ name: 'Users' }"
              class="btn btn-sm"
              :class="
                this.$route.name == 'Users'
                  ? 'btn-primary'
                  : 'btn-outline-primary bg-white text-primary'
              "
              v-if="permissions.includes('users.read')"
            >
              <div
                v-if="
                  permissions.includes('users.read') &&
                  permissions.includes('users.enable')
                "
              >
                Active
              </div>
            </router-link> -->
          <!-- <router-link
              :to="{ name: 'DeletedUsers' }"
              class="btn btn-sm"
              :class="
                this.$route.name == 'DeletedUsers'
                  ? 'btn-primary'
                  : 'btn-outline-primary bg-white text-primary'
              "
              v-if="
                permissions.includes('users.read') &&
                permissions.includes('users.enable')
              "
            >
              Inactive
            </router-link> -->
          <!-- Hide due to unresponsive graphical view -->
          <!-- <router-link
            :to="{ name: 'Hierarchy' }"
            class="btn rounded-0"
            :class="
              this.$route.name == 'Hierarchy'
                ? 'btn-primary'
                : 'btn-outline-primary bg-white text-primary'
            "
            v-if="permissions.includes('hierarchy.read')"

          >
            Graphical View
          </router-link> -->
          <!-- <router-link
              :to="{ name: 'HierarchyFileView' }"
              class="btn rounded-0"
              :class="
                this.$route.name == 'HierarchyFileView'
                  ? 'btn-primary'
                  : 'btn-outline-primary bg-white text-primary'
              "
              v-if="permissions.includes('hierarchy.read')"
            >
              File View
            </router-link> -->
          <!-- </div> -->
        </div>
        <div v-if="permissions.includes('users.read')" class="p-2">
          <div class="table-responsive">
            <vue-good-table
              mode="remote"
              @on-page-change="onPageChange"
              @on-sort-change="onSortChange"
              @on-column-filter="onColumnFilter"
              @on-per-page-change="onPerPageChange"
              :totalRows="totalRecords"
              :isLoading.sync="isLoading"
              :dropdownAllowAll="false"
              :pagination-options="{
                enabled: true,
                perPageDropdown: perpage_list,
              }"
              :rows="rows"
              :columns="columns"
              styleClass="vgt-table"
            >
              <template
                slot="column-filter"
                slot-scope="{ column, updateFilters }"
              >
                <span v-if="column.field == 'login_slot'">
                  <datepicker
                    :clear-button="true"
                    :clear-button-icon="'fa fa-times'"
                    placeholder="yyyy-mm-dd"
                    :format="formateDate"
                    @input="
                      (e) =>
                        updateFilters(
                          column,
                          e
                            ? moment(e).format('YYYY-MM-DD')
                            : updateFilters(column, '')
                        )
                    "
                  />
                </span>
              </template>
              <template slot="table-row" scope="props">
                <span v-if="props.column.label == 'Action'">
                  <tr>
                    <div
                      v-if="columns != inactiveColumns"
                      class="d-flex justify-content-center align-items-center p-2 gap-3"
                    >
                      <router-link
                        :to="'/prof/' + props.row.id"
                        tag="button"
                        class="btn-view"
                      >
                        <i class="fa fa-eye fs-5"></i>
                      </router-link>
                      <button
                        class="btn-trash"
                        @click="delete_user(props.row.id, props.row.doe)"
                        v-if="
                          permissions.includes('users.delete') &&
                          props.row.id != currentUser.id
                        "
                      >
                        <i class="fa fa-user-times fs-5"></i>
                      </button>

                      <!-- <td
                        v-if="
                          permissions.includes('users.delete') &&
                          props.row.id != currentUser.id
                        "
                        class="no-border-td-tr"
                      >
                        <i
                          @click="delete_user(props.row.id,props.row.doe)"
                          class="btn btn-danger"
                          >De-Activiate</i
                        >
                      </td> -->
                    </div>
                    <div
                      class="d-flex justify-content-center align-items-center gap-3 p-2"
                      v-else
                    >
                      <router-link
                        :to="'/prof/' + props.row.id"
                        v-if="permissions.includes('users.read')"
                        class="btn btn-view"
                        tag="button"
                      >
                        <i class="fa fa-eye fs-5"></i>
                      </router-link>
                      <button
                        class="btn btn-add"
                        v-if="permissions.includes('users.enable')"
                        @click="make_user_active(props.row.id)"
                      >
                        <i class="fa fa-user fs-5"></i>
                      </button>
                    </div>
                  </tr>
                </span>
                <span v-else-if="props.column.field == 'manager_of_projects'">
                  <readMore
                    more-str="Show more"
                    :text="props.row.manager_of_projects"
                    less-str="Show less"
                    :max-chars="50"
                    class="p-1"
                  >
                  </readMore>
                </span>
              </template>

              <template
                slot="column-filter"
                slot-scope="{ column, updateFilters }"
              >
                <span v-if="column.field == 'doj'">
                  <datepicker
                    :clear-button="true"
                    :format="formateDate"
                    placeholder="yyyy-mm-dd"
                    @input="
                      (e) =>
                        updateFilters(column, e.toISOString().substring(0, 10))
                    "
                  />
                </span>
              </template>

              <template slot="pagination-bottom" slot-scope="props">
                <CustomPagination
                  class="p-2"
                  :pageTabs="4"
                  :serverParams="serverParams"
                  :total="props.total"
                  :pageChanged="props.pageChanged"
                  :perPageChanged="props.perPageChanged"
                ></CustomPagination>
              </template>
            </vue-good-table>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- View Modal -->
  <!-- <v-modal name="view">
      <Profile></Profile>``      <time-picker-vue :config="time"></time-picker-vue>
    </v-modal> -->
  <!-- End of Main Content -->
</template>
<script>
import UnauthorizedPage from "@/views/Layouts/UnauthorizedPage.vue";
import user from "@/ajax/user";
import { mapState, mapActions } from "vuex";
import Vue from "vue";
import myMixin from "@/mixins/Permission";
import Datepicker from "vuejs-datepicker";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import moment from "moment";
import redmineFilter from "../../components/redmine_filter.vue";
import CustomPagination from "../../components/CustomPagination.vue";
moment.locale("en");
Vue.use(VueSweetalert2);
// Vue.use(VModal, { componentName: "v-modal" });

// import VueAxios from 'vue-axios';
// Vue.use(VueAxios,axios)

export default {
  name: "Users",
  mixins: [myMixin],
  components: { UnauthorizedPage, Datepicker, redmineFilter, CustomPagination },
  data: function () {
    return {
      time: { hours: null, minutes: null },
      showDialog: true,
      reqObj: {},
      permissions: window.localStorage.getItem("permissions"),

      breakArr: [
        { value: "lunch", text: "Lunch" },
        { value: "other", text: "Other" },
      ],
      siteLogReq: {
        site_name: "active_users",
      },
      currentPage: "",
      isLoading: false,
      // Reporting To (merge first + firstchar of middle + lastname) | Name (merge first + firstchar of middle + lastname) |  DoJ | UserRole | UserType | Login Slot
      columns: [],
      activeColumns: [
        {
          label: "Name",
          // field: this.concatNames,
          field: "full_name",
          sortable: true,
          width: "12%",
          thClass: "custom-th",
          tdClass: "custom-td",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "user name", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.onColumnFilter, //custom filter function that
            ////trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Reporting To",
          // field: this.reportToconcatNames,
          field: "rt_name",
          sortable: true,
          width: "12%",
          thClass: "custom-th",
          tdClass: "custom-td",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "reporting to", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.onColumnFilter, //custom filter function that
            ////trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Date Of Joining",
          field: "doj",
          sortable: true,
          width: "12%",
          thClass: "custom-th",
          tdClass: "custom-td",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "date of joining", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.onColumnFilter, //custom filter function that
            ////trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Designation",
          field: "designation",
          sortable: true,
          width: "8%",
          thClass: "custom-th",
          tdClass: "custom-td",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "user type", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.onColumnFilter, //custom filter function that
            ////trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "UserRole",
          field: "user_role_name",
          sortable: true,
          width: "8%",
          thClass: "custom-th",
          tdClass: "custom-td",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "User Role", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.onColumnFilter, //custom filter function that
            ////trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "UserType",
          field: "user_type_name",
          sortable: true,
          width: "8%",
          thClass: "custom-th",
          tdClass: "custom-td",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "user type", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.onColumnFilter, //custom filter function that
            ////trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Slot",
          field: "login_slot",
          sortable: true,
          width: "8%",
          thClass: "custom-th",
          tdClass: "custom-td",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Login Slot", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.onColumnFilter, //custom filter function that
            ////trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Status",
          field: "state",
          sortable: true,
          width: "8%",
          thClass: "custom-th",
          tdClass: "custom-td",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Status", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: ["Work", "Lunch", "Break"], // dropdown (with selected values) instead of text input
            filterFn: this.onColumnFilter, //custom filter function that
            ////trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Action",
          field: "action",
          sortable: false,
          width: "8%",
          thClass: "custom-th",
          tdClass: "custom-td",
        },
      ],
      inactiveColumns: [
        {
          label: "Name",
          // field: this.concatNames,
          field: "full_name",
          sortable: true,
          width: "12%",
          thClass: "custom-th",
          tdClass: "custom-td",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "user name", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.onColumnFilter, //custom filter function that
            ////trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Reporting To",
          // field: this.reportToconcatNames,
          field: "rt_name",
          sortable: true,
          width: "12%",
          thClass: "custom-th",
          tdClass: "custom-td",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "reporting to", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.onColumnFilter, //custom filter function that
            ////trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Date Of Joining",
          field: "doj",
          sortable: true,
          width: "12%",
          thClass: "custom-th",
          tdClass: "custom-td",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "date of joining", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.onColumnFilter, //custom filter function that
            ////trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Tenure",
          field: "user_tenure",
          sortable: true,
          width: "7%",
          thClass: "custom-th",
          tdClass: "custom-td",
        },
        {
          label: "UserRole",
          field: "user_role_name",
          sortable: true,
          width: "8%",
          thClass: "custom-th",
          tdClass: "custom-td",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "User Role", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.onColumnFilter, //custom filter function that
            ////trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "UserType",
          field: "user_type_name",
          sortable: true,
          width: "8%",
          thClass: "custom-th",
          tdClass: "custom-td",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "user type", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.onColumnFilter, //custom filter function that
            ////trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Slot",
          field: "login_slot",
          sortable: true,
          width: "8%",
          thClass: "custom-th",
          tdClass: "custom-td",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Login Slot", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.onColumnFilter, //custom filter function that
            ////trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Action",
          field: "action",
          sortable: false,
          width: "8%",
          thClass: "custom-th",
          tdClass: "custom-td",
        },
      ],
      rows: [],
      showColumns: false,
      user_designations: [],
      totalRecords: 0,
      serverParams: {
        filters: {},
        sort: [
          {
            field: "",
            type: "asc",
          },
        ],
        page: 1,
        perpage: 100,
      },
      perpage_list: [100, 200, 300, 400, 500, "All"],
    };
  },
  beforeMount() {
    this.setCurrentPage("Users");
  },
  mounted() {
    this.getUserPermissions();
    this.columns = this.activeColumns;
    if (this.permissions.includes("users.read")) {
      // this.displayAllUserPages();
      // this.addColumnsForAdmin();
    }
  },
  created() {
    user.createSiteUsage(this.siteLogReq);
  },
  methods: {
    ...mapActions("userStore", ["setCurrentUser", "logoutUser"]),
    ...mapActions("common", ["setCurrentPage"]),
    addColumnsForAdmin() {
      if (this.currentUser.user_role == 1) {
        this.columns.splice(-1, 0, {
          label: "Manager Of Projects",
          field: "manager_of_projects",
          sortable: false,
          width: "12%",
          thClass: "custom-th",
          tdClass: "custom-td",
        });
        this.columns.splice(-7, 0, {
          label: "Tenure",
          field: "user_tenure",
          sortable: true,
          width: "7%",
          thClass: "custom-th",
          tdClass: "custom-td",
        });
      }
    },
    exportUserList() {
      Swal.fire({
        title: "Exporting...",
        text: "Please wait while the user list is being exported.",
        icon: "info",
        showConfirmButton: false,
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      // API request to export the user list
      setTimeout(() => {
        user
          .exportUserList({
            page: 1,
            perpage: -1,
            filters: {},
            sort: [{ field: "", type: "asc" }],
          })
          .then((res) => {
            const blob = res.data;

            const filename = res["headers"]["content-disposition"];

            let url = window.URL.createObjectURL(new Blob([blob]));
            let link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", filename);
            link.click();

            window.URL.revokeObjectURL(url);

            Swal.close();

            Swal.fire({
              title: "Success",
              text: "User List Exported Successfully",
              icon: "success",
              showConfirmButton: true,
              confirmButtonText: "Okay",
              timer: 2500,
            });
          })
          .catch((err) => {
            Swal.fire({
              title: "Export Failed",
              text: "There was an error while exporting the user list.",
              icon: "error",
              confirmButtonText: "Okay",
            });
          });
      }, 750);
    },
    redmineFilterHandler(data, total, showColumns) {
      this.reqObj = data;
      this.rows = data;
      this.totalRecords = total;
      if (!showColumns) {
        this.columns = this.inactiveColumns;
        return;
      }
      this.columns = [...this.activeColumns];
      if (this.currentUser.user_role == 1) {
        this.addColumnsForAdmin();
      }
    },
    monthYearFormat(date) {
      let o_date = new Date(date.doj);
      let today_date = new Date();
      let formated_date = o_date.toISOString().substring(0, 10);

      if (today_date.getFullYear() > o_date.getFullYear() && formated_date) {
        // return `${formated_date.today_date.getFullYear()-o_date.getFullYear()} year ago...`;
        return (
          formated_date +
          " ( " +
          (today_date.getFullYear() - o_date.getFullYear()) +
          " year ago ... )"
        );
      } else if (today_date.getMonth() > o_date.getMonth() && formated_date) {
        return (
          formated_date +
          " ( " +
          (today_date.getMonth() - o_date.getMonth()) +
          " months ago ... )"
        );
        // return `${today_date.getMonth()-o_date.getMonth()} months ago...`;
      } else if (today_date.getMonth() === o_date.getMonth() && formated_date) {
        return (
          formated_date +
          " ( " +
          (today_date.getDate() - o_date.getDate()) +
          " days ago ... )"
        );
        // return `${today_date.getDate()-o_date.getDate()} days ago...`;
      } else if (today_date.getDate() === o_date.getDate()) {
        return today_date.toISOString().substring(0, 10);
      } else {
        return "-";
      }
    },
    formateDate(date) {
      return moment(date).format("yyyy-MM-DD");
    },
    //concate first and last name
    concatNames: function (rowObj) {
      if (
        rowObj.first_name != null &&
        rowObj.last_name != null &&
        rowObj.middle_name != null
      ) {
        return (
          rowObj.first_name + " " + rowObj.last_name + " " + rowObj.middle_name
        );
      } else if (rowObj.middle_name == null) {
        return rowObj.first_name + " " + rowObj.last_name;
      } else if (rowObj.last_name == null && rowObj.middle_name == null) {
        return rowObj.first_name;
      }
    },
    //concate first and last name
    reportToconcatNames: function (rowObj) {
      if (
        rowObj.rt_first_name != null &&
        rowObj.rt_last_name != null &&
        rowObj.rt_middle_name != null
      ) {
        return (
          rowObj.rt_first_name +
          " " +
          rowObj.rt_last_name +
          " " +
          rowObj.rt_middle_name
        );
      } else if (rowObj.rt_middle_name == null) {
        return rowObj.rt_first_name + " " + rowObj.rt_last_name;
      } else if (rowObj.rt_last_name == null && rowObj.rt_middle_name == null) {
        return rowObj.rt_first_name;
      }
    },
    search_user() {
      var fields = {
        filters: this.serverParams.filters,
        messageData: this.message,
        sort: this.serverParams.sort,
        page: this.serverParams.page,
        perpage: this.serverParams.perpage,
      };
      // console.log("search",fields);
      user.search_user(fields).then((result) => {
        this.rows = [];
        this.reqObj = result.data.data;
        this.rows = result.data.data.data;
        this.totalRecords = result.data.data.total;
        // console.log(this.rows);
      });
    },
    displayAllUserPages() {
      user
        .displayAllUserPages(this.serverParams.page, this.serverParams.perPage)
        .then((result) => {
          //console.log(this.serverParams.page);
          //console.log(this.serverParams.perPage)
          //console.log(result.data.data);
          this.rows = [];
          this.reqObj = result.data.data;
          this.rows = result.data.data.data;
          this.totalRecords = result.data.data.total;
          // console.log(this.rows);
        });
    },
    delete_user(id, doe) {
      if (doe) {
        this.$swal({
          title: "De-Activate",
          text: "Are you sure you want to de-activate?",
          type: "warning",
          icon: "question",
          showCancelButton: true,
          confirmButtonColor: "#d33",
          cancelButtonColor: "",
          confirmButtonText: "Yes, De-activate it!",
          closeOnConfirm: true,
        }).then((result) => {
          if (result.value) {
            this.$swal(
              "De-activated",
              "You successfully de-activate this user",
              "success"
            );
            user.delete_user(id).then((result) => {
              // this.search_user();
              this.$refs.redmineFilter.applyFilter(true);
            });
          } else {
            // this.$swal('Cancelled',)
            // user.delete_user(id, (error) => {
            // console.log(error);
            // });
          }
        });
      } else {
        this.$swal("Error", "Please enter the date of exit of user", "error");
      }
    },
    make_user_active(id) {
      this.$swal({
        title: "Active",
        text: "Are you sure you want to Active this user?",
        type: "warning",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "",
        confirmButtonText: "Yes, Active it!",
        closeOnConfirm: true,
      }).then((result) => {
        if (result.value) {
          this.$swal("success", "User is activated...", "success");
          user.make_user_active(id).then((result) => {
            // this.search_user();
            this.$refs.redmineFilter.applyFilter(true);
          });
        } else {
          // this.$swal('Cancelled',)
          // user.delete_user(id, (error) => {
          // console.log(error);
          // });
        }
      });
    },
    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      this.$nextTick(() => this.$refs.redmineFilter.applyFilter(true));
    },

    onPerPageChange(params) {
      this.updateParams({ perpage: params.currentPerPage });
      this.serverParams.page = 1;
      this.$nextTick(() => this.$refs.redmineFilter.applyFilter(true));
    },

    onSortChange(params) {
      this.updateParams({
        sort: [
          {
            type: params[0].type,
            field: params[0].field,
          },
        ],
      });
      this.$nextTick(() => this.$refs.redmineFilter.applyFilter(true));
    },

    onColumnFilter(params) {
      //remove empty fields
      var newObj = { columnFilters: {} };
      Object.keys(params.columnFilters).forEach(function (key) {
        if (params.columnFilters[key] !== "")
          newObj.columnFilters[key] = params.columnFilters[key];
      });
      this.updateParams(newObj);
      // console.log("params",params);
      {
        field: "first_name";
        field: "last_name";
        field: "state";
      }
      this.serverParams.page = 1;
      var filtersPr = JSON.parse(JSON.stringify(this.serverParams));
      var filters = filtersPr.columnFilters;
      // console.log("filters",filters);
      // this.search_user();
    },
  },
  computed: {
    ...mapState("userStore", ["currentUser"]),
  },
};
</script>

<style scoped>
.startbuttons {
  padding: 5px 30px 5px 30px;
  margin: 10px;
  font-size: 20px;
}
.disableButton {
  opacity: 0.5;
  pointer-events: none;
}
::v-deep .custom-sort-button-class::before,
::v-deep .custom-sort-button-class::after {
  /* Add your custom styles here */
  /* For example, changing the color and size of the arrow indicators */
  content: "";
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  /* Replace #ff0000 with your desired arrow color */
  border-bottom: 5px solid #ff0000;
}
</style>
