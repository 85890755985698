var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{},[_c('div',{staticClass:"d-flex flex-md-row flex-column justify-content-between align-items-md-center mt-4 mx-3 pb-3 gap-md-0 gap-3"},[_c('div',[_c('h3',{staticClass:"text-dark"},[_vm._v("Milestones")]),_c('ol',{staticClass:"breadcrumb m-0"},[_c('li',{staticClass:"breadcrumb-item"},[_c('router-link',{attrs:{"to":{ name: 'DashboardContent' },"tag":"a"}},[_vm._v(" Dashboard ")])],1),_c('li',{staticClass:"breadcrumb-item active"},[_vm._v("Milestones")])])]),(_vm.permissions.includes('milestones.add'))?_c('button',{staticClass:"btn btn-primary btn-sm ms-md-auto me-md-0 me-auto",on:{"click":function($event){return _vm.milestoneModalHandler('add')}}},[_vm._v(" Add Milestone ")]):_vm._e()]),_c('div',{staticClass:"mx-3"},[_c('redmineFilter',{ref:"redmineFilter",attrs:{"page":"milestones","serverParams":_vm.serverParams},on:{"redmine-filter-data":_vm.redmineFilterHandler,"reset-server-params":_vm.onResetServerParams}})],1),_c('div',{staticClass:"card border-0 shadow-sm my-4 mx-3"},[_c('div',{staticClass:"card-body"},[(_vm.permissions.includes('milestones.read'))?_c('div',{staticClass:"p-2"},[_c('vue-good-table',{attrs:{"styleClass":"vgt-table no-border","mode":"remote","totalRows":_vm.totalRecords,"isLoading":_vm.isLoading,"pagination-options":{
            enabled: true,
            perPageDropdown: _vm.perpage_list,
          },"rows":_vm.rows,"columns":_vm.columns},on:{"on-page-change":_vm.onPageChange,"on-sort-change":_vm.onSortChange,"on-column-filter":_vm.onColumnFilter,"on-per-page-change":_vm.onPerPageChange,"update:isLoading":function($event){_vm.isLoading=$event},"update:is-loading":function($event){_vm.isLoading=$event}},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.label == 'Action')?[_c('div',{staticClass:"d-flex justify-content-center align-items-center p-2 gap-3"},[(_vm.permissions.includes('milestones.write'))?_c('span',[_c('button',{staticClass:"btn-pencil",on:{"click":function($event){return _vm.milestoneModalHandler('update', props.row.id)}}},[_c('i',{staticClass:"fa fa-pencil fs-5"})])]):_vm._e(),(_vm.permissions.includes('milestones.delete'))?_c('span',[_c('button',{staticClass:"btn-trash",on:{"click":function($event){return _vm.delete_milestone(props.row.id)}}},[_c('i',{staticClass:"fa fa-trash fs-5"})])]):_vm._e()])]:_vm._e()]}},{key:"column-filter",fn:function({ column, updateFilters }){return [(column.field == 'estimated_start_time')?_c('span',[_c('datepicker',{attrs:{"clear-button":true,"clear-button-icon":'fa fa-times',"format":_vm.formateDate,"placeholder":"yyyy-mm-dd"},on:{"input":(e) =>
                    e ? updateFilters(column, e) : updateFilters(column, '')}})],1):(column.field == 'estimated_end_time')?_c('span',[_c('datepicker',{attrs:{"clear-button":true,"clear-button-icon":'fa fa-times',"format":_vm.formateDate,"placeholder":"yyyy-mm-dd"},on:{"input":(e) =>
                    e ? updateFilters(column, e) : updateFilters(column, '')}})],1):(column.field == 'actual_start_time')?_c('span',[_c('datepicker',{attrs:{"clear-button":true,"clear-button-icon":'fa fa-times',"format":_vm.formateDate,"placeholder":"yyyy-mm-dd"},on:{"input":(e) =>
                    e ? updateFilters(column, e) : updateFilters(column, '')}})],1):(column.field == 'actual_end_time')?_c('span',[_c('datepicker',{attrs:{"clear-button":true,"clear-button-icon":'fa fa-times',"format":_vm.formateDate,"placeholder":"yyyy-mm-dd"},on:{"input":(e) =>
                    e ? updateFilters(column, e) : updateFilters(column, '')}})],1):_vm._e()]}},{key:"pagination-bottom",fn:function(props){return [_c('CustomPagination',{staticClass:"p-2",attrs:{"pageTabs":4,"total":props.total,"pageChanged":props.pageChanged,"perPageChanged":props.perPageChanged,"serverParams":_vm.serverParams}})]}}],null,false,815109268)})],1):_vm._e()]),(!_vm.permissions.includes('milestones.read'))?_c('div',[_c('UnauthorizedPage')],1):_vm._e()]),_c('div',{staticClass:"modal fade",attrs:{"id":"milestoneModal","role":"dialog","aria-hidden":"true","tabindex":"-1"}},[_c('div',{staticClass:"modal-dialog modal-dialog-centered modal-lg",attrs:{"role":"document"}},[_c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"modal-header"},[_c('h3',{staticClass:"modal-title text-dark font-weight-bold pl-3",staticStyle:{"font-size":"26px"},attrs:{"id":"taskModalLongTitle"},domProps:{"textContent":_vm._s(_vm.modal_title)}}),_c('button',{staticClass:"btn-close",attrs:{"type":"button","data-bs-dismiss":"modal","aria-label":"Close"}})]),_c('div',{staticClass:"modal-body mb-2"},[(_vm.modal_title == 'Update Milestone')?_c('add-milestones',{attrs:{"milestone_id":_vm.milestone_id},on:{"close-modal":function($event){return _vm.milestoneModalHandler('close')}}}):_vm._e(),(_vm.modal_title == 'Add Milestone')?_c('add-milestones',{on:{"close-modal":function($event){return _vm.milestoneModalHandler('close')}}}):_vm._e()],1)])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }